import React from "react";
import {Alert, Card, Input, Row, Tooltip} from "antd";
import IReportWidgetProps from "../../../../../model/interface/report/IReportWidgetProps";
import {API_FILTER_TYPE} from "../../../../../model/constants/ApiConstant";
import CodeReaderModal from "../../../../shared/input/code-reader/CodeReaderModal";
import {BarcodeOutlined, CameraOutlined, QrcodeOutlined} from "@ant-design/icons";
import IReportWidgetResourceFinderByCode from "../../../../../model/interface/report/IReportWidgetResourceFinderByCode";
import {connect, RootStateOrAny} from "react-redux";
import IContentType from "../../../../../model/interface/dataStorage/IContentType";
import selectors from "../../../../../redux/selectors";
import IRepositoryService from "../../../../../model/interface/IRepositoryService";
import IRestResource from "../../../../../model/interface/api/IRestResource";
import ActionButton from "../../../action/ActionButton";

interface IProps extends IReportWidgetProps<IReportWidgetResourceFinderByCode> {
    findServiceByContentType: (contentType: IContentType) => IRepositoryService,
    findContentType: (uuid: string) => IContentType
}

interface IState {
    scan?: boolean,
    result?: IRestResource | null,
    loading: boolean,
    search?: 'camera' | 'input'
}

class ResourceFinderByCodeWidget extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            loading: false
        }
    }

    handleChange = (value: string | undefined, search: 'camera' | 'input') => {
        const {findServiceByContentType, options} = this.props
        const {showAction, codeField} = options

        const contentType = this.getContentType();
        if (contentType && codeField) {
            const service = findServiceByContentType(contentType)
            const field = contentType.fields.find(f => f.uuid === codeField)
            const action = contentType.actions.find(a => a.uuid === showAction)
            if (field && action) {
                this.setState({loading: true, result: undefined, search})
                service.collectionList({filters: {0: {field: field.name, type: API_FILTER_TYPE.EQUAL, value}}})
                    .then(({results}) => {
                        this.setState({result: results.length > 0 ? results[0] : null, loading: !!results[0]})
                    })
            }
        }
        value && this.setState({scan: false})
    }

    getContentType() {
        const {findContentType, options} = this.props

        return options.contentTypeUuid ? findContentType(options.contentTypeUuid) : null
    }

    setScan = () => {
        this.setState(state => ({scan: !state.scan}))
    }

    getOnFinish = () => {
        return new Promise<void>(resolve => this.setState({loading: false}, resolve))
    }

    render() {
        const {scan, result, loading, search} = this.state
        const {value, options} = this.props
        const {showOnResult, showAction, inputSearch, scanSearch} = options
        let {bordered} = options
        if(typeof bordered === 'undefined') {
            bordered = true
        }
        const action = this.getContentType()?.actions.find(a => a.uuid === showAction)

        return (
            <div className={bordered && 'rounded border overflow-hidden bg-theme p-3'}>
                {inputSearch && (
                    <>
                        <Input.Search loading={loading} enterButton
                                      onSearch={value => this.handleChange(value, 'input')} value={value}
                                      allowClear={true} placeholder={'Zadejte kód'}/>
                        {result === null && search === 'input' && (
                            <Alert type={'warning'} message={'Záznam nenalezen!'} closable={true}/>
                        )}
                    </>
                )}

                {result && action && <div hidden={true}>
                    <ActionButton triggerClickRender={true} resources={[result]} action={action} options={{label: ''}}
                                  onFinish={this.getOnFinish}/>
                </div>}

                {scan && (
                    <CodeReaderModal confirmText={'Hledat'} resultOnSubmit={!showOnResult} onCancel={this.setScan}
                                     onChange={result => this.handleChange(result, 'camera')}/>
                )}

                {scanSearch && (
                    <Tooltip title={'Naskenujte kód kamerou'}>
                        <Card loading={loading} onClick={this.setScan} className={'cursor-pointer mt-2 mb-0'}>
                            <Row className={'font-size-xxl m-3'} justify={'center'}>
                                <CameraOutlined/>
                            </Row>
                            <Row className={'font-size-lg m-2'} justify={'center'}>
                                <QrcodeOutlined className={'mr-5'}/>
                                <BarcodeOutlined/>
                            </Row>
                            {result === null && search === 'camera' && (
                                <Alert type={'warning'} message={'Záznam nenalezen!'} closable={true}/>
                            )}
                        </Card>
                    </Tooltip>
                )}
            </div>
        )
    }
}

const mapStateToProps = (state: RootStateOrAny) => {
    return {
        findServiceByContentType: (contentType: IContentType) => selectors.services.findOneByContentType(state, contentType),
        findContentType: (uuid: string) => selectors.contentTypes.findOneBy(state, 'uuid', uuid)
    }
}

export default connect(mapStateToProps)(ResourceFinderByCodeWidget)