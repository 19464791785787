import React from "react";
import {ICompositeFieldApprovalOptions} from "../optionEditors/composite/CompositeFieldApprovalOptionsEditor";
import ICompositeFieldData from "../../../../../../model/interface/dataStorage/field/composite/ICompositeFieldData";
import IField from "../../../../../../model/interface/dataStorage/IField";
import {History} from "history";


interface IProps {
    options: ICompositeFieldApprovalOptions,
    value: ICompositeFieldData,
    field: IField,
    history: History
}

interface IState {
}

class PhoneNumber extends React.Component<IProps, IState> {

    constructor(props: Readonly<IProps> | IProps) {
        super(props);
        this.state = {}
    }

    render() {
        const {options, value, field} = this.props
        const {
            phoneNumberDisabledCountryCode,
            phoneNumberLink
        } = {...(field?.options || {}),...options}

        let formatted = ''
        if (value) {
            const code = phoneNumberDisabledCountryCode ? '' : value.length > 9 ? value.split(' ')[0] : ''
            const number = (value.length > 9 ? (value.split(' ')[1] || value) : value)?.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
            formatted = (code ? code + ' ' : '') + number
        }
        return formatted && (
            <div className={'inline-block'}>
                {phoneNumberLink ?
                    <a className={'text-nowrap'} href={'tel:' + formatted.replace(/\s/g, '')}>
                        {formatted}
                    </a> : formatted}
            </div>
        )
    }

    getFieldName = (property: string) => {
        const {field} = this.props
        return field.name + '_' + property
    }
}

export default PhoneNumber
