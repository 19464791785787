import React, {RefObject} from "react";
import IPresenter from "../../../../../model/interface/dataStorage/IPresenter";
import {Button, Form, FormInstance, Input, InputNumber, Modal, Select} from "antd";
import _ from "underscore"
import PresenterBuilder, {IPresenterType} from "../../../../../views/dataStorage/PresenterBuilder";
import IContentType from "../../../../../model/interface/dataStorage/IContentType";
import {connect, RootStateOrAny} from "react-redux";
import selectors from "../../../../../redux/selectors";
import IRepositoryService from "../../../../../model/interface/IRepositoryService";
import ContentTypePresenterMentions from "./ContentTypePresenterMentions";
import {update} from "../../../../../redux/actions/Setup";
import {ISetupState} from "../../../../../redux/reducers/Setup";
import PresentersService from "../../../../../model/service/dataStorage/PresentersService";
import TranslationInput from "../../../../shared/input/TranslationInput";
import Utils from "../../../../../utils";

interface IProps {
    resource?: IPresenter
    contentType: IContentType
    onSubmit: (values?: IPresenter) => void
    findServiceByClassName: (name: string) => IRepositoryService
    modal?: boolean,
    update: (changes: any) => void,
    saveMode: 'update-state' | 'save' | 'none',
    contentTypes: IContentType[]
}

interface IState {
    type?: IPresenterType,
    saving?: boolean
}

class ContentTypePresentersForm extends React.Component<IProps, IState> {

    formRef: RefObject<FormInstance> = React.createRef()

    defaultProps = {
        resource: {
            uuid: Utils.uuid(),
            type: 'label',
            name: '',
            label: '',
            options: {}
        }
    }

    constructor(props: IProps, context: any) {
        super(props, context);
        this.state = {}
    }

    componentDidMount() {
        this.setType(this.props.resource?.type || 'label')
    }

    setType = (type?: string) => {
        this.setState({type: _.findWhere(PresenterBuilder.getTypes(), {type})})
    }

    buildTypeOptions() {
        return PresenterBuilder.getTypes().map(type => ({
            label: type.label,
            value: type.type
        }))
    }

    buildFieldOptions() {
        return this.props.contentType.fields
            .map(field => ({
                label: field.label,
                value: field.name
            }))
    }

    renderTypeOptions(type: IPresenterType) {
        const {contentType} = this.props
        return type.options?.map(option => (
            <>
                {option.type === 'field' && (
                    <Form.Item name={['options', option.name]} label={option.label}>
                        <Select options={this.buildFieldOptions()} defaultValue={option.default}/>
                    </Form.Item>
                )}
                {option.type === 'fields' && (
                    <Form.Item name={['options', option.name]} label={option.label}>
                        <Select options={this.buildFieldOptions()} mode={"multiple"} defaultValue={option.default}/>
                    </Form.Item>
                )}
                {option.type === 'string' && (
                    <Form.Item name={['options', option.name]} label={option.label}>
                        <Input defaultValue={option.default}/>
                    </Form.Item>
                )}
                {option.type === 'number' && (
                    <Form.Item name={['options', option.name]} label={option.label}>
                        <InputNumber defaultValue={option.default}/>
                    </Form.Item>
                )}
                {option.type === 'mentions' && (
                    <Form.Item name={['options', option.name]} label={option.label}>
                        <ContentTypePresenterMentions contentType={contentType}/>
                    </Form.Item>
                )}
            </>
        ))
    }

    submit = () => {
        const {resource} = this.props
        this.formRef.current?.validateFields().then(values => {
            this.save({...resource, ...values})
        })
    }

    save(presenter: IPresenter) {
        const {update, onSubmit, contentType, saveMode, contentTypes} = this.props
        this.setState({saving: true});
        if (saveMode === 'update-state' || saveMode === 'save') {
            (presenter.id ? PresentersService.resourceRetrieve(contentType.uuid, presenter.id, presenter) :
                PresentersService.collectionCreate(contentType.uuid, presenter)).then(resource => {
                if (saveMode === 'update-state') {
                    update({
                        contentTypes: [...contentTypes.filter(c => c.uuid !== contentType.uuid), {
                            ...contentType,
                            presenters: [...contentType.presenters.filter(p => p.uuid !== resource.uuid), presenter]
                        }]
                    })
                }
                return onSubmit(presenter)
            })
        }
        onSubmit(presenter)
    }

    render() {
        const {resource, contentType, modal, onSubmit} = this.props
        const {type, saving} = this.state
        const formData = {type: 'label', ...resource}
        const form = <Form initialValues={formData} ref={this.formRef}
                           onFinish={values => this.save({...resource, ...values})}>
            <Form.Item name={"label"} label={"Titulek"}>
                <TranslationInput uuid={resource?.uuid || Utils.uuid()} code={'configuration.presenter.' + resource?.uuid}/>
            </Form.Item>
            <Form.Item name={"name"} label={"Název"} rules={[{required: true}, {pattern: /[a-z_]/}]}>
                <Input/>
            </Form.Item>
            <Form.Item name={"type"} label={"Typ"}>
                <Select options={this.buildTypeOptions()} onChange={this.setType}/>
            </Form.Item>
            {type && this.renderTypeOptions(type!)}
            <Form.Item name={"action"} label={"Akce"}
                       help={'Vykreslí jako odkaz a po jeho kliknutí se provede přiřazená akce'}>
                <Select allowClear>
                    {contentType.actions.map(action => (
                        <Select.Option value={action.uuid} key={action.uuid}>
                            {action.label}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>
            {!modal && <Button key="submit" type="primary" loading={saving} onClick={this.submit}>
                Uložit
            </Button>}
        </Form>
        return modal ?
            <Modal title={"Nastavení zobrazení"} visible={true} onCancel={() => onSubmit()}
                   destroyOnClose={true} closable={true}
                   footer={[
                       <Button key="submit" type="primary" loading={saving} onClick={this.submit}>
                           Uložit
                       </Button>
                   ]}>
                {form}
            </Modal> : form
    }
}


const mapStateToProps = (state: RootStateOrAny) => {
    const {contentTypes} = state.setup as ISetupState
    return {
        contentTypes,
        findServiceByClassName: (name: string) => selectors.services.findOneByFullClassName(state, name),
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        update: (changes: any) => dispatch(update(changes))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentTypePresentersForm)