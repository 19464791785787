import React, {Component} from 'react';
import {Input} from "antd";
import {connect} from "react-redux";
import {IAppState} from "../../../redux/store";
import ILanguage from "../../../model/interface/ui/dictionary/ILanguage";
import selectors from "../../../redux/selectors";
import ITerm from "../../../model/interface/ui/dictionary/ITerm";
import Utils from "../../../utils";
import {update} from "../../../redux/actions/Setup";
import DictionaryTermsService from "../../../model/service/ui/dictionary/DictionaryTermsService";
import IUser from "../../../model/interface/security/IUser";
import LanguagePicker from "../pickers/LanguagePicker";
import ITranslation from "../../../model/interface/ui/dictionary/ITranslation";
import {ISetupState} from "../../../redux/reducers/Setup";
import {SizeType} from "antd/es/config-provider/SizeContext";

interface IProps {
    value?: string
    onChange?: (code?: string) => void
    className?: string,
    size?: SizeType
    code: string
    uuid?: string,
    preserveCode?: boolean,
    placeholder?: string
    style?: React.CSSProperties,
    disabled?: boolean
    languages: ILanguage[]
    getText: (code: string, language: string, fallBack?: string) => string
    terms: ITerm[]
    user: IUser
    update: (changes: Partial<ISetupState>) => void
    customInput?: (value: string | undefined, triggerSave: (value?: string) => void, onChange: (value?: string) => void, className: string) => JSX.Element
}

interface IState {
    language: string
    value?: string
}

class TranslationInput extends Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        const value = (props.user.language && (props.code === props.value || props.preserveCode) ? (props.preserveCode && props.getText(props.value || '', props.user.language, '') ?  props.getText(props.value || '', props.user.language, '') : props.getText(props.code, props.user.language, props.value)) : props.value || '')
        this.state = {
            language: props.user.language || '',
            value: value === props.code ? '' : value,
        }
    }

    componentDidUpdate(prevProps: Readonly<IProps>) {
        if (this.props.value !== this.state.value && prevProps.value !== this.props.value && this.props.value !== this.props.code && (!this.props.preserveCode || this.props.getText(this.props.value || '', this.state.language, ''))) {
            this.setState({value: this.props.getText(this.props.value || '', this.state.language, '')})
        }
    }

    onChange = () => {
        this.props.onChange?.(this.props.code)
    }

    onChangeValue = (value: string) => {
        this.setState({value})
    }

    onLanguagePick = (language: string) => {
        const {code, user, value, getText} = this.props
        this.setState({
            language,
            value: getText(code, language) === code ? (user.language === language ? value : '') : getText(code, language)
        })
    }


    translationUpdate(code: string, text: string, oldText: string) {
        if (!text && this.props.user.language === this.state.language) {
            return this.props.onChange?.()
        }
        if (oldText !== text && text !== this.props.value) {
            const {language} = this.state
            const {terms, update, uuid, preserveCode, value} = this.props
            let term: ITerm = terms.find(t => t.code === code) || {
                uuid: Utils.uuid(),
                code,
                relatedResource: uuid,
                children: [],
                path: code,
                translations: [],
                tokens: []
            }
            const updatedTranslations: Partial<ITranslation>[] = [...term.translations]
            const translation: Partial<ITranslation> = {
                ...(term.translations.find(t => t.language === language) || {
                    uuid: Utils.uuid(),
                    language,
                    term: term.uuid,
                    text: text
                }), text
            };
            const translationIndex = updatedTranslations.findIndex(t => t.uuid === translation.uuid)
            if (translationIndex > -1) {
                updatedTranslations[translationIndex] = translation
            } else {
                updatedTranslations.push(translation)
            }
            term = {...term, translations: updatedTranslations as any}

            const promise = (term.id ? DictionaryTermsService.resourceUpdate(term.uuid, {
                ...term,
                path: undefined
            }) : DictionaryTermsService.collectionCreate({...term, path: undefined}))
            if (preserveCode && translationIndex > -1){
                return this.props.onChange?.(value)
            } else {
                this.onChange()
            }
            const index = terms.findIndex(t => t.uuid === term.uuid)
            const updatedTerms = [...terms]
            if (index > -1) {
                updatedTerms[index] = term
            } else {
                updatedTerms.push(term)
            }

            update({terms: updatedTerms})
            promise.then()
        }
    }

    render() {

        const {className, style, getText, code, languages, disabled, customInput, size, placeholder} = this.props
        const {language, value} = this.state

        const inputClass = 'flex-grow-1 min-w-0'

        return (
            <div className={"translation-input " + (className || '')} style={style}>
                <Input.Group compact={true} className={'d-flex w-100'}>
                    {customInput ? customInput(value, (value) => this.translationUpdate(code, value || '', getText(code, language || '')), (value) => this.onChangeValue(value || ''), inputClass) :
                        <Input
                            size={size}
                            disabled={disabled} className={inputClass}
                            onPressEnter={e => this.translationUpdate(code, e.currentTarget.value, getText(code, language || ''))}
                            onBlur={e => this.translationUpdate(code, e.currentTarget.value, getText(code, language || ''))}
                            onChange={e => this.onChangeValue(e.target.value)}
                            placeholder={placeholder || 'zadejte překlad'}
                            value={value}
                        />}
                    {languages.length > 1 ?
                        <LanguagePicker allowClear={false} value={language} className={'w-auto tall'}
                                        onChange={this.onLanguagePick}/> : undefined}
                </Input.Group>


            </div>
        )
    }
}

const mapStateToProps = (state: IAppState) => {
    return {
        languages: state.setup.languages,
        getText: (code: string, language: string, fallback?: string) => selectors.dictionary.getMessage(state, code, fallback, {}, language),
        user: state.setup.user,
        terms: state.setup.terms
    }
}

export default connect(mapStateToProps, {update})(TranslationInput)
