import React from 'react'
import {Skeleton, Typography} from 'antd';
import IReport from "../../../model/interface/dataStorage/IReport";
import IReportWidget from "../../../model/interface/report/IReportWidget";
import ReportWidgetList from "../configuration/report/widget/ReportWidgetList";
import ReportWidgetType from "../configuration/report/widget/ReportWidgetType";
import ReportEditor from "../configuration/report/ReportEditor";
import ReportWidget from "../configuration/report/widget/ReportWidget";
import IBaseProps from "../../../model/interface/IBaseProps";
import IWidgetPropsFunctions from "../../../model/interface/widget/IWidgetPropsFunctions";
import TreeStructure, {ITreeStructure} from "../../../utils/TreeStructure";
import ReportEditButton from "../configuration/report/ReportEditButton";
import ReportsService from "../../../model/service/dataStorage/ReportService";
import settingsService from "../../../model/service/SettingsService";
import Utils from "../../../utils";
import LocaleText from "../settings/dictionary/LocaleText";

interface IState {
    structure: { [id: string]: IReportWidget }
    loading?: boolean,
    report?: IReport
}

interface IProps extends IBaseProps {
    report: IReport | string
}

class Report extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        const containerUuid = Utils.uuid();
        this.state = {
            structure: {
                [containerUuid]: {...ReportWidgetList.getByType(ReportWidgetType.CONTAINER), id: containerUuid, label: 'Container'}
            },
        };
    }

    componentDidMount() {
        const {report} = this.props
        if (typeof report === 'string') {
            this.load(report).then(report => this.buildStructure(report))
        } else {
            this.setState({report})
            this.buildStructure(report);
        }
    }

    buildStructure(report: IReport) {
        let structure = ReportEditor.widgetsToStructure(report.widgets)
        structure = this.setValues(structure, report)
        report.widgets.length > 0 && this.setState({structure})
    }

    load = (uuid: string) => {
        this.setState({loading: true})
        return ReportsService.resourceRetrieve(uuid).then(response => {
            this.setState({report: response, loading: false})
            return response
        })
    }

    setValues(structure: { [p: string]: IReportWidget }, report: IReport) {
        Object.keys(structure).forEach(key => {
            if (structure[key].type === ReportWidgetType.COMMENT) {
                structure[key].value = report.thread
            }
        })
        return structure
    }

    getSortedChildren(id: string, structure: ITreeStructure<IReportWidget>) {
        return TreeStructure.sortChildren(id, structure, 'weight') as IReportWidget[]
    }

    render() {
        const {structure, loading, report} = this.state
        const {history, match} = this.props

        return (
            <>
                {loading ? (
                    <Skeleton.Button className={'mb-2'} active={true} style={{width: 200}} size={"small"}/>
                ) : (
                    <Typography.Title level={1} style={{color: settingsService.getThemeColor(), marginBottom: 10}}>
                        <LocaleText code={report?.label || ''}/>
                    </Typography.Title>
                )}

                <Skeleton loading={loading}>
                    {report ? (
                        <div>
                            <ReportEditButton resource={report} history={history} match={match}/>
                            {
                                Object.entries(structure).map(([, widget]) => {
                                    if (widget && !widget.parent) {
                                        let functions = {
                                            getNode: (id: string) => structure[id],
                                            getSortedChildren: (id: string) => this.getSortedChildren(id, structure),
                                        } as unknown as IWidgetPropsFunctions
                                        return (
                                            <ReportWidget
                                                {...widget}
                                                current={widget.uuid}
                                                key={widget.uuid}
                                                preview={true}
                                                functions={functions}
                                                match={this.props.match}
                                                history={this.props.history}
                                            />
                                        )
                                    }
                                    return null
                                })
                            }
                        </div>
                    ) : 'Chyba konfigurace'}
                </Skeleton>

            </>
        )
    }
}

export default Report