import IRoute from "../dataStorage/IRoute";
import IRule from "../security/IRule";

type Type = 'url' | 'route' | 'script' | 'onclick_script' | ''
export const MENU_ITEM_TYPES = {
    URL: 'url',
    ROUTE: 'route',
    SCRIPT: 'script',
    ONCLICK_SCRIPT: 'onclick-script'
}
export default interface IMenuItem {
    id?: number,
    uuid: string,
    menu: string
    breadcrumb: boolean,
    parent: string| null
    icon: string,
    weight: number,
    lvl?: number,
    type: Type,
    title: string,
    url?: string,
    route?: IRoute,
    key?: number,
    root?: IMenuItem
    rule: IRule|null
    iconColor?: string
    backgroundColor?: string
    iconColorDark?: string
    backgroundColorDark?: string
    script?: string,
    blank?: boolean
    hidden?: boolean
}