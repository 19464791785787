import React, {RefObject} from "react"
import {Button, Drawer, Form, FormInstance, Row, Select, Tooltip} from "antd";
import {
    CaretDownOutlined,
    CaretUpOutlined,
    DeleteOutlined,
    EditOutlined,
    PlusOutlined,
    SaveOutlined,
    SortAscendingOutlined,
    SortDescendingOutlined
} from "@ant-design/icons";
import ISortSettings from "../../../../model/interface/dataStorage/view/settings/ISortSettings";
import IRestServiceOrder from "../../../../model/interface/api/IRestServiceOrder";
import IContentType from "../../../../model/interface/dataStorage/IContentType";
import {IViewSettingsProps} from "../ViewSettings";
import arrayMove from "array-move";
import {connect, RootStateOrAny} from "react-redux";
import selectors from "../../../../redux/selectors";
import IViewSettingsStructure from "../../../../model/interface/dataStorage/view/settings/IViewSettingsStructure";
import IViewUnit from "../../../../model/interface/dataStorage/IViewUnit";
import IRestServiceOrders from "../../../../model/interface/api/IRestServiceOrders";
import _ from "underscore";
import ViewUnit from "../ViewUnit";
import DragSortList from "../../../shared/list/DragsortList";
import ViewTableItemsSettings from "../table/ViewTableItemsSettings";
import LocaleText from "../../settings/dictionary/LocaleText";

interface IState {
    sort: ISortSettings
    currentContentType: string
    currentIndex?: number
}

interface IProps extends IViewSettingsProps {
    findContentTypeByClassName: (name: string) => IContentType
    findContentTypeByUuid: (uuid: string) => IContentType
}

class SortSettings extends React.Component<IProps, IState> {

    formRef: RefObject<FormInstance> = React.createRef()

    constructor(props: IProps) {
        super(props)
        this.state = {
            sort: props.settings?.sort ? {...props.settings.sort} : {},
            currentContentType: props.findContentTypeByUuid(props.view.contentTypes[0]).fullClassName
        }
    }

    static buildSortSettings(settings: IViewSettingsStructure, viewUnit: IViewUnit, contentType: IContentType) {
        if (settings && settings.sort && settings.sort[contentType.fullClassName]) {
            let orders: IRestServiceOrders = {}
            settings.sort[contentType.fullClassName].forEach(sort => {
                const field = _.findWhere(contentType.fields, {name: sort.field as string})
                orders = {...orders, ...(field ? ViewUnit.getItemSortBy(viewUnit, field, sort.direction, 'settings-') : {})}
            })
            return orders
        }
        return {}
    }

    handleClose = (removeSortItem: IRestServiceOrder) => {
        const {currentContentType} = this.state
        const sortItems = this.state.sort[currentContentType]
            .filter(sortItem => sortItem.field !== removeSortItem.field)
        this.setState(state => ({sort: {...state.sort, [currentContentType]: sortItems}}), this.onChange)
    }

    onEdit = (index: number) => {
        this.setState({currentIndex: index})
    }

    resetEditing() {
        this.setState({currentIndex: undefined}, this.formRef.current?.resetFields)
    }

    onValuesChange(values: any) {
        this.setState(state => ({sort: {...values, ...state.sort}}))
        this.onChange()
    }

    onChange() {
        this.props.onChange({sort: this.state.sort}).then();
    }

    getContentType() {
        const {findContentTypeByClassName} = this.props
        const {currentContentType} = this.state
        return findContentTypeByClassName(currentContentType)
    }

    onAdd = (currentIndex: number) => {
        this.formRef.current?.validateFields().then(values => {
            let {sort, currentContentType} = this.state;
            if (!sort[currentContentType] || sort[currentContentType].findIndex(sortItem => sortItem.field === values.field) === -1) {
                this.setState(state => ({
                    sort: {
                        ...state.sort,
                        [currentContentType]: [...(state.sort[currentContentType] || []), values]
                    }
                }), this.onChange)
            } else {
                sort[currentContentType][currentIndex] = values
                this.setState(state => ({
                    sort: {...state.sort, [currentContentType]: [...sort[currentContentType]]}
                }), this.onChange)
            }
            this.resetEditing()
        }).catch(() => {
        })
    }

    onSortEnd = ({oldIndex, newIndex}: { oldIndex: number, newIndex: number }): void => {
        const {currentContentType} = this.state
        const sortList = arrayMove(this.state.sort[currentContentType], oldIndex, newIndex)
        this.setState(state => ({sort: {...state.sort, [currentContentType]: sortList}}), this.onChange);
    }

    onContentTypeChange = (name: string) => {
        this.setState({currentContentType: name}, this.formRef.current?.resetFields)
    }

    getField = (value: string, property: 'uuid' | 'name' = 'name') => {
        const contentType = this.getContentType()
        const field = contentType.fields.find(f => f[property] === value);
        if (!field) {
            throw new Error(`Field with identifier [${property}: ${value}] does not exist`)
        }
        return field
    }

    render() {
        const {sort, currentContentType, currentIndex} = this.state;
        const {view, findContentTypeByUuid} = this.props

        const items = sort[currentContentType]?.filter(item => !!this.getField(item.field)) || []

        return (
            <div>
                {view.contentTypes.length > 1 && (
                    <Form.Item label={'Zvolte typ obsahu'}>
                        <Select value={currentContentType} onChange={this.onContentTypeChange}>
                            {view.contentTypes.map(uuid => {
                                const listContentType = findContentTypeByUuid(uuid)
                                return currentContentType && (
                                    <Select.Option value={listContentType.fullClassName} key={uuid}>
                                        {listContentType.label}
                                    </Select.Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                )}
                <DragSortList lockAxis={"y"} item={{
                    style: {zIndex: 1001},
                    render: (item: IRestServiceOrder, index, handle) =>
                        <Row justify={"space-between"} align={"middle"}
                             className={'mb-2 border p-1 shadow-sm'} key={item.field}>
                            <Row align={"middle"}>
                                {handle} {this.getField(item.field).label || this.getField(item.field).name}
                            </Row>
                            <Row align={"middle"}>
                                {item.direction && {
                                    'ASC': <>Vzestupně <SortAscendingOutlined style={{fontSize: 20}}/></>,
                                    'DESC': <>Sestupně <SortDescendingOutlined style={{fontSize: 20}}/></>
                                }[item.direction]}
                                <Button size={"small"} className={'ml-3'} type={"link"} icon={<EditOutlined/>}
                                        onClick={() => this.onEdit(index)}/>
                                <Tooltip title={items.length === 1 ? 'zachovejte prosím alespoň jedno řazení' : false}>
                                    <Button size={"small"} type={"link"} danger disabled={items.length === 1}
                                            icon={<DeleteOutlined/>} onClick={() => this.handleClose(item)}/>
                                </Tooltip>
                            </Row>
                        </Row>
                }} children={items}
                              onSortEnd={(sort) =>
                                  this.onSortEnd(sort)} handle={{
                    render: () => <div className={'d-inline-block'}>
                        <div className={"d-flex flex-column pl-2 pr-2"} style={{cursor: "move"}}>
                            <CaretUpOutlined/>
                            <CaretDownOutlined/>
                        </div>
                    </div>
                }}/>
                <Row justify={"center"} className={'mt-3'}>
                    <Tooltip title={'Přidat'} mouseEnterDelay={0.5}>
                        <Button shape={'circle'} onClick={() => this.onEdit(items.length)}
                                icon={<PlusOutlined/>}>
                        </Button>
                    </Tooltip>
                </Row>
                {currentIndex !== undefined && <Drawer placement="right"
                                         title={currentIndex === items.length ? 'Vytvořit řazení' : 'Upravit řazení'}
                                         visible={true} getContainer={false}
                                         onClose={() => this.resetEditing()} destroyOnClose={true}
                                         style={{position: 'absolute', zIndex: 1}}
                                         contentWrapperStyle={{width: 600, maxWidth: '100%'}} width={'auto'}>
                    <Form layout={"vertical"} ref={this.formRef} initialValues={items[currentIndex]}
                          onValuesChange={(values) => this.onValuesChange(values)}>
                        <Form.Item name={'field'} label={'Pole'} rules={[{required: true, message: 'Pole je povinné'}]}>
                            <Select>
                                {view.items
                                    .filter(item => !!this.getField(item.field, 'uuid')
                                        && (!items.find(i => this.getField(item.field, 'uuid').name === i.field)
                                            || (items[currentIndex]?.field === this.getField(item.field, 'uuid').name)))
                                    .map(item => {
                                        const field = this.getField(item.field, 'uuid')
                                        return (
                                            <Select.Option
                                                disabled={ViewTableItemsSettings.isSortableDisabled(this.getField(item.field, 'uuid'))}
                                                key={item.id} value={field.name}>
                                                <LocaleText code={field.label || field.name}/>
                                            </Select.Option>
                                        )
                                    })}
                            </Select>
                        </Form.Item>
                        <Form.Item name={'direction'} label={'Směr'}
                                   rules={[{required: true, message: 'Pole je povinné'}]}>
                            <Select>
                                <Select.Option key={'asc'} value={"ASC"}>
                                    <Row justify={"space-between"} align={"middle"}>
                                        Vzestupně<SortAscendingOutlined style={{fontSize: 20}}/>
                                    </Row>
                                </Select.Option>
                                <Select.Option key={'desc'} value={"DESC"}>
                                    <Row justify={"space-between"} align={"middle"}>
                                        Sestupně<SortDescendingOutlined style={{fontSize: 20}}/>
                                    </Row>
                                </Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary"
                                    onClick={() => currentIndex !== undefined && this.onAdd(currentIndex)}
                                    icon={<SaveOutlined/>}>
                                Uložit
                            </Button>
                        </Form.Item>
                    </Form>
                </Drawer>}

            </div>
        )
    }
}


const mapStateToProps = (state: RootStateOrAny) => {
    return {
        findContentTypeByClassName: (name: string) => selectors.contentTypes.findOneBy(state, 'fullClassName', name),
        findContentTypeByUuid: (uuid: string) => selectors.contentTypes.findOneBy(state, 'uuid', uuid)
    }
}

export default connect(mapStateToProps)(SortSettings)