import React from "react";
import {Checkbox, Row, Select, SelectProps} from "antd";
import {RefSelectProps} from "antd/es/select";


interface IProps extends Omit<SelectProps<any>, 'children'> {
    allowSelectAll?: boolean,
    inputRef?: React.Ref<RefSelectProps>
}

const SELECT_ALL = '_all'

interface IState {
    search: string
}

class ProSelect extends React.Component<IProps, IState> {

    constructor(props: IProps, context: any) {
        super(props, context);
        this.state = {
            search: ''
        }
    }

    onSearch = (value: string) => {
        let {onSearch} = this.props
        onSearch?.(value)
        this.setState({search: value})
    }

    onSelect = (selectedValue: string, option: any) => {
        const {
            onSelect,
            options,
            value,
            onChange,
            filterOption
        } = this.props
        const {search} = this.state

        const selectedLength = value?.length || 0
        const filteredData = search ?
            (options?.filter(option => typeof filterOption !== 'function' || filterOption(search, option)) || []) : options
        const dataLength = filteredData?.length || 0
        if (selectedValue === SELECT_ALL){
            if (selectedLength === dataLength) {
                onChange?.([], [])
            } else {
                onChange?.(filteredData?.map(d => d.value), [])
            }
        }
        onSelect?.(selectedValue, option)
    }
    render() {
        const {
            allowSelectAll,
            options,
            value,
            mode,
            inputRef,
            filterOption,
            maxTagCount,
            searchValue
        } = this.props
        const {search} = this.state

        console.log(allowSelectAll, mode)
        const selectedLength = value?.length || 0
        const optionsLength = options?.length || 0
        const filteredData = search ?
            (options?.filter(option => typeof filterOption !== 'function' || filterOption(search, option)) || []) : options
        const dataLength = filteredData?.length || 0
        const formattedOptions = [
            ...((optionsLength > 3) && allowSelectAll && mode && dataLength ? [{
                label: (
                    <Row>
                        <Checkbox checked={selectedLength === dataLength} className={'mr-2'}/>Vybrat vše
                    </Row>),
                value: SELECT_ALL
            }] : []),
            ...options || []
        ]

        return (
            <Select {...this.props} filterOption={(input, option) =>
                (typeof filterOption === 'function' && filterOption(input, option)) || option?.value === SELECT_ALL
            } options={formattedOptions} ref={inputRef} onSearch={this.onSearch}
                    searchValue={searchValue || (allowSelectAll ? search : undefined)}
                    maxTagCount={maxTagCount || (allowSelectAll ? 2 : undefined)} onSelect={this.onSelect}/>
        )
    }
}

export default ProSelect