import React from "react"
import arrayMove from "array-move";
import {Button, Row, Select, Typography} from "antd";
import {CaretDownOutlined, CaretUpOutlined, DeleteOutlined, PlusOutlined} from "@ant-design/icons";
import IViewItem from "model/interface/dataStorage/view/IViewItem";
import {connect, RootStateOrAny} from "react-redux";
import selectors from "../../../../redux/selectors";
import IContentType from "../../../../model/interface/dataStorage/IContentType";
import IViewPivotTableGroupItemSettings
    from "../../../../model/interface/dataStorage/view/pivotTable/IViewPivotTableGroupItemSettings";
import IViewUnit from "../../../../model/interface/dataStorage/IViewUnit";
import {
    FIELD_MODE_COMPOSITE,
    FIELD_MODE_COMPUTED,
    RELATION_FIELD_TYPE
} from "../../../../model/interface/dataStorage/IField";
import DragSortList, {DragSortListItem} from "../../../shared/list/DragsortList";
import LocaleText from "../../settings/dictionary/LocaleText";

interface IProps {
    findContentTypeByUuid: (uuid: string) => IContentType
    items: IViewPivotTableGroupItemSettings[]
    onChange: (values: IViewPivotTableGroupItemSettings[]) => void,
    viewUnit: IViewUnit
}

class GroupItemsSettings extends React.Component<IProps> {
    constructor(props: IProps) {
        super(props);

        this.state = {}
    }

    onSortEnd = ({oldIndex, newIndex}: { oldIndex: number, newIndex: number }): void => {
        const {items} = this.props
        const sortedItems = arrayMove(items, oldIndex, newIndex)
        oldIndex !== newIndex && this.props.onChange(sortedItems)
    }

    onChange = (fieldUuid: string) => {
        let items = [...this.props.items]
        items.push({field: fieldUuid})
        this.props.onChange(items)
    }

    getField = (value: string, property: 'uuid' | 'name' = 'uuid') => {
        const contentType = this.getContentType();
        const field = contentType.fields.find(f => f[property] === value);
        if (!field) {
            throw new Error(`Field with identifier [${property}: ${value}] does not exist`)
        }
        return field
    }

    getContentType() {
        const {findContentTypeByUuid, viewUnit} = this.props
        return findContentTypeByUuid(viewUnit.contentTypes[0]);
    }

    deleteItem = (item: IViewPivotTableGroupItemSettings) => {
        let items = [...this.props.items]
        this.props.onChange(items.filter(i => i.field !== item.field))
    }

    render() {
        const {viewUnit, items} = this.props
        let groupItems: (IViewItem & IViewPivotTableGroupItemSettings)[] = [];
        items?.forEach(item => {
            const viewItem = viewUnit.items.find(viewItem => viewItem.field === item.field && viewItem.enabled)
            if (viewItem) {
                groupItems.push({...viewItem, ...item})
            }
        })

        const itemProps: DragSortListItem<IViewItem & IViewPivotTableGroupItemSettings> = {
            render: (item, index, handle) => {
                const fieldObject = this.getField(item.field)
                const {name, label} = fieldObject
                const title = item.options?.title || label || name
                return <Row justify={'space-between'}>
                    <Row align={"middle"}>
                        {handle}
                        {title && <Typography.Text strong className={'mr-2'}>{title}</Typography.Text>}
                    </Row>
                    <Row align={"middle"}>
                        <Button size={"small"} danger type={'link'} onClick={() => this.deleteItem(item)}
                                icon={<DeleteOutlined/>}/>
                    </Row>
                </Row>
            },
            className: 'border p-1 mb-2 shadow-sm'
        }

        return (
            <>
                <Select value={''} optionLabelProp={'label'} className={'w-100 mb-2'} suffixIcon={<PlusOutlined/>}
                        onChange={(value) => this.onChange(value as string)}>
                    {viewUnit.items
                        .filter(i => ![RELATION_FIELD_TYPE.ONE_TO_MANY, RELATION_FIELD_TYPE.MANY_TO_MANY]
                                .includes(this.getField(i.field).type) && i.enabled
                            && ![FIELD_MODE_COMPOSITE, FIELD_MODE_COMPUTED].includes(this.getField(i.field).mode || '')
                            && !groupItems.some(g => g.field === i.field))
                        .map(item => {
                            const field = this.getField(item.field, 'uuid')
                            return (
                                <Select.Option value={item.field} key={item.field}>
                                    <LocaleText code={item.options?.label || field.label || field.name}/>
                                </Select.Option>
                            )
                        })}
                </Select>
                <DragSortList
                    item={itemProps}
                    lockAxis={"y"}
                    onSortEnd={this.onSortEnd}
                    handle={{
                        render: () => <div className={"d-flex flex-column px-2"}>
                            <CaretUpOutlined/>
                            <CaretDownOutlined/>
                        </div>
                    }}
                    children={groupItems}
                />
            </>
        );
    }
}

const mapStateToProps = (state: RootStateOrAny) => {
    return {
        findContentTypeByUuid: (uuid: string) => selectors.contentTypes.findOneBy(state, 'uuid', uuid)
    }
}

export default connect(mapStateToProps)(GroupItemsSettings)