import React, {Component} from 'react';
import {Input, Select} from "antd";
import {connect} from "react-redux";
import Utils from "../../../utils";
import {IAppState} from "../../../redux/store";
import ILanguage from "../../../model/interface/ui/dictionary/ILanguage";
import CountryFlag from "../display/CountryFlag";
import {SizeType} from "antd/es/config-provider/SizeContext";

interface IProps {
    value?: string | ILanguage
    onChange?: (language: string) => void
    className?: string,
    style?: React.CSSProperties,
    disabled?: boolean
    languages: ILanguage[]
    allowClear?: boolean
    size?: SizeType
}

interface IState {

}

class LanguagePicker extends Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {}
    }

    onPickerChoose = (language: string) => {
        this.props.onChange?.(language)
    }

    render() {
        const {className, style, disabled, languages, allowClear, size} = this.props
        const value = Utils.parseObjectToIdentifier(this.props.value, 'uuid')

        return (
            <Select
                allowClear={allowClear === undefined ? true : allowClear}
                className={className + ' w-100 language-picker'}
                disabled={disabled}
                size={size}
                value={value}
                placeholder="Vyberte jazyk"
                optionFilterProp="children"
                onChange={this.onPickerChoose}
                filterOption={(input, option) =>
                    option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
            >
                {languages.map(language => (
                        <Select.Option key={language.uuid} value={language.uuid}>
                            {language.icon ? <CountryFlag
                                countryCode={language.icon}/> : ''} {language.label + ' [' + language.code + ']'}
                        </Select.Option>
                    )
                )}
            </Select>
        )
    }
}

const mapStateToProps = (state: IAppState) => {
    return {
        languages: state.setup.languages,
    }
}

export default connect(mapStateToProps)(LanguagePicker)
