import RestService from "model/service/dataStorage/RestService";
import IRestServiceOptions from "../../interface/api/IRestServiceOptions";
import IRestServiceCollectionResponse from "../../interface/api/IRestServiceCollectionResponse";
import IDownloadRequest from "../../interface/export/IDownloadRequest";
import {DownloadOutlined, FileExcelOutlined, FileTextOutlined} from "@ant-design/icons";
import IRestFile from "../../interface/api/IRestFile";
import {Button, notification, Progress, Spin} from "antd";
import React from "react";

export interface IRestDownloadRequestServiceCollectionResponse extends IRestServiceCollectionResponse {
    results: Array<IDownloadRequest>
}

export const DOWNLOAD_REQUEST_TYPES = {
    DATA_STORAGE: 'data_storage_view',
    DATA_STORAGE_VIEW: 'data_storage_view_unit',
    DATA_STORAGE_PIVOT_VIEW: 'data_storage_pivot_view_unit',
    EMPLOYEES: 'employees',
    TERM_TRANSLATIONS: 'term_translations',
    WORKLOAD_REPORTS: 'workload_reports',
    ORGANIGRAMME: 'organigramme',
    ORGANIGRAMME_JOBS: 'organigramme_jobs',
    ORGANIGRAMME_UNUSED_JOBS: 'organigramme_unused_jobs',
    ORGANIGRAMME_EMPLOYEES: 'organigramme_employees',
    WORKLOAD_WAGE_EXPORT: 'workload_wage_export'
}

export const DOWNLOAD_REQUEST_FORMAT = {
    XLSX: 'xlsx',
    CSV: 'csv',
    CSV_MS: 'csv_ms',
    TXT: 'txt',
    ZIP: 'zip'
}

export const DOWNLOAD_REQUEST_FORMAT_LABELS = [
    {value: DOWNLOAD_REQUEST_FORMAT.XLSX, label: 'XLSX', icon: <FileExcelOutlined/>},
    {value: DOWNLOAD_REQUEST_FORMAT.CSV, label: 'CSV', configLabel: 'CSV (utf-8)', icon: <FileTextOutlined/>},
    {
        value: DOWNLOAD_REQUEST_FORMAT.CSV_MS,
        label: 'CSV (MS)',
        configLabel: 'CSV (win-1250)',
        icon: <FileTextOutlined/>
    },
    {
        value: DOWNLOAD_REQUEST_FORMAT.TXT,
        label: 'TXT',
        configLabel: 'TXT (csv tab delimiter)',
        icon: <FileTextOutlined/>
    }
]


interface IRestDownloadRequestService {
    create(type: string, format: string, fileName: string, source: any): Promise<IDownloadRequest>

    collectionList(options?: IRestServiceOptions): Promise<IRestDownloadRequestServiceCollectionResponse>,

    collectionCreate(data: any): Promise<IDownloadRequest>,

    resourceRetrieve(id: number | string): Promise<IDownloadRequest>,

    resourceUpdate(id: number | string, data: any): Promise<IDownloadRequest>,

    resourceDelete(id: number | string): Promise<void>,

    iterateResource(id: number | string): Promise<IDownloadRequest>

    runDownload(id: number | string, fileName?: string): Promise<IRestFile>,

    prepareDownload(resource: IDownloadRequest, totalCount: number, timeOut?: number): void
}

const DownloadRequestsService_COLLECTION = 'download-requests'

const DownloadRequestsService_DOWNLOAD = 'download'

const DownloadRequestsService_ITERATE = 'download-requests-iterate'

const DownloadRequestsService: IRestDownloadRequestService = {
    collectionList: function (options?) {
        return RestService.collectionList(DownloadRequestsService_COLLECTION, options as unknown as IRestServiceOptions) as Promise<IRestDownloadRequestServiceCollectionResponse>
    },
    collectionCreate: function (data) {
        return RestService.collectionCreate(DownloadRequestsService_COLLECTION, {
            ...data,
        }) as Promise<IDownloadRequest>
    },
    create: (type = DOWNLOAD_REQUEST_TYPES.DATA_STORAGE, format = 'xlsx', fileName: string, source: any) => {
        return RestService.collectionCreate(DownloadRequestsService_COLLECTION, {
            type,
            outputFormat: format,
            fileName,
            source
        }) as Promise<IDownloadRequest>
    },
    resourceRetrieve: function (id) {
        return RestService.resourceRetrieve(DownloadRequestsService_COLLECTION, id) as Promise<IDownloadRequest>
    },
    resourceDelete: function (id) {
        return RestService.resourceDelete(DownloadRequestsService_COLLECTION, id)
    },
    resourceUpdate: function (id, data) {
        return RestService.resourceUpdate(DownloadRequestsService_COLLECTION, id, data) as Promise<IDownloadRequest>
    },
    iterateResource: function (id) {
        return RestService.resourceRetrieve(DownloadRequestsService_ITERATE, id) as Promise<any>
    },
    runDownload: function (id, fileName) {
        return RestService.resourceDownload('/' + DownloadRequestsService_DOWNLOAD + '/' + id, true, fileName)
    },
    prepareDownload: (resource: IDownloadRequest, totalCount: number, timeOut = 3) => {
        if (localStorage.getItem('export-terminated-' + resource.uuid)) {
            localStorage.removeItem('export-terminated-' + resource.uuid)
            return
        }
        DownloadRequestsService.iterateResource(resource.uuid).then((request) => {
            notification.open({
                onClose: () => request.state === 'working' && localStorage.setItem('export-terminated-' + resource.uuid, 'true'),
                key: resource.uuid,
                message: request.state === 'working' ?
                    <div>Připravuje se soubor ke stažení <Spin className={'ml-2'}></Spin>
                    </div> : 'Soubor je připraven ke stažení',
                description: <div>
                    <Progress
                        percent={request.recordsOffset && (Math.round((request.recordsOffset / totalCount) * 100))}/>
                    {request.state === 'done' && (
                        <Button disabled={totalCount === 0} type={'primary'} icon={<DownloadOutlined/>}
                                onClick={() => {
                                    DownloadRequestsService.runDownload(request.uuid, request.fileName).then(() => {
                                        notification.close(request.uuid)
                                    })
                                }}>
                            Stáhnout
                        </Button>
                    )}
                </div>,
                duration: 0,
                type: "info"
            })
            if (request.state === 'working') {
                setTimeout(() => DownloadRequestsService.prepareDownload(resource, totalCount, timeOut), (timeOut || 3) * 1000)
            }
        })
    }
}

export default DownloadRequestsService