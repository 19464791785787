import React from "react";
import IFieldOptions from "../../../../../../model/interface/form/elementOptions/IFieldOptions";
import {DatePicker} from "antd";
import FormFieldType from "./FormFieldType";

const { RangePicker } = DatePicker;

class FormFieldDateRange {
    public static render = (options: IFieldOptions) => {
        const props = {
            style: options.style,
            allowClear: options.showClear,
            disabled: options.disabled,
            value: options.value
        };

        if (options.datePicker === FormFieldType.FIELD_DATE_DATE_TIME_PICKER) {
            return <RangePicker format={options.datePickerFormat} showTime={{format: options.datePickerTimeFormat}}
                                {...props} />
        }

        return <RangePicker format={options.datePickerFormat}{...props} picker={options.datePicker}/>
    }
}

export default FormFieldDateRange