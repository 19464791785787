import React from 'react';
import {Provider} from 'react-redux';
import store from './redux/store';
import {Route, Router, Switch} from 'react-router-dom';
import Components from "./components/Components";
import history from "./history";

function App() {
    document.body.className += ' dir-ltr'

    return (
        <div className="App h-100">
            <Provider store={store}>
                <Router history={history}>
                    <Switch>
                        <Route path="/" component={Components}/>
                    </Switch>
                </Router>
            </Provider>
        </div>
    );

}


export default App;
