import React, {RefObject} from "react";
import {Col, Divider, Form, FormInstance, InputNumber, Row, Slider, Switch} from "antd";
import IWidgetOptions from "../../../../../model/interface/widget/IWidgetOptions";
import FilePicker from "../../../../shared/pickers/file/FilePicker";
import FieldPicker from "../../../../shared/pickers/FieldPicker";
import IContentType from "../../../../../model/interface/dataStorage/IContentType";
import FilesService from "../../../../../model/service/file/FilesService";
import IImageWidget from "../../../../../model/interface/widget/option/IImageWidget";

interface IProps {
    options: IImageWidget
    onFinish: (values?: IWidgetOptions) => void
    formRef: RefObject<FormInstance>
    contentType?: IContentType
}

interface IState {
    noWidth?: boolean,
    noHeight?: boolean
}

class ImageWidgetEditor extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props)
        this.state = {
            noWidth: !!props.formRef.current?.getFieldValue('width'),
            noHeight: !!props.formRef.current?.getFieldValue('height')
        }
    }

    updateWidth = (width?: string | number, none: boolean = false) => {
        this.setState({noWidth: none})
        const {formRef, options, onFinish} = this.props
        onFinish({...options, width: width ? Number(width) : undefined})
        formRef.current?.setFieldsValue({width})
    }

    updateHeight = (height?: string | number, none: boolean = false) => {
        this.setState({noHeight: none})
        const {formRef, options, onFinish} = this.props
        onFinish({...options, height: height ? Number(height) : undefined})
        formRef.current?.setFieldsValue({height})
    }

    render() {
        const {noWidth, noHeight} = this.state
        const {contentType, options} = this.props
        const {file, width, height, field} = options

        return (
            <div>
                <Form.Item name={'file'} className={'mb-0'}
                           rules={!field ? [{required: true, message: 'soubor je vyžadován'}] : []}>
                    <FilePicker placeholder={'Nahrát'} disabled={!field} extensions={['image/*']}/>
                </Form.Item>

                {contentType?.fields.some(f => f.targetEntity === FilesService.getRecordClassName()) && (
                    <>
                        <Divider>nebo</Divider>
                        <Form.Item name={'field'} rules={[{required: true, message: 'soubor je vyžadován'}]}>
                            <FieldPicker allowTargetEntity={[FilesService.getRecordClassName()]} output={'name'}
                                         disabled={!!file} serviceClassName={contentType?.fullClassName}/>
                        </Form.Item>
                    </>
                )}
                {(file || field) && (
                    <>
                        <Form.Item name={'preview'} label={'Náhled obrázku'} valuePropName={'checked'}>
                            <Switch/>
                        </Form.Item>
                        <Form.Item name={noWidth ? undefined : 'width'} label={'Zvolte šířku'} className={'m-0'}>
                            <Slider tipFormatter={value => value + ' px'} disabled={noWidth}
                                    min={20} max={file?.currentVersion.width || 2000}/>
                        </Form.Item>
                        <Row justify={"space-between"} align={"middle"} className={'mb-3'}>
                            <Col>
                                <InputNumber value={width} disabled={noWidth}
                                             formatter={value => `${value} px`}
                                             parser={value => Number(value?.replace(' px', ''))}
                                             onChange={w => this.updateWidth(w)}/>
                            </Col>
                            <Col>
                                <Switch checked={noWidth} checkedChildren={'auto'} unCheckedChildren={'auto'}
                                        onChange={e => this.updateWidth(undefined, e)}/>
                            </Col>
                        </Row>
                        <Form.Item name={noHeight ? undefined : 'height'} label={'Zvolte výšku'} className={'m-0'}>
                            <Slider tipFormatter={value => value + ' px'} disabled={noHeight}
                                    min={20} max={file?.currentVersion.height || 2000}/>
                        </Form.Item>
                        <Row justify={"space-between"} align={"middle"} className={'mb-3'}>
                            <Col>
                                <InputNumber formatter={value => `${value} px`}
                                             parser={value => Number(value?.replace(' px', ''))}
                                             value={height} disabled={noHeight}
                                             onChange={e => this.updateHeight(e)}/>
                            </Col>
                            <Col>
                                <Switch checked={noHeight} checkedChildren={'auto'} unCheckedChildren={'auto'}
                                        onChange={e => this.updateHeight(undefined, e)}/>
                            </Col>
                        </Row>
                    </>
                )}
            </div>
        )
    }
}

export default ImageWidgetEditor