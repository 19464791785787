import IUser from "../../../model/interface/security/IUser";
import React from "react";
import {AvatarSize} from "antd/lib/avatar/SizeContext";
import AvatarWithDefault from "./AvatarWithDefault";
import EmployeesService from "../../../model/service/company/EmployeesService";

interface IProps {
    user: IUser
    size?: AvatarSize
    title?: string,
    className?: string
}

interface IState {
    size: AvatarSize
}

class UserAvatar extends React.Component<IProps, IState> {
    state = {
        size: this.props.size || "small"
    }
    render() {
        const {user} = this.props
        const {size} = this.state
        const {username, email} = user
        const currentEmployee = EmployeesService.getInstance().getCurrentEmployee(user)

        return (
            <AvatarWithDefault {...this.props} src={user && (user.avatar?.thumbnailUrl || currentEmployee?.photo?.thumbnailUrl)}
                               size={size} title={currentEmployee?.fullName || username || email}/>
        )
    }
}

export default UserAvatar