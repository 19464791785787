import React, {RefObject} from "react";
import {Button, Col, Form, FormInstance, Mentions, Row, Select} from "antd";
import RoutePicker from "../../../../shared/pickers/RoutePicker";
import {ICardStepProps} from "./CardModal";
import {RightOutlined, SwapOutlined} from "@ant-design/icons";
import IContentType from "../../../../../model/interface/dataStorage/IContentType";
import ICard, {CARD_RESERVED_NAMES} from "../../../../../model/interface/dataStorage/ICard";
import ActionPicker from "../../../../shared/pickers/ActionPicker";
import {connect} from "react-redux";
import selectors from "../../../../../redux/selectors";
import {IAppState} from "../../../../../redux/store";
import IRepositoryService from "../../../../../model/interface/IRepositoryService";
import TranslationInput from "../../../../shared/input/TranslationInput";
import LocaleText from "../../../settings/dictionary/LocaleText";
import PresenterPicker from "../../../../shared/pickers/PresenterPicker";
import ContentTypePresenterMentions from "../presenter/ContentTypePresenterMentions";

interface IState {
    formValues: ICard,
    changedCards: ICard[]
}

interface IProps extends ICardStepProps {
    contentType: IContentType
    findOneByFullClassName: (className: string) => IRepositoryService,
    getText: (code: string, fallBack?: string) => string
}

class CardProperties extends React.Component<IProps, IState> {

    formRef: RefObject<FormInstance> = React.createRef()

    constructor(props: Readonly<IProps> | IProps) {
        super(props);
        this.state = {
            formValues: {...props.card},
            changedCards: props.contentType.cards.filter(c => c.uuid !== props.card.uuid)
        }
    }

    confirm = (back?: boolean) => {
        const {card, onChange, setValid} = this.props
        const {formValues, changedCards} = this.state
        this.formRef.current?.validateFields().then(values => {
            setValid(true)
            onChange({...card, ...values, ...formValues}, back, changedCards)
        }).catch(() => setValid(false))
    }

    onValuesChange = (formValues: Partial<ICard>) => {
        this.setState(state => ({formValues: {...state.formValues, ...formValues}}), this.confirm)
    }

    getService() {
        const {findOneByFullClassName, contentType} = this.props
        return findOneByFullClassName(contentType.fullClassName);
    }

    render() {
        const {card, contentType, getText} = this.props

        return (
            <Row className={'flex-column h-100'} justify={"space-between"}>
                <Form
                    onValuesChange={this.onValuesChange}
                    initialValues={{
                        ...(card ? card : {}),
                        _label_presenter: this.getService().getPresenterList().find(p => p.value === card?.label?.slice(1))?.value
                    }}
                    ref={this.formRef}
                >
                    <Form.Item
                        label={<LocaleText code={'configuration.card.form.name'} fallback={'Systémový Název'}/>}
                        name={"name"}
                        rules={[{required: true}]}
                    >
                        <Mentions
                            split={''}
                            placeholder={getText('configuration.card.form.name.placeholder', 'název nebo zvolte zobrazení')}
                            prefix={'#'}
                        >
                            {Object.entries(CARD_RESERVED_NAMES).map(([label, value]) => (
                                <Mentions.Option key={value} value={value.slice(1)}>
                                    {label}
                                </Mentions.Option>
                            ))}
                        </Mentions>
                    </Form.Item>
                    <Form.Item
                        label={<LocaleText code={'configuration.card.form.label'} fallback={'Název'}/>}
                        rules={[{required: true}]} name={'label'}
                    >
                        <TranslationInput uuid={card.uuid} code={'configuration.card.' + card.uuid}
                                          customInput={(value, triggerSave, onChange, className) =>
                                              <Mentions
                                                  value={value}
                                                  onPressEnter={e => triggerSave?.(e.currentTarget.value)}
                                                  onBlur={e => triggerSave?.(e.target.value)}
                                                  split={''}
                                                  placeholder={"název nebo # pro zobrazení"}
                                                  prefix={'#'}
                                                  onChange={onChange}
                                                  className={className}
                                              >
                                                  {this.getService().getPresenterList().map(({value, label}) => (
                                                      <Mentions.Option key={value} value={value}>
                                                          {label}
                                                      </Mentions.Option>
                                                  ))}
                                              </Mentions>}/>
                    </Form.Item>
                    <Form.Item
                        label={<LocaleText code={'configuration.card.form.route'} fallback={'Cesta'}/>}
                        name={"route"}
                        rules={[{required: true}]}
                    >
                        <RoutePicker identifier={contentType.name}/>
                    </Form.Item>
                    <Form.Item
                        label={<LocaleText code={'configuration.card.form.action'} fallback={"Akce"}/>}
                        name={"action"}
                    >
                        <ActionPicker contentTypeUuid={contentType.uuid}/>
                    </Form.Item>
                </Form>
                <Button icon={<RightOutlined/>} onClick={() => this.confirm(false)} type={"primary"}>
                    <LocaleText code={'configuration.card.form.next-step'} fallback={'Následující krok'}/>
                </Button>
            </Row>
        )
    }
}

const mapStateToProps = (state: IAppState) => {
    return {
        findOneByFullClassName: (className: string) => selectors.services.findOneByFullClassName(state, className),
        getText: (code: string, fallback?: string) => selectors.dictionary.getMessage(state, code, fallback),
    }
}

export default connect(mapStateToProps)(CardProperties)