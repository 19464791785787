import React from 'react';
import {Button, Col, Divider, Form, Result, Row, Spin} from "antd";
import {LoginOutlined} from '@ant-design/icons';
import PasswordResetService from "../../model/service/security/PasswordResetService";
import {Link} from "react-router-dom";
import NewPasswordProperties from "../app/security/users/forms/NewPasswordProperties";

interface IProps {
    token: string
}

interface IState {
    loading: boolean,
    reset: boolean,
    showError: boolean
    validating: boolean
}

class PasswordResetForm extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            loading: false,
            reset: false,
            showError: false,
            validating: true
        }
        this.onPasswordReset = this.onPasswordReset.bind(this);
    }

    load() {
        PasswordResetService.check(this.props.token).catch(() => {
            this.setState({
                showError: true
            })
        }).finally(() => {
            this.setState({validating: false})
        })

    }

    onPasswordReset(values: any) {
        this.setState(_state => ({loading: true}))
        PasswordResetService.reset(values.password, this.props.token).then(() => {
            this.setState({reset: true})
        }).catch(() => {
            this.setState({
                showError: true
            })
        }).finally(() => {
            this.setState({loading: false})
        })
    }

    componentDidMount() {
        this.load()
    }

    render = () => {
        const {loading, reset, showError, validating} = this.state
        return (
            <>
                <Divider className={"text-muted font-weight-normal mt-4 mb-4 font-size-sm"}>
                    Obnovení zapomenutého hesla
                </Divider>
                {showError &&
                    <Result className={'p-3'} status="error"
                            title={<div className={'font-size-md'}>Neplatný odkaz</div>}
                            subTitle={'Zkuste se znovu přihlásit'}
                            extra={[
                                <Link key={'login'} to={'/auth/login'}>
                                    <Button type={'primary'} icon={<LoginOutlined/>}>Přihlásit se</Button>
                                </Link>
                            ]}
                    />
                }
                {reset && <Result className={'p-3'} status="success"
                                  title={<div className={'font-size-md'}>Heslo bylo nastaveno</div>}
                                  subTitle={'Prosím pokračujte na přihlašovací stránku'}
                                  extra={[
                                      <Link key={'login'} to="/auth/login">
                                          <Button key={'login'} type={'primary'} icon={<LoginOutlined/>}>
                                              Přihlásit se
                                          </Button>
                                      </Link>

                                  ]}
                />}
                <Spin spinning={validating}>
                    {!reset && !showError &&
                        <Form
                            layout="vertical"
                            name="password-reset"
                            onFinish={this.onPasswordReset}
                        >
                            <NewPasswordProperties/>
                            <Row>
                                <Col className={"text-center"} xs={24}>
                                    <Button type="primary" htmlType="submit" block loading={loading}>
                                        Nastavit heslo
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    }
                </Spin>
            </>
        )
    }

}

export default PasswordResetForm
