import React from "react";
import {Mentions} from "antd";
import IContentType from "../../../../../model/interface/dataStorage/IContentType";
import {connect, RootStateOrAny} from "react-redux";
import selectors from "../../../../../redux/selectors";
import IRepositoryService from "../../../../../model/interface/IRepositoryService";
import {RELATION_FIELD_TYPE} from "../../../../../model/interface/dataStorage/IField";

interface IProps {
    onChange?: (value?: string) => void,
    value?: string,
    contentType: IContentType,
    findServiceByClassName: (name: string) => IRepositoryService
    placeHolder?: string,
    onSubmit?: (value: string) => void
    rows?: number
    className?: string,
    getText: (code: string, fallBack?: string) => string
}

interface IState {

}

class ContentTypePresenterMentions extends React.Component<IProps, IState> {


    constructor(props: IProps, context: any) {
        super(props, context);
        this.state = {}
    }

    buildMentionOptions() {
        const {getText}  = this.props
        const output: { value: string, label: string }[] = []
        this.props.contentType.fields.forEach(field => {
            if (field.mode === "scalar") {
                output.push({value: field.name, label: field.label || field.name})
            }
            if (field.mode === 'relation') {
                if (field.type === RELATION_FIELD_TYPE.MANY_TO_ONE || field.type === RELATION_FIELD_TYPE.ONE_TO_ONE) {
                    const service = this.props.findServiceByClassName(field.targetEntity!)
                    if (!service) {
                        throw new Error('Service for field ' + field.name + ' was not found')
                    }
                    service.getPresenterList().forEach(presenter => {
                        output.push({
                            value: field.name + '|' + presenter.value,
                            label: (getText(field.label || field.name)) + ' - ' + getText(presenter.label)
                        })
                    })
                }
                if (field.type === RELATION_FIELD_TYPE.MANY_TO_MANY || field.type === RELATION_FIELD_TYPE.ONE_TO_MANY) {
                    output.push({value: field.name + '@length', label: (getText(field.label || field.name)) + ' (počet)'})
                }
            }
        })
        return output
    }

    render() {
        const {value, placeHolder, onChange, rows, onSubmit, className} = this.props

        return (
            <Mentions className={className} rows={rows}
                      placeholder={placeHolder || "Použijte #pro vložení vlastnosti"}
                      prefix={'#'}
                      value={value}
                      onChange={onChange}
                      onPressEnter={e => onSubmit?.(e.currentTarget.value)}
                      onBlur={e => onSubmit?.(e.target.value)}
            >
                {this.buildMentionOptions().map(option => (
                    <Mentions.Option key={option.value} value={option.value}>
                        {option.label}
                    </Mentions.Option>
                ))}
            </Mentions>
        )
    }
}


const mapStateToProps = (state: RootStateOrAny) => {
    return {
        findServiceByClassName: (name: string) => selectors.services.findOneByFullClassName(state, name),
        getText: (code: string, fallback?: string) => selectors.dictionary.getMessage(state, code, fallback)
    }
}
export default connect(mapStateToProps)(ContentTypePresenterMentions)