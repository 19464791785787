import React, {RefObject} from "react";
import {Form, FormInstance, Switch} from "antd";
import IType from "../../../../../../model/interface/company/workload/IType";
import IReportWidgetWorkloadAttendance from "../../../../../../model/interface/report/IReportWidgetWorkloadAttendance";

interface IProps {
    options: IReportWidgetWorkloadAttendance
    formRef?: RefObject<FormInstance>
}

interface IState {
    types?: IType[]
}

class WorkloadAttendanceWidgetEditor extends React.Component<IProps, IState> {


    constructor(props: IProps) {
        super(props)
        this.state = {}
    }

    render() {
        return (
            <div>
                <Form.Item name={'workloadAttendanceShowPlanRequest'} label={'Zobrazit tlačítko žádosti o dovolenou'}
                           valuePropName={'checked'}>
                    <Switch/>
                </Form.Item>
            </div>
        )
    }
}

export default WorkloadAttendanceWidgetEditor