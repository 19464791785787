import React, {RefObject} from "react";
import {Divider, Form, FormInstance, Input, Row, Select, Switch, Typography} from "antd";
import IField, {
    COMPOSITE_FIELD_TYPE,
    FIELD_MODE_COMPOSITE,
    FIELD_MODE_RELATION,
    FIELD_MODE_SCALAR,
    FIELD_TYPE
} from "../../../../../../../model/interface/dataStorage/IField";
import moment from "moment";
import ScalarFieldWidget from "../ScalarFieldWidget";
import IRepositoryService from "../../../../../../../model/interface/IRepositoryService";
import selectors from "../../../../../../../redux/selectors";
import {connect, RootStateOrAny} from "react-redux";
import ICardWidgetScalarField from "../../../../../../../model/interface/dataStorage/card/ICardWidgetScalarField";
import IContentType from "../../../../../../../model/interface/dataStorage/IContentType";
import FormFieldType from "../../../../form/FormElement/formField/FormFieldType";
import FieldEditor from "../../../../form/FormElement/optionEditor/FieldEditor";
import ConditionEditor from "../../../../form/FormElement/optionEditor/ConditionEditor";
import MomentJsDateFormat from "../../../../../../shared/input/MomentJsDateFormat";
import FieldCollapseInfo from "../../../field/FieldCollapseInfo";
import CompositeFieldApprovalOptionsEditor
    from "../../../field/optionEditors/composite/CompositeFieldApprovalOptionsEditor";
import FieldPhoneNumberOptionsEditor from "../../../field/optionEditors/FieldPhoneNumberOptionsEditor";
import FieldOptionsEditor from "../../../field/FieldOptionsEditor";
import IBaseProps from "../../../../../../../model/interface/IBaseProps";
import ActionPicker from "../../../../../../shared/pickers/ActionPicker";
import {ActionTypes} from "../../../../../../../model/interface/dataStorage/IAction";
import FieldEmojiOptionsEditor from "../../../field/optionEditors/FieldEmojiOptionsEditor";
import TranslationInput from "../../../../../../shared/input/TranslationInput";
import ICardWidget from "../../../../../../../model/interface/dataStorage/card/ICardWidget";


interface IProps extends IBaseProps {
    options: ICardWidgetScalarField
    findServiceByClassName: (name: string) => IRepositoryService,
    findContentTypeBy: (property: string, value: any) => IContentType,
    field: IField
    fields: IField[]
    widget: ICardWidget
}

class ScalarFieldEditor extends React.Component<IProps> {

    formRef = React.createRef() as RefObject<FormInstance>

    static detectType(field: IField) {
        switch (field.mode) {
            case FIELD_MODE_SCALAR:
                return {
                    [FIELD_TYPE.STRING]: ScalarFieldWidget.TEXT,
                    [FIELD_TYPE.TEXT]: ScalarFieldWidget.TEXT,
                    [FIELD_TYPE.INTEGER]: ScalarFieldWidget.NUMBER,
                    [FIELD_TYPE.PRICE]: ScalarFieldWidget.NUMBER,
                    [FIELD_TYPE.FLOAT]: ScalarFieldWidget.NUMBER,
                    [FIELD_TYPE.BASE64]: ScalarFieldWidget.BASE_64,
                    [FIELD_TYPE.BOOLEAN]: ScalarFieldWidget.BOOLEAN,
                    [FIELD_TYPE.DATE_TIME]: ScalarFieldWidget.DATE,
                    [FIELD_TYPE.PHONE_NUMBER]: ScalarFieldWidget.PHONE_NUMBER,
                    [FIELD_TYPE.EMOJI]: ScalarFieldWidget.EMOJI
                }[field.type] || ScalarFieldWidget.TEXT
            case FIELD_MODE_COMPOSITE:
                return {
                    [COMPOSITE_FIELD_TYPE.APPROVAL]: ScalarFieldWidget.COMPOSITE_APPROVAL
                }[field.type] || ScalarFieldWidget.TEXT
            case FIELD_MODE_RELATION:
                return ScalarFieldWidget.RELATION
            default:
                return ''
        }
    }

    getRelationFieldPresenters() {
        const {field} = this.props
        if (!field.targetEntity) {
            throw new Error(`Target entity not defined for contentType[${field.contentTypeId}] field[${field.name}]`)
        }

        let service: IRepositoryService = this.props.findServiceByClassName(field.targetEntity)

        if (!service) {
            throw new Error('Service for "' + field.targetEntity + '" is missing')
        }

        return service.getPresenterList()
    }

    buildActionOptions(type?: string) {
        const contentType = this.props.findContentTypeBy('uuid', this.props.field.contentTypeId)
        return contentType.actions.filter(item => {
            if (type !== undefined) {
                return item.type === 'form'
            } else {
                return item;
            }
        }).map(action => ({
            value: action.uuid,
            label: action.label
        }))
    }

    render() {

        const {field, fields, findContentTypeBy, history, match, options, widget} = this.props
        const type = ScalarFieldEditor.detectType(field)
        const {showLabel, inPlaceFieldOptions} = {showLabel: true, ...options}

        const dateFormatRules = [
            {required: true, message: 'Prosím vyplňte format'},
            () => ({
                validator(_: any, value: string) {
                    //TODO : is valid doest work when localized format is applied. Maybe add better validation ??
                    if (/*moment(moment().format(value)).isValid() && */value !== moment().format(value)) {
                        return Promise.resolve();
                    }
                    return Promise.reject(new Error('Formát se zdá být neplatný'));
                }
            })]

        return (
            <div>
                <FieldCollapseInfo field={field}/>

                <Form.Item initialValue={true} name={'showLabel'} label={'Zobrazit popisek'} valuePropName={'checked'}>
                    <Switch/>
                </Form.Item>
                {showLabel && (
                    <Form.Item name={'label'} label={'Popisek'} rules={[{required: true}]} initialValue={field.label}>
                        <TranslationInput uuid={field.uuid} code={'configuration.card.field.widget.' + widget.uuid}/>
                    </Form.Item>
                )}
                <Form.Item name={'titleLevel'} label={'Formátovat jako nadpis'} rules={[{required: true}]} initialValue={0}>
                    <Select>
                        {['Vypnuto', 'Velký nadpis', 'Střední nadpis', 'Malý nadpis']
                            .map((size, index) => (
                                <Select.Option key={index} value={index}>{size}</Select.Option>
                            ))}
                    </Select>
                </Form.Item>
                <Form.Item name={'emptyText'} label={'Text pro prázdnou hotnotu'} initialValue={'není vyplněno'}
                           rules={[{required: true}]}>
                    <Input/>
                </Form.Item>
                <Form.Item name={'type'} label={'Zvolte typ vstupu'} rules={[{required: true}]}>
                    <Select>
                        {ScalarFieldWidget.TYPES.map(type => (
                            <Select.Option key={type.value} value={type.value}>
                                <Row justify={"space-between"}>
                                    <div>{type.label}</div>
                                    <div>{ScalarFieldWidget.renderPreview(type.value)}</div>
                                </Row>
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                {type === ScalarFieldWidget.DATE && (
                    <div>
                        <Form.Item label={'Formát'} name={'dateFormat'} rules={dateFormatRules} initialValue={'LLL'}>
                            <MomentJsDateFormat/>
                        </Form.Item>
                    </div>

                )}
                {type === ScalarFieldWidget.COMPOSITE_APPROVAL && (
                    <div>
                        <CompositeFieldApprovalOptionsEditor buildFieldName={(...args) => args}
                                                             contentType={findContentTypeBy('uuid', field.contentTypeId)}
                                                             options={{...options}}/>
                    </div>
                )}
                {type === ScalarFieldWidget.PHONE_NUMBER && (
                    <div>
                        <FieldPhoneNumberOptionsEditor buildFieldName={(...args) => args} options={{...options}}/>
                    </div>
                )}
                {type === ScalarFieldWidget.RELATION && (
                    <Form.Item name={'relationPresenter'} label={'Presenter'} rules={[{required: true}]}
                               initialValue={this.getRelationFieldPresenters()[0]?.value}>
                        <Select>
                            {this.getRelationFieldPresenters().map(type => (
                                <Select.Option key={type.value} value={type.value}>{type.label}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                )}
                {type === ScalarFieldWidget.EMOJI && (
                   <FieldEmojiOptionsEditor options={options} buildFieldName={(...args) => args} noOriginal={true}/>
                )}
                <Form.Item name={'prefix'} label={'Prefix'}>
                    <Input/>
                </Form.Item>
                <Form.Item name={'suffix'} label={'Suffix'}>
                    <Input/>
                </Form.Item>
                {field.contentTypeId && (
                    <div>
                        <Divider>Editace na kartě</Divider>

                        <Form.Item name={'inPlaceEdit'} label={'Povolená editace'} valuePropName={'checked'}>
                            <Switch/>
                        </Form.Item>
                        <Form.Item name={'inPlaceAction'} label={'Zobrazit na základě oprávnění k akci'}>
                            <Select options={this.buildActionOptions()}/>
                        </Form.Item>
                        <Form.Item name={'inPlaceDataAction'} label={'Akce pro data'}>
                            <ActionPicker contentTypeUuid={field.contentTypeId} types={[ActionTypes.FORM]}/>
                        </Form.Item>
                        <Form.Item name={'inPlaceType'} label={'Zvolte typ vstupu'}
                                   initialValue={FieldEditor.detectType(field)}>
                            <Select>
                                {FormFieldType.FIELD_TYPES.map(type => (
                                    <Select.Option key={type.value} value={type.value}>{type.label}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Typography.Text>Deaktivovat pole na základě podmínek</Typography.Text>
                        <Form.Item name={'inPlaceConditions'} noStyle={true}>
                            <ConditionEditor fields={fields}/>
                        </Form.Item>
                        <FieldOptionsEditor field={field} contentType={findContentTypeBy('uuid', field.contentTypeId)}
                                            fieldName={['inPlaceFieldOptions']} history={history} match={match}
                                            title={'Vlastnosti pole'} collapsable={true}
                                            options={inPlaceFieldOptions || field.options}
                        />
                    </div>
                )}
            </div>
        )
    }
}

const mapStateToProps = (state: RootStateOrAny) => {
    return {
        findServiceByClassName: (name: string) => selectors.services.findOneByFullClassName(state, name),
        findContentTypeBy: (property: string, value: any) => selectors.contentTypes.findOneBy(state, property, value),
    }
}

export default connect(mapStateToProps)(ScalarFieldEditor)
