import {
    SETUP_BOOKMARKS_UPDATE,
    SETUP_END_LOADING,
    SETUP_LOAD,
    SETUP_LOCATION_STORE,
    SETUP_RELOAD,
    SETUP_SAVE,
    SETUP_START_LOADING,
    SETUP_UPDATE,
    SETUP_USER_UPDATE
} from '../constants/Setup';
import IRestServiceSetupResponse from "../../model/interface/api/IRestServiceSetupResponse";
import IUser from "../../model/interface/security/IUser";
import IBookmark from "../../model/interface/ui/IBookmark";
import {ISetupState} from "../reducers/Setup";

export const startLoading = () => {
    return {
        type: SETUP_START_LOADING,
        loading: true
    }
};
export const endLoading = () => {
    return {
        type: SETUP_END_LOADING,
        loading: false
    }
};
export const load = () => {
    return {
        type: SETUP_LOAD
    }
};
export const reLoad = () => {
    return {
        type: SETUP_RELOAD
    }
};
export const save = (response: IRestServiceSetupResponse) => {
    return {
        ...response,
        type: SETUP_SAVE
    }
};
export const userUpdate = (user: IUser) => {
    return {
        type: SETUP_USER_UPDATE,
        user
    }
};

export const bookmarksUpdate = (bookmarks: IBookmark[]) => {
    return {
        type: SETUP_BOOKMARKS_UPDATE,
        bookmarks
    }
};

export const update = (changes: Partial<ISetupState>) => {
    return {
        type: SETUP_UPDATE,
        ...changes
    }
};

export const storeLocation = (location: string) => {
    return {
        type: SETUP_LOCATION_STORE,
        location
    }
};

export default {
    load,
    reLoad,
    save,
    update,
    bookmarksUpdate,
    storeLocation
}