import IViewType from "../../interface/dataStorage/view/IViewType";
import {
    AppstoreAddOutlined,
    BarChartOutlined,
    CalendarOutlined,
    DashOutlined,
    DatabaseOutlined,
    FieldTimeOutlined,
    PicRightOutlined
} from "@ant-design/icons";
import React from "react";

interface IViewTypesService {
    collectionList: () => Promise<IViewType[]>
    get: (typ: string) => IViewType
}

export const VIEW_TYPE_TABLE = 'App\\DataStorage\\ViewType\\Table'
export const VIEW_TYPE_CALENDAR = 'App\\DataStorage\\ViewType\\Calendar'
export const VIEW_TYPE_GRAPH = 'App\\DataStorage\\ViewType\\Graph'
export const VIEW_TYPE_TIMELINE = 'App\\DataStorage\\ViewType\\Timeline'
export const VIEW_TYPE_KANBAN = 'App\\DataStorage\\ViewType\\Kanban'
export const VIEW_TYPE_PIVOT_TABLE = 'App\\DataStorage\\ViewType\\PivotTable'
export const VIEW_TYPE_CAROUSEL = 'App\\DataStorage\\ViewType\\Carousel'


export const viewTypes: IViewType[] = [
    {
        name: VIEW_TYPE_CALENDAR,
        label: 'Kalendář',
        description: 'Zobrazení různých typů obsahu v jednom kalendáři.',
        multiple: true,
        icon: <CalendarOutlined/>
    },
    {
        name: VIEW_TYPE_TABLE,
        label: 'Tabulka',
        description: 'Tabulkový výpis s možností voleb jednotlivých polí.',
        multiple: false,
        icon: <DatabaseOutlined/>
    },
    {
        name: VIEW_TYPE_KANBAN,
        label: 'Kanban',
        description: 'Úkoly členěné do sloupců a řazených dle priorit.',
        multiple: true,
        icon: <AppstoreAddOutlined/>
    },
    {
        name: VIEW_TYPE_GRAPH,
        label: 'Graf',
        description: 'Graficky zobrazené přehledy a analýzy dat',
        multiple: false,
        icon: <BarChartOutlined/>
    },
    {
        name: VIEW_TYPE_TIMELINE,
        label: 'Časová osa',
        description: 'Grafické znázornění časového období, na kterém jsou vyznačeny důležité události.',
        multiple: false,
        icon: <FieldTimeOutlined/>
    },
    {
        name: VIEW_TYPE_PIVOT_TABLE,
        label: 'Kontingenční tabulka',
        description: 'Tabulka umožňuje analyzovat, agregovat a shrnovat velké množství dat.',
        multiple: false,
        icon: <PicRightOutlined/>
    },
    {
        name: VIEW_TYPE_CAROUSEL,
        label: 'Kartotéka',
        description: 'Umožňuje zobrazit záznamy v jednom interaktivním „posuvném“ bloku zobrazujícím kartu záznamu',
        multiple: false,
        icon: <DashOutlined/>
    }
]

const ViewTypesService: IViewTypesService = {
    collectionList: function () {
        return new Promise(resolve => {
            resolve(viewTypes)
        })
    },
    get: (type) => {
        const foundType = viewTypes.find(viewType => viewType.name === type)
        if (foundType) {
            return foundType;
        }
        throw new Error(`View type [${type}] does not exist!`);
    }
}

export default ViewTypesService