import React, {Component} from 'react';

import {ReactComponent as AF} from '../../../assets/flags/4x3/af.svg'
import {ReactComponent as AX} from '../../../assets/flags/4x3/ax.svg'
import {ReactComponent as AL} from '../../../assets/flags/4x3/al.svg'
import {ReactComponent as DZ} from '../../../assets/flags/4x3/dz.svg'
import {ReactComponent as AS} from '../../../assets/flags/4x3/as.svg'
import {ReactComponent as AD} from '../../../assets/flags/4x3/ad.svg'
import {ReactComponent as AO} from '../../../assets/flags/4x3/ao.svg'
import {ReactComponent as AI} from '../../../assets/flags/4x3/ai.svg'
import {ReactComponent as AQ} from '../../../assets/flags/4x3/aq.svg'
import {ReactComponent as AG} from '../../../assets/flags/4x3/ag.svg'
import {ReactComponent as AR} from '../../../assets/flags/4x3/ar.svg'
import {ReactComponent as AM} from '../../../assets/flags/4x3/am.svg'
import {ReactComponent as AW} from '../../../assets/flags/4x3/aw.svg'
import {ReactComponent as AC} from '../../../assets/flags/4x3/ac.svg'
import {ReactComponent as AU} from '../../../assets/flags/4x3/au.svg'
import {ReactComponent as AT} from '../../../assets/flags/4x3/at.svg'
import {ReactComponent as AZ} from '../../../assets/flags/4x3/az.svg'
import {ReactComponent as BS} from '../../../assets/flags/4x3/bs.svg'
import {ReactComponent as BH} from '../../../assets/flags/4x3/bh.svg'
import {ReactComponent as BD} from '../../../assets/flags/4x3/bd.svg'
import {ReactComponent as BB} from '../../../assets/flags/4x3/bb.svg'
import {ReactComponent as BY} from '../../../assets/flags/4x3/by.svg'
import {ReactComponent as BE} from '../../../assets/flags/4x3/be.svg'
import {ReactComponent as BZ} from '../../../assets/flags/4x3/bz.svg'
import {ReactComponent as BJ} from '../../../assets/flags/4x3/bj.svg'
import {ReactComponent as BM} from '../../../assets/flags/4x3/bm.svg'
import {ReactComponent as BT} from '../../../assets/flags/4x3/bt.svg'
import {ReactComponent as BO} from '../../../assets/flags/4x3/bo.svg'
import {ReactComponent as BQ} from '../../../assets/flags/4x3/bq.svg'
import {ReactComponent as BA} from '../../../assets/flags/4x3/ba.svg'
import {ReactComponent as BW} from '../../../assets/flags/4x3/bw.svg'
import {ReactComponent as BV} from '../../../assets/flags/4x3/bv.svg'
import {ReactComponent as BR} from '../../../assets/flags/4x3/br.svg'
import {ReactComponent as IO} from '../../../assets/flags/4x3/io.svg'
import {ReactComponent as BN} from '../../../assets/flags/4x3/bn.svg'
import {ReactComponent as BG} from '../../../assets/flags/4x3/bg.svg'
import {ReactComponent as BF} from '../../../assets/flags/4x3/bf.svg'
import {ReactComponent as BI} from '../../../assets/flags/4x3/bi.svg'
import {ReactComponent as CV} from '../../../assets/flags/4x3/cv.svg'
import {ReactComponent as KH} from '../../../assets/flags/4x3/kh.svg'
import {ReactComponent as CM} from '../../../assets/flags/4x3/cm.svg'
import {ReactComponent as CA} from '../../../assets/flags/4x3/ca.svg'
import {ReactComponent as IC} from '../../../assets/flags/4x3/ic.svg'
import {ReactComponent as ES_CT} from '../../../assets/flags/4x3/es-ct.svg'
import {ReactComponent as ES_PV} from '../../../assets/flags/4x3/es-pv.svg'
import {ReactComponent as KY} from '../../../assets/flags/4x3/ky.svg'
import {ReactComponent as CF} from '../../../assets/flags/4x3/cf.svg'
import {ReactComponent as CEFTA} from '../../../assets/flags/4x3/cefta.svg'
import {ReactComponent as EA} from '../../../assets/flags/4x3/ea.svg'
import {ReactComponent as TD} from '../../../assets/flags/4x3/td.svg'
import {ReactComponent as CL} from '../../../assets/flags/4x3/cl.svg'
import {ReactComponent as CN} from '../../../assets/flags/4x3/cn.svg'
import {ReactComponent as CX} from '../../../assets/flags/4x3/cx.svg'
import {ReactComponent as CP} from '../../../assets/flags/4x3/cp.svg'
import {ReactComponent as CC} from '../../../assets/flags/4x3/cc.svg'
import {ReactComponent as CO} from '../../../assets/flags/4x3/co.svg'
import {ReactComponent as KM} from '../../../assets/flags/4x3/km.svg'
import {ReactComponent as CK} from '../../../assets/flags/4x3/ck.svg'
import {ReactComponent as CR} from '../../../assets/flags/4x3/cr.svg'
import {ReactComponent as HR} from '../../../assets/flags/4x3/hr.svg'
import {ReactComponent as CU} from '../../../assets/flags/4x3/cu.svg'
import {ReactComponent as CW} from '../../../assets/flags/4x3/cw.svg'
import {ReactComponent as CY} from '../../../assets/flags/4x3/cy.svg'
import {ReactComponent as CZ} from '../../../assets/flags/4x3/cz.svg'
import {ReactComponent as CI} from '../../../assets/flags/4x3/ci.svg'
import {ReactComponent as CD} from '../../../assets/flags/4x3/cd.svg'
import {ReactComponent as DK} from '../../../assets/flags/4x3/dk.svg'
import {ReactComponent as DG} from '../../../assets/flags/4x3/dg.svg'
import {ReactComponent as DJ} from '../../../assets/flags/4x3/dj.svg'
import {ReactComponent as DM} from '../../../assets/flags/4x3/dm.svg'
import {ReactComponent as DO} from '../../../assets/flags/4x3/do.svg'
import {ReactComponent as EC} from '../../../assets/flags/4x3/ec.svg'
import {ReactComponent as EG} from '../../../assets/flags/4x3/eg.svg'
import {ReactComponent as SV} from '../../../assets/flags/4x3/sv.svg'
import {ReactComponent as GB_ENG} from '../../../assets/flags/4x3/gb-eng.svg'
import {ReactComponent as GQ} from '../../../assets/flags/4x3/gq.svg'
import {ReactComponent as ER} from '../../../assets/flags/4x3/er.svg'
import {ReactComponent as EE} from '../../../assets/flags/4x3/ee.svg'
import {ReactComponent as SZ} from '../../../assets/flags/4x3/sz.svg'
import {ReactComponent as ET} from '../../../assets/flags/4x3/et.svg'
import {ReactComponent as EU} from '../../../assets/flags/4x3/eu.svg'
import {ReactComponent as FK} from '../../../assets/flags/4x3/fk.svg'
import {ReactComponent as FO} from '../../../assets/flags/4x3/fo.svg'
import {ReactComponent as FM} from '../../../assets/flags/4x3/fm.svg'
import {ReactComponent as FJ} from '../../../assets/flags/4x3/fj.svg'
import {ReactComponent as FI} from '../../../assets/flags/4x3/fi.svg'
import {ReactComponent as FR} from '../../../assets/flags/4x3/fr.svg'
import {ReactComponent as GF} from '../../../assets/flags/4x3/gf.svg'
import {ReactComponent as PF} from '../../../assets/flags/4x3/pf.svg'
import {ReactComponent as TF} from '../../../assets/flags/4x3/tf.svg'
import {ReactComponent as GA} from '../../../assets/flags/4x3/ga.svg'
import {ReactComponent as ES_GA} from '../../../assets/flags/4x3/es-ga.svg'
import {ReactComponent as GM} from '../../../assets/flags/4x3/gm.svg'
import {ReactComponent as GE} from '../../../assets/flags/4x3/ge.svg'
import {ReactComponent as DE} from '../../../assets/flags/4x3/de.svg'
import {ReactComponent as GH} from '../../../assets/flags/4x3/gh.svg'
import {ReactComponent as GI} from '../../../assets/flags/4x3/gi.svg'
import {ReactComponent as GR} from '../../../assets/flags/4x3/gr.svg'
import {ReactComponent as GL} from '../../../assets/flags/4x3/gl.svg'
import {ReactComponent as GD} from '../../../assets/flags/4x3/gd.svg'
import {ReactComponent as GP} from '../../../assets/flags/4x3/gp.svg'
import {ReactComponent as GU} from '../../../assets/flags/4x3/gu.svg'
import {ReactComponent as GT} from '../../../assets/flags/4x3/gt.svg'
import {ReactComponent as GG} from '../../../assets/flags/4x3/gg.svg'
import {ReactComponent as GN} from '../../../assets/flags/4x3/gn.svg'
import {ReactComponent as GW} from '../../../assets/flags/4x3/gw.svg'
import {ReactComponent as GY} from '../../../assets/flags/4x3/gy.svg'
import {ReactComponent as HT} from '../../../assets/flags/4x3/ht.svg'
import {ReactComponent as HM} from '../../../assets/flags/4x3/hm.svg'
import {ReactComponent as VA} from '../../../assets/flags/4x3/va.svg'
import {ReactComponent as HN} from '../../../assets/flags/4x3/hn.svg'
import {ReactComponent as HK} from '../../../assets/flags/4x3/hk.svg'
import {ReactComponent as HU} from '../../../assets/flags/4x3/hu.svg'
import {ReactComponent as IS} from '../../../assets/flags/4x3/is.svg'
import {ReactComponent as IN} from '../../../assets/flags/4x3/in.svg'
import {ReactComponent as ID} from '../../../assets/flags/4x3/id.svg'
import {ReactComponent as IR} from '../../../assets/flags/4x3/ir.svg'
import {ReactComponent as IQ} from '../../../assets/flags/4x3/iq.svg'
import {ReactComponent as IE} from '../../../assets/flags/4x3/ie.svg'
import {ReactComponent as IM} from '../../../assets/flags/4x3/im.svg'
import {ReactComponent as IL} from '../../../assets/flags/4x3/il.svg'
import {ReactComponent as IT} from '../../../assets/flags/4x3/it.svg'
import {ReactComponent as JM} from '../../../assets/flags/4x3/jm.svg'
import {ReactComponent as JP} from '../../../assets/flags/4x3/jp.svg'
import {ReactComponent as JE} from '../../../assets/flags/4x3/je.svg'
import {ReactComponent as JO} from '../../../assets/flags/4x3/jo.svg'
import {ReactComponent as KZ} from '../../../assets/flags/4x3/kz.svg'
import {ReactComponent as KE} from '../../../assets/flags/4x3/ke.svg'
import {ReactComponent as KI} from '../../../assets/flags/4x3/ki.svg'
import {ReactComponent as XK} from '../../../assets/flags/4x3/xk.svg'
import {ReactComponent as KW} from '../../../assets/flags/4x3/kw.svg'
import {ReactComponent as KG} from '../../../assets/flags/4x3/kg.svg'
import {ReactComponent as LA} from '../../../assets/flags/4x3/la.svg'
import {ReactComponent as LV} from '../../../assets/flags/4x3/lv.svg'
import {ReactComponent as LB} from '../../../assets/flags/4x3/lb.svg'
import {ReactComponent as LS} from '../../../assets/flags/4x3/ls.svg'
import {ReactComponent as LR} from '../../../assets/flags/4x3/lr.svg'
import {ReactComponent as LY} from '../../../assets/flags/4x3/ly.svg'
import {ReactComponent as LI} from '../../../assets/flags/4x3/li.svg'
import {ReactComponent as LT} from '../../../assets/flags/4x3/lt.svg'
import {ReactComponent as LU} from '../../../assets/flags/4x3/lu.svg'
import {ReactComponent as MO} from '../../../assets/flags/4x3/mo.svg'
import {ReactComponent as MG} from '../../../assets/flags/4x3/mg.svg'
import {ReactComponent as MW} from '../../../assets/flags/4x3/mw.svg'
import {ReactComponent as MY} from '../../../assets/flags/4x3/my.svg'
import {ReactComponent as MV} from '../../../assets/flags/4x3/mv.svg'
import {ReactComponent as ML} from '../../../assets/flags/4x3/ml.svg'
import {ReactComponent as MT} from '../../../assets/flags/4x3/mt.svg'
import {ReactComponent as MH} from '../../../assets/flags/4x3/mh.svg'
import {ReactComponent as MQ} from '../../../assets/flags/4x3/mq.svg'
import {ReactComponent as MR} from '../../../assets/flags/4x3/mr.svg'
import {ReactComponent as MU} from '../../../assets/flags/4x3/mu.svg'
import {ReactComponent as YT} from '../../../assets/flags/4x3/yt.svg'
import {ReactComponent as MX} from '../../../assets/flags/4x3/mx.svg'
import {ReactComponent as MD} from '../../../assets/flags/4x3/md.svg'
import {ReactComponent as MC} from '../../../assets/flags/4x3/mc.svg'
import {ReactComponent as MN} from '../../../assets/flags/4x3/mn.svg'
import {ReactComponent as ME} from '../../../assets/flags/4x3/me.svg'
import {ReactComponent as MS} from '../../../assets/flags/4x3/ms.svg'
import {ReactComponent as MA} from '../../../assets/flags/4x3/ma.svg'
import {ReactComponent as MZ} from '../../../assets/flags/4x3/mz.svg'
import {ReactComponent as MM} from '../../../assets/flags/4x3/mm.svg'
import {ReactComponent as NA} from '../../../assets/flags/4x3/na.svg'
import {ReactComponent as NR} from '../../../assets/flags/4x3/nr.svg'
import {ReactComponent as NP} from '../../../assets/flags/4x3/np.svg'
import {ReactComponent as NL} from '../../../assets/flags/4x3/nl.svg'
import {ReactComponent as NC} from '../../../assets/flags/4x3/nc.svg'
import {ReactComponent as NZ} from '../../../assets/flags/4x3/nz.svg'
import {ReactComponent as NI} from '../../../assets/flags/4x3/ni.svg'
import {ReactComponent as NE} from '../../../assets/flags/4x3/ne.svg'
import {ReactComponent as NG} from '../../../assets/flags/4x3/ng.svg'
import {ReactComponent as NU} from '../../../assets/flags/4x3/nu.svg'
import {ReactComponent as NF} from '../../../assets/flags/4x3/nf.svg'
import {ReactComponent as KP} from '../../../assets/flags/4x3/kp.svg'
import {ReactComponent as MK} from '../../../assets/flags/4x3/mk.svg'
import {ReactComponent as GB_NIR} from '../../../assets/flags/4x3/gb-nir.svg'
import {ReactComponent as MP} from '../../../assets/flags/4x3/mp.svg'
import {ReactComponent as NO} from '../../../assets/flags/4x3/no.svg'
import {ReactComponent as OM} from '../../../assets/flags/4x3/om.svg'
import {ReactComponent as PK} from '../../../assets/flags/4x3/pk.svg'
import {ReactComponent as PW} from '../../../assets/flags/4x3/pw.svg'
import {ReactComponent as PA} from '../../../assets/flags/4x3/pa.svg'
import {ReactComponent as PG} from '../../../assets/flags/4x3/pg.svg'
import {ReactComponent as PY} from '../../../assets/flags/4x3/py.svg'
import {ReactComponent as PE} from '../../../assets/flags/4x3/pe.svg'
import {ReactComponent as PH} from '../../../assets/flags/4x3/ph.svg'
import {ReactComponent as PN} from '../../../assets/flags/4x3/pn.svg'
import {ReactComponent as PL} from '../../../assets/flags/4x3/pl.svg'
import {ReactComponent as PT} from '../../../assets/flags/4x3/pt.svg'
import {ReactComponent as PR} from '../../../assets/flags/4x3/pr.svg'
import {ReactComponent as QA} from '../../../assets/flags/4x3/qa.svg'
import {ReactComponent as CG} from '../../../assets/flags/4x3/cg.svg'
import {ReactComponent as RO} from '../../../assets/flags/4x3/ro.svg'
import {ReactComponent as RU} from '../../../assets/flags/4x3/ru.svg'
import {ReactComponent as RW} from '../../../assets/flags/4x3/rw.svg'
import {ReactComponent as RE} from '../../../assets/flags/4x3/re.svg'
import {ReactComponent as BL} from '../../../assets/flags/4x3/bl.svg'
import {ReactComponent as SH} from '../../../assets/flags/4x3/sh.svg'
import {ReactComponent as KN} from '../../../assets/flags/4x3/kn.svg'
import {ReactComponent as LC} from '../../../assets/flags/4x3/lc.svg'
import {ReactComponent as MF} from '../../../assets/flags/4x3/mf.svg'
import {ReactComponent as PM} from '../../../assets/flags/4x3/pm.svg'
import {ReactComponent as VC} from '../../../assets/flags/4x3/vc.svg'
import {ReactComponent as WS} from '../../../assets/flags/4x3/ws.svg'
import {ReactComponent as SM} from '../../../assets/flags/4x3/sm.svg'
import {ReactComponent as ST} from '../../../assets/flags/4x3/st.svg'
import {ReactComponent as SA} from '../../../assets/flags/4x3/sa.svg'
import {ReactComponent as GB_SCT} from '../../../assets/flags/4x3/gb-sct.svg'
import {ReactComponent as SN} from '../../../assets/flags/4x3/sn.svg'
import {ReactComponent as RS} from '../../../assets/flags/4x3/rs.svg'
import {ReactComponent as SC} from '../../../assets/flags/4x3/sc.svg'
import {ReactComponent as SL} from '../../../assets/flags/4x3/sl.svg'
import {ReactComponent as SG} from '../../../assets/flags/4x3/sg.svg'
import {ReactComponent as SX} from '../../../assets/flags/4x3/sx.svg'
import {ReactComponent as SK} from '../../../assets/flags/4x3/sk.svg'
import {ReactComponent as SI} from '../../../assets/flags/4x3/si.svg'
import {ReactComponent as SB} from '../../../assets/flags/4x3/sb.svg'
import {ReactComponent as SO} from '../../../assets/flags/4x3/so.svg'
import {ReactComponent as ZA} from '../../../assets/flags/4x3/za.svg'
import {ReactComponent as GS} from '../../../assets/flags/4x3/gs.svg'
import {ReactComponent as KR} from '../../../assets/flags/4x3/kr.svg'
import {ReactComponent as SS} from '../../../assets/flags/4x3/ss.svg'
import {ReactComponent as ES} from '../../../assets/flags/4x3/es.svg'
import {ReactComponent as LK} from '../../../assets/flags/4x3/lk.svg'
import {ReactComponent as PS} from '../../../assets/flags/4x3/ps.svg'
import {ReactComponent as SD} from '../../../assets/flags/4x3/sd.svg'
import {ReactComponent as SR} from '../../../assets/flags/4x3/sr.svg'
import {ReactComponent as SJ} from '../../../assets/flags/4x3/sj.svg'
import {ReactComponent as SE} from '../../../assets/flags/4x3/se.svg'
import {ReactComponent as CH} from '../../../assets/flags/4x3/ch.svg'
import {ReactComponent as SY} from '../../../assets/flags/4x3/sy.svg'
import {ReactComponent as TW} from '../../../assets/flags/4x3/tw.svg'
import {ReactComponent as TJ} from '../../../assets/flags/4x3/tj.svg'
import {ReactComponent as TZ} from '../../../assets/flags/4x3/tz.svg'
import {ReactComponent as TH} from '../../../assets/flags/4x3/th.svg'
import {ReactComponent as TL} from '../../../assets/flags/4x3/tl.svg'
import {ReactComponent as TG} from '../../../assets/flags/4x3/tg.svg'
import {ReactComponent as TK} from '../../../assets/flags/4x3/tk.svg'
import {ReactComponent as TO} from '../../../assets/flags/4x3/to.svg'
import {ReactComponent as TT} from '../../../assets/flags/4x3/tt.svg'
import {ReactComponent as TA} from '../../../assets/flags/4x3/ta.svg'
import {ReactComponent as TN} from '../../../assets/flags/4x3/tn.svg'
import {ReactComponent as TR} from '../../../assets/flags/4x3/tr.svg'
import {ReactComponent as TM} from '../../../assets/flags/4x3/tm.svg'
import {ReactComponent as TC} from '../../../assets/flags/4x3/tc.svg'
import {ReactComponent as TV} from '../../../assets/flags/4x3/tv.svg'
import {ReactComponent as UG} from '../../../assets/flags/4x3/ug.svg'
import {ReactComponent as UA} from '../../../assets/flags/4x3/ua.svg'
import {ReactComponent as AE} from '../../../assets/flags/4x3/ae.svg'
import {ReactComponent as GB} from '../../../assets/flags/4x3/gb.svg'
import {ReactComponent as UN} from '../../../assets/flags/4x3/un.svg'
import {ReactComponent as UM} from '../../../assets/flags/4x3/um.svg'
import {ReactComponent as US} from '../../../assets/flags/4x3/us.svg'
import {ReactComponent as XX} from '../../../assets/flags/4x3/xx.svg'
import {ReactComponent as UY} from '../../../assets/flags/4x3/uy.svg'
import {ReactComponent as UZ} from '../../../assets/flags/4x3/uz.svg'
import {ReactComponent as VU} from '../../../assets/flags/4x3/vu.svg'
import {ReactComponent as VE} from '../../../assets/flags/4x3/ve.svg'
import {ReactComponent as VN} from '../../../assets/flags/4x3/vn.svg'
import {ReactComponent as VG} from '../../../assets/flags/4x3/vg.svg'
import {ReactComponent as VI} from '../../../assets/flags/4x3/vi.svg'
import {ReactComponent as GB_WLS} from '../../../assets/flags/4x3/gb-wls.svg'
import {ReactComponent as WF} from '../../../assets/flags/4x3/wf.svg'
import {ReactComponent as EH} from '../../../assets/flags/4x3/eh.svg'
import {ReactComponent as YE} from '../../../assets/flags/4x3/ye.svg'
import {ReactComponent as ZM} from '../../../assets/flags/4x3/zm.svg'
import {ReactComponent as ZW} from '../../../assets/flags/4x3/zw.svg'
import {Tooltip} from "antd";

import countries from "../../../assets/data/country.json";

const ICONS: {[prop: string]: React.FunctionComponent} = {
    af: AF,
    ax: AX,
    al: AL,
    dz: DZ,
    as: AS,
    ad: AD,
    ao: AO,
    ai: AI,
    aq: AQ,
    ag: AG,
    ar: AR,
    am: AM,
    aw: AW,
    ac: AC,
    au: AU,
    at: AT,
    az: AZ,
    bs: BS,
    bh: BH,
    bd: BD,
    bb: BB,
    by: BY,
    be: BE,
    bz: BZ,
    bj: BJ,
    bm: BM,
    bt: BT,
    bo: BO,
    bq: BQ,
    ba: BA,
    bw: BW,
    bv: BV,
    br: BR,
    io: IO,
    bn: BN,
    bg: BG,
    bf: BF,
    bi: BI,
    cv: CV,
    kh: KH,
    cm: CM,
    ca: CA,
    ic: IC,
    'es-ct': ES_CT,
    'es-pv': ES_PV,
    ky: KY,
    cf: CF,
    cefta: CEFTA,
    ea: EA,
    td: TD,
    cl: CL,
    cn: CN,
    cx: CX,
    cp: CP,
    cc: CC,
    co: CO,
    km: KM,
    ck: CK,
    cr: CR,
    hr: HR,
    cu: CU,
    cw: CW,
    cy: CY,
    cz: CZ,
    ci: CI,
    cd: CD,
    dk: DK,
    dg: DG,
    dj: DJ,
    dm: DM,
    do: DO,
    ec: EC,
    eg: EG,
    sv: SV,
    'gb-eng': GB_ENG,
    gq: GQ,
    er: ER,
    ee: EE,
    sz: SZ,
    et: ET,
    eu: EU,
    fk: FK,
    fo: FO,
    fm: FM,
    fj: FJ,
    fi: FI,
    fr: FR,
    gf: GF,
    pf: PF,
    tf: TF,
    ga: GA,
    'es-ga': ES_GA,
    gm: GM,
    ge: GE,
    de: DE,
    gh: GH,
    gi: GI,
    gr: GR,
    gl: GL,
    gd: GD,
    gp: GP,
    gu: GU,
    gt: GT,
    gg: GG,
    gn: GN,
    gw: GW,
    gy: GY,
    ht: HT,
    hm: HM,
    va: VA,
    hn: HN,
    hk: HK,
    hu: HU,
    is: IS,
    in: IN,
    id: ID,
    ir: IR,
    iq: IQ,
    ie: IE,
    im: IM,
    il: IL,
    it: IT,
    jm: JM,
    jp: JP,
    je: JE,
    jo: JO,
    kz: KZ,
    ke: KE,
    ki: KI,
    xk: XK,
    kw: KW,
    kg: KG,
    la: LA,
    lv: LV,
    lb: LB,
    ls: LS,
    lr: LR,
    ly: LY,
    li: LI,
    lt: LT,
    lu: LU,
    mo: MO,
    mg: MG,
    mw: MW,
    my: MY,
    mv: MV,
    ml: ML,
    mt: MT,
    mh: MH,
    mq: MQ,
    mr: MR,
    mu: MU,
    yt: YT,
    mx: MX,
    md: MD,
    mc: MC,
    mn: MN,
    me: ME,
    ms: MS,
    ma: MA,
    mz: MZ,
    mm: MM,
    na: NA,
    nr: NR,
    np: NP,
    nl: NL,
    nc: NC,
    nz: NZ,
    ni: NI,
    ne: NE,
    ng: NG,
    nu: NU,
    nf: NF,
    kp: KP,
    mk: MK,
    'gb-nir': GB_NIR,
    mp: MP,
    no: NO,
    om: OM,
    pk: PK,
    pw: PW,
    pa: PA,
    pg: PG,
    py: PY,
    pe: PE,
    ph: PH,
    pn: PN,
    pl: PL,
    pt: PT,
    pr: PR,
    qa: QA,
    cg: CG,
    ro: RO,
    ru: RU,
    rw: RW,
    re: RE,
    bl: BL,
    sh: SH,
    kn: KN,
    lc: LC,
    mf: MF,
    pm: PM,
    vc: VC,
    ws: WS,
    sm: SM,
    st: ST,
    sa: SA,
    'gb-sct': GB_SCT,
    sn: SN,
    rs: RS,
    sc: SC,
    sl: SL,
    sg: SG,
    sx: SX,
    sk: SK,
    si: SI,
    sb: SB,
    so: SO,
    za: ZA,
    gs: GS,
    kr: KR,
    ss: SS,
    es: ES,
    lk: LK,
    ps: PS,
    sd: SD,
    sr: SR,
    sj: SJ,
    se: SE,
    ch: CH,
    sy: SY,
    tw: TW,
    tj: TJ,
    tz: TZ,
    th: TH,
    tl: TL,
    tg: TG,
    tk: TK,
    to: TO,
    tt: TT,
    ta: TA,
    tn: TN,
    tr: TR,
    tm: TM,
    tc: TC,
    tv: TV,
    ug: UG,
    ua: UA,
    ae: AE,
    gb: GB,
    un: UN,
    um: UM,
    us: US,
    xx: XX,
    uy: UY,
    uz: UZ,
    vu: VU,
    ve: VE,
    vn: VN,
    vg: VG,
    vi: VI,
    'gb-wls': GB_WLS,
    wf: WF,
    eh: EH,
    ye: YE,
    zm: ZM,
    zw: ZW,
}

interface IProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLImageElement>, HTMLImageElement> {
    countryCode: string,
    tooltip?: boolean
}

class CountryFlag extends Component<IProps> {

    render() {
        const {countryCode, tooltip, ...props} = this.props
        const Icon = ICONS[countryCode]

        return Icon ? (
            <Tooltip visible={tooltip === false ? false : undefined}
                     title={countries.find(c => c.code === countryCode)?.name || ''}>
                <Icon style={{height: '1em'}} {...props}/>
            </Tooltip>
        ) : 'Invalid country lag code ' + countryCode
    }
}

export default CountryFlag
