import RestService from "model/service/dataStorage/RestService";
import IRestServiceOptions from "model/interface/api/IRestServiceOptions";
import INotification from "../../interface/ui/INotification";


const COLLECTION = 'my-notifications'
const COLLECTION_MARK_ALL_READ = COLLECTION + '/mark-all-read'
const COLLECTION_DELETE_ALL = COLLECTION + '/delete-all'

interface ICollectionService {
    collectionList: (options?: IRestServiceOptions) => void
    deleteAll: () => Promise<void>,
    markAllRead: () => Promise<void>,
    resourceUpdate: (id: number | string, data: {[K in keyof INotification]?: INotification[K]}) => Promise<INotification>,
}

const MyNotificationsService: ICollectionService = {
    collectionList: function (options?: IRestServiceOptions) {
        return RestService.collectionList<INotification>(COLLECTION, options)
    },
    resourceUpdate: function (id, data) {
        return RestService.resourceUpdate(COLLECTION, id, data)
    },
    deleteAll: () => RestService.collectionList(COLLECTION_DELETE_ALL).then(),
    markAllRead: () => RestService.collectionList(COLLECTION_MARK_ALL_READ).then()
}

export default MyNotificationsService