import {all} from 'redux-saga/effects';
import Auth from './Auth';
import Setup from './Setup';
import Notifications from './Notifications';

export default function* rootSaga(getState: any) {
  yield all([
    Auth(),
    Setup(),
    Notifications()
  ]);
}
