import React from "react";
import IField, {
    FIELD_MODE_COMPOSITE,
    FIELD_MODE_COMPUTED,
    FIELD_MODE_RELATION,
    FIELD_MODE_SCALAR
} from "../../../../model/interface/dataStorage/IField";
import {Col, Modal, Popover, Row, Table, Tooltip, Typography} from "antd";
import {
    CheckOutlined,
    CloseOutlined,
    EditOutlined,
    EyeOutlined,
    LockOutlined,
    PlusOutlined,
    UnorderedListOutlined,
    WarningOutlined
} from "@ant-design/icons";
import {IContentTypeStepProps} from "./ContentTypeConfiguration";
import _ from "underscore"
import Utils from "../../../../utils";
import Hotkey from "../../../shared/hotkey/Hotkey";
import ContentTypeFieldModal from "./field/ContentTypeFieldModal";
import selectors from "../../../../redux/selectors";
import {connect, RootStateOrAny} from "react-redux";
import IRepositoryService from "../../../../model/interface/IRepositoryService";
import {RemoveOutline} from "react-ionicons";
import Button from "../../../shared/button/Button";
import LocaleText from "../../settings/dictionary/LocaleText";

interface IProps extends IContentTypeStepProps {
    findServiceByClassName: (className: string) => IRepositoryService | null,
}

interface IState {
    field: IField | null,
    counter: number
}

class ContentTypeFields extends React.Component<IProps, IState> {


    constructor(props: Readonly<IProps> | IProps) {
        super(props);
        this.state = {
            field: null,
            counter: 0
        }
    }

    onModalConfirm = (field?: IField) => {
        if (field) {
            let fields = [...this.props.resource.fields]
            let index = Utils.findIndex(fields, {uuid: field.uuid})
            if (index >= 0) {
                fields[index] = field
            } else {
                fields.push(field)
            }
            this.props.onValuesChange({
                fields
            })
        }
        this.onSelectField(null)
    }

    onCreateField = () => {
        let {counter} = this.state
        const {resource} = this.props
        this.onSelectField({
            uuid: Utils.uuid(),
            name: '',
            mode: null,
            locked: false,
            type: '',
            targetEntity: '',
            options: {},
            unit: '',
            weight: 0,
            contentTypeId: resource.uuid,
            contentTypeName: false,
            loadInCollection: true,
            loadInResource: true,
            arguments: {}
        })
        this.setState({
            counter
        })
    }

    onSelectField = (field: IField | null) => {
        this.setState({field})
    }

    onDeleteField = (field: IField) => {
        Modal.confirm({
            title: 'Opravdu smazat?',
            onOk: () => {
                let fields = [...this.props.resource.fields]
                let index = Utils.findIndex(fields, {uuid: field.uuid})
                if (index < 0) {
                    throw new Error('Field index not found');
                }
                fields.splice(index, 1)
                this.props.onValuesChange({
                    fields
                })
            }
        })
    }

    render() {
        const {resource, fieldScalarTypes, compositeFieldTypes, findServiceByClassName} = this.props
        const {field} = this.state
        const fieldColumns = [
            {
                title: 'ID',
                dataIndex: 'id',
                render: (value: number) => {
                    return (
                        <>
                            {value ? (
                                <>
                                    {value}
                                </>
                            ) : (
                                <Typography.Text className={"text-muted"}>N/A</Typography.Text>
                            )}
                        </>
                    )
                }
            },
            {
                title: <LockOutlined/>,
                dataIndex: 'locked',
                render: (flag: boolean) => (
                    <>
                        {flag && <LockOutlined/>}
                    </>
                ),
            },
            {
                title: 'Titulek',
                dataIndex: 'label',
                render: (label: string) => (
                    <LocaleText code={label}/>
                ),
            },
            {
                title: 'Název',
                dataIndex: 'name'
            },
            {
                title: 'Typ',
                dataIndex: 'mode'
            },
            {
                title: 'Cíl / Formát',
                dataIndex: 'targetEntity',
                render: (value: string, field: IField) => {
                    let text = 'N/A'
                    if (field.mode === FIELD_MODE_SCALAR
                        || field.mode === FIELD_MODE_COMPUTED) {
                        const scalarType = _.findWhere(fieldScalarTypes, {value: field.type})
                        if (scalarType) {
                            text = scalarType.label
                        }
                    }
                    if (field.mode === FIELD_MODE_RELATION) {
                        const service = findServiceByClassName(field.targetEntity!)
                        text = field.targetEntity || ''
                        if (service && typeof service.getTitle === 'function') {
                            text = service.getTitle()
                        }
                    }
                    if (field.mode === FIELD_MODE_COMPOSITE) {
                        const compositeType = _.findWhere(compositeFieldTypes, {value: field.type})
                        if (compositeType) {
                            text = compositeType.label
                        }
                    }
                    return (
                        <>
                            {text}
                        </>
                    )
                }
            },
            {
                title: <UnorderedListOutlined/>,
                dataIndex: 'loadInCollection',
                render: (value: boolean) => (<>{value ? <CheckOutlined/> : <RemoveOutline/>}</>)
            },
            {
                title: <EyeOutlined/>,
                dataIndex: 'loadInResource',
                render: (value: boolean) => (<>{value ? <CheckOutlined/> : <RemoveOutline/>}</>)
            },
            {
                title: <Row justify={"end"}>
                    <Hotkey help={"Přidat nové pole"} keys={["Alt", "p"]} trigger={() => this.onCreateField()}>
                        <Button type={"success"} onClick={() => this.onCreateField()} icon={<PlusOutlined/>}>
                            <u className={'pl-2'}>P</u>řidat
                        </Button>
                    </Hotkey>
                </Row>,
                key: 'actions',
                dataIndex: 'actions',
                render: (_: any, elm: IField) => (
                    <div className="text-right d-flex justify-content-end">
                        <Tooltip title={"Zobrazit"}>
                            <Button onClick={() => this.onSelectField(elm)} type="link" className="mr-2"
                                    icon={<EditOutlined/>} size="small"/>
                        </Tooltip>
                        <Tooltip title={"Odstranit"}>
                            <Button onClick={() => this.onDeleteField(elm)} danger type="link" className="mr-2"
                                    icon={<CloseOutlined/>} size="small" disabled={elm.locked}/>
                        </Tooltip>
                    </div>
                )
            }
        ]
        const fields = resource.fields
            .sort((a, b) => (a.label || a.name) > (b.label || b.name) ? 1 : -1)
            .filter(f => !f.targetEntity || findServiceByClassName(f.targetEntity))

        return (
            <>
                {field && <ContentTypeFieldModal field={field} {...this.props} onFinish={this.onModalConfirm}/>}
                <Row justify={'space-between'}>
                    {resource.fields.length > fields.length &&
                        <Col>
                            <Popover content={<Table style={{maxHeight: '60vh', overflow: 'auto'}}
                                                     pagination={false}
                                                     columns={[...fieldColumns.filter(c => ![
                                                         'actions', 'loadInCollection', 'loadInResource'
                                                     ].includes(c.dataIndex))]}
                                                     dataSource={resource.fields.filter(f => f.targetEntity && !findServiceByClassName(f.targetEntity))}
                                                     rowKey='name'
                            />} title={false}>
                                <Button icon={<WarningOutlined/>} type={"link"} className={'text-warning'}>
                                    Pole řídící třídy s nepodporovanou relací ({resource.fields.length - fields.length})
                                </Button>
                            </Popover>
                        </Col>
                    }
                </Row>

                <Table
                    pagination={false}
                    columns={fieldColumns}
                    dataSource={fields}
                    rowKey='name'
                />
            </>
        )
    }
}

const mapStateToProps = (store: RootStateOrAny) => {
    return {
        findServiceByClassName: (className: string) => selectors.services.findOneOrNullByFullClassName(store, className),
    }
}

export default connect(mapStateToProps)(ContentTypeFields)