import React, {RefObject} from "react"
import {Button, Form, FormInstance, Mentions, Row} from "antd";
import _ from "underscore";
import IForm, {FORM_RESERVED_NAMES} from "../../../../model/interface/form/IForm";
import {IFormStepProps} from "./FormConfiguration";
import FieldPicker from "../../../shared/pickers/FieldPicker";
import FieldDefaultValuePicker from "../../../shared/pickers/FieldDefaultValuePicker";
import {CloseOutlined} from "@ant-design/icons";
import TranslationInput from "../../../shared/input/TranslationInput";
import Utils from "../../../../utils";

interface IStateFormProperties {
    valid: boolean,
    form: IForm,
    defaultValueFields: string[]
}

class FormProperties extends React.Component<IFormStepProps, IStateFormProperties> {

    formRef : RefObject<FormInstance> = React.createRef()

    constructor(props: IFormStepProps) {
        super(props);
        this.state = {
            valid: false,
            form: _.extend(props.form, {}),
            defaultValueFields: Object.keys(props.form.defaultValues || {})
        }
    }

    _isMounted = false;

    componentDidMount() {
        this._isMounted = true;
        this.checkValidity()
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    checkValidity(): void {
        this.formRef.current?.validateFields().then(form => {
            if (this._isMounted) {
                // const route = form.route ? ( _.findWhere(this.props.routes, { id: form.route }) || null ) : null
                this.setState({
                    valid: true,
                    form: {
                        ...this.state.form,
                        ...form,
                        defaultValues: form.defaultValues || {}
                        // route,
                    }
                })
            }
        }).catch(reason => {
            console.log(reason)
            this.setState({
                valid: false
            })
        })
    }

    confirm = () => {
        this.props.onChange(this.state.form, undefined, true)
    }

    addDefaultValueField = (field: string) => {
        this.setState(state => ({defaultValueFields: [...state.defaultValueFields, field]}))
    }

    removeDefaultField = (field: string) => {
        this.setState(state => ({defaultValueFields: [...state.defaultValueFields.filter(f => f !== field)]}), () => {
            const values = this.formRef.current?.getFieldsValue()
            this.onValuesChange({...values, defaultValues: values.defaultValues || {}})
        })
    }

    onValuesChange = (values: any) => {
        this.props.onChange(values)
    }

    // buildRouteOptions(): LabeledValue[] {
    //     return this.props.routes.map((route: IRoute) => {
    //         return {
    //             label: route.type === 'route' ? route.route.url : route.url,
    //             value: route.id || 0
    //         }
    //     })
    // }

    render() {
        const {form, contentType, match, history} = this.props
        const {valid, defaultValueFields} = this.state
        return (
            <>
                <Form ref={this.formRef} initialValues={{...form}}
                      onValuesChange={(_,values) => this.onValuesChange({...values, defaultValues: values.defaultValues || {}})}>
                    <Form.Item
                        label={"Název"}
                        name={"label"}
                        rules={[{required: true, message: 'Vyplňte název'}]}
                    >
                        <TranslationInput uuid={form.uuid || Utils.uuid()} code={'configuration.form.' + form.uuid} onChange={() => this.checkValidity()}/>
                    </Form.Item>
                    <Form.Item
                        label={"Systémový Název"}
                        name={"name"}
                        rules={[{required: true, message: 'Vyplňte systémový název'}]}
                    >
                        <Mentions
                            split={''}
                            placeholder={"název nebo # pro vyhrazený název"}
                            prefix={'#'}
                            onChange={() => this.checkValidity()}
                        >
                            {Object.entries(FORM_RESERVED_NAMES).map(([label, value]) => (
                                <Mentions.Option key={value} value={value.slice(1)}>
                                    {label}
                                </Mentions.Option>
                            ))}
                        </Mentions>
                    </Form.Item>
                    <Form.Item label={'Východzí hodnoty'}>
                        <FieldPicker className={'mb-2'} serviceClassName={contentType.fullClassName} output={'name'}
                                     onChange={field => this.addDefaultValueField(field as string)}/>
                        {defaultValueFields.map((field) => {
                            const fieldObject = contentType.fields.find(f => f.name === field)
                            return fieldObject ? (
                                <Row className={'w-100'} wrap={false}>
                                    <Form.Item label={fieldObject.label} className={'w-100 mb-2'}
                                               name={['defaultValues', field]}>
                                        <FieldDefaultValuePicker className={'w-100'} match={match} history={history}
                                                                 field={fieldObject}/>
                                    </Form.Item>
                                    <Button type={'link'} danger={true} icon={<CloseOutlined/>}
                                            onClick={() => this.removeDefaultField(field)}/>
                                </Row>
                            ) : ''
                        })}
                    </Form.Item>
                </Form>

                <Button disabled={!valid} type={"primary"} onClick={this.confirm}>
                    Následující krok
                </Button>
            </>
        )
    }
}

export default FormProperties