import React from "react";
import {Button, Result, Row, Spin} from "antd";
import ISource from "../../../../model/interface/import/ISource";
import IFile from "../../../../model/interface/file/IFile";
import SourcePreviewTree from "./source-preview/SourcPreviewTree";
import {connect, RootStateOrAny} from "react-redux";
import selectors from "../../../../redux/selectors";
import IRepositoryService from "../../../../model/interface/IRepositoryService";
import ImportService from "../../../../model/service/import/ImportService";
import SourcePreviewTable from "./source-preview/SourcePreviewTable";
import {ISourceImportStepProps} from "./SourceImport";
import {RollbackOutlined} from "@ant-design/icons";

interface IState {
    loading: boolean
    data?: Array<any>
}

interface IProps extends ISourceImportStepProps {
    findServiceByClassName: (className: string) => IRepositoryService
    onImportNew: () => void
}


export interface ISourcePreviewProps extends IProps {
    data: Array<any>
}

class SourcePreview extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            loading: false
        }
    }

    _isMounted = false;

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        this.load()
    }

    load() {
        let promises: Promise<void>[] = []
        this.setState({loading: true})
        promises.push(ImportService.retrievePreviewData(this.props.resource.id)
            .then(data => this.setState({data})))

        Promise.all(promises).then(() => this.setState({loading: false}))
    }

    setSourceFile(file: IFile) {
        this.props.updateResource({file: file.uuid} as ISource, false).then()
    }

    confirm = () => {
        this.props.setStep(1)
    }

    render() {
        const {loading, data} = this.state
        const {parentSource, onImportNew} = this.props
        const valid = data && data.length > 0 && Object.keys(data[0]).length > 0

        return (
            <div>
                <Spin className={'d-block'} spinning={loading}>
                    {data && (valid ? {
                        tree: <SourcePreviewTree data={data} {...this.props}/>,
                        table: <SourcePreviewTable data={data} {...this.props}/>
                    }[parentSource.mappingType] : (
                        <Result
                            status="error"
                            title="Tento soubor nemá správnou strukturu"
                            subTitle=""
                            extra={[
                                <Button onClick={onImportNew}>Importovat nový soubor</Button>,
                            ]}
                        />
                    ))}
                </Spin>
                 <Row justify={"space-between"} className={'mt-2'}>
                     <Button onClick={() => this.props.setStep(-1)} icon={<RollbackOutlined/>}>Předchozí krok</Button>
                     <Button disabled={!valid} type={"primary"} onClick={this.confirm}>
                         Přejít na import
                     </Button>
                </Row>

            </div>
        )
    }
}

const mapStateToProps = (store: RootStateOrAny) => {
    return {
        findServiceByClassName: (className: string) => selectors.services.findOneByFullClassName(store, className)
    }
}

export default connect(mapStateToProps)(SourcePreview)

