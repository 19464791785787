import React from "react";
import IFormElementProps from "../../../../../model/interface/form/IFormElementProps";
import FormElement from "../FormElement";

class FormElementButtons extends React.Component<IFormElementProps> {
    render() {
        const {id, functions, preview} = this.props
        const children = functions.getSortedChildren(id)
        return (
            <div>
                <div className={!preview ? "d-flex" : ""}>
                    {children.map(child => {
                        return (
                            <FormElement
                                {...this.props}
                                {...child}
                                key={child.id}
                                id={child.id}
                            />
                        )
                    })}
                </div>
            </div>
        );
    }
}

export default FormElementButtons