import React, {RefObject} from "react";
import {LoadingOutlined, UnorderedListOutlined} from "@ant-design/icons";
import ITimeTracker from "../../../../model/interface/extension/ITimeTracker";
import TimeTrackerService from "../../../../model/service/dataStorage/extension/TimeTrackerService";
import moment from "moment";
import {DATE_FORMAT_YYYY_MM_DD_HH_mm_ss} from "../../../../model/constants/DateConstant";
import {Badge, Divider, Form as AntFrom, FormInstance, Input, Modal, Popover, Row, Timeline, Tooltip} from "antd";
import Button from "../../../shared/button/Button";
import {MomentBuilder} from "../../../../utils/MomentBuilder";
import {connect, RootStateOrAny} from "react-redux";
import IUser from "../../../../model/interface/security/IUser";
import {API_FILTER_TYPE} from "../../../../model/constants/ApiConstant";
import IconBuilder from "../../../../utils/IconBuilder";
import SettingsService from "../../../../model/service/SettingsService";
import {FORM_RESERVED_NAMES} from "../../../../model/interface/form/IForm";
import Form from "../../form/Form";
import IBaseProps from "../../../../model/interface/IBaseProps";
import selectors from "../../../../redux/selectors";
import IRoute from "../../../../model/interface/dataStorage/IRoute";
import DataStorageHelper from "../../../../utils/DataStorageHelper";
import {humanTime} from "../../file-manager/utils/TimeDuration";


interface IState {
    loading: boolean
    current?: ITimeTracker,
    openForm: boolean
}

interface IProps extends IBaseProps {
    user: IUser
    isMobile: boolean
    findRouteByUuid: (uuid: string) => IRoute
}

class Trigger extends React.Component<IProps, IState> {

    formRef: RefObject<FormInstance> = React.createRef()

    constructor(props: IProps) {
        super(props);
        this.state = {
            loading: true,
            openForm: false
        }
    }

    componentDidMount() {
        this.load()
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (prevProps.user.id !== this.props.user.id) {
            this.load()
        }
    }

    load = () => {
        const {user} = this.props
        user.employees[0] ? TimeTrackerService.getInstance().collectionList({
            filters: {
                employee: {
                    type: API_FILTER_TYPE.EQUAL,
                    field: 'employee',
                    value: user.employees[0].id
                },
                endAt: {
                    type: API_FILTER_TYPE.IS_NULL,
                    field: 'endAt'
                }
            }
        }).then(({results}) => {
            this.setState({current: results[0], loading: false})
        }) : this.setState({loading: false})
    }

    start = () => {
        const slaveContentType = TimeTrackerService.getInstance().getSlaveContentType()
        this.formRef.current?.validateFields().then(values => {
            this.setState({loading: true})
            TimeTrackerService.getInstance().collectionCreate({
                ...(slaveContentType ? DataStorageHelper.buildFormData(values, slaveContentType) : values)
            }).then((time) => {
                this.setState({current: time, loading: false})
            }).catch(() => this.setState({loading: false}))
        })
    }

    end = () => {
        const {current} = this.state
        this.setState({loading: true, openForm: false})
        current && TimeTrackerService.getInstance().resourceUpdate(current.uuid, {
            endAt: moment().format(DATE_FORMAT_YYYY_MM_DD_HH_mm_ss)
        })
            .then(() => {
                this.setState({current: undefined, loading: false})
            }).catch(() => this.setState({loading: false}))
    }

    setOpenForm = () => {
        this.setState(state => ({openForm: !state.openForm}))
    }

    render() {
        const {history, findRouteByUuid, isMobile} = this.props
        const {current, loading, openForm} = this.state
        const iconName = SettingsService.getValue('timeTracker', 'play-icon') || 'ClockCircleOutlined'
        const iconStopName = SettingsService.getValue('timeTracker', 'stop-icon') || 'ClockStopOutlined'
        const reportHeaderLink = SettingsService.isEnabled('timeTracker', 'header-report-link')
        const reportRoute = SettingsService.getValue('timeTracker', 'report-route')
        const start = current && MomentBuilder.build(current.startAt)
        const timeElapsed = start ? moment().diff(start, 'm') : 0

        return current ? (
            <Popover visible={loading ? false : undefined} placement={'bottom'} title={current.name} content={<div>
                <Timeline pending={
                    <Button size={"small"} loading={loading} onClick={this.end} type={'danger'}
                            icon={IconBuilder(iconStopName)}>
                        Zastavit
                    </Button>
                }>
                    <Timeline.Item color="green">
                        {start?.format('lll')}
                    </Timeline.Item>
                </Timeline>
            </div>}>
                <Tooltip placement={'left'} title={'Zastavit sledování času'}>
                    <Badge count={<span className={'ant-badge-count'} style={{zIndex: 1}}>
                        {timeElapsed > 48 * 60 ?
                            humanTime(timeElapsed, 'minute', ["day"]) :
                            Math.round(timeElapsed / 60) + ":" +  String(Math.round(timeElapsed % 60)).padStart(2, '0')}
                    </span>} >
                        {IconBuilder(loading ? 'LoadingOutlined' : iconStopName, undefined, {
                            className: 'nav-icon cursor-pointer m-0',
                            onClick: this.end
                        })}
                    </Badge>
                </Tooltip>
            </Popover>

        ) : (<Row align={"middle"}>
                <div>
                    <Modal visible={openForm} title={'Spustit sledování času'} footer={[
                        <Button key={'run'} size={"small"} loading={loading} onClick={() => this.start()}
                                type={'success'}
                                icon={IconBuilder(iconName)}>
                            Spustit
                        </Button>
                    ]} onCancel={this.setOpenForm}>
                        <div>
                            {this.getForm()}
                        </div>
                    </Modal>
                    <Tooltip title={'Spustit sledování času'} placement={'bottom'}>
                        <div>
                            {loading ? <LoadingOutlined className={'nav-icon'}/> :
                                IconBuilder(iconName, undefined, {
                                    className: 'cursor-pointer nav-icon',
                                    onClick: this.setOpenForm
                                })}
                        </div>
                    </Tooltip>
                </div>
                {reportHeaderLink && reportRoute && (
                    <>
                    {isMobile && <Divider type={'vertical'}/>}
                        <Tooltip title={'Výkaz sledování času'} placement={'bottom'}>
                            <div>
                                <UnorderedListOutlined className={'cursor-pointer m-0 nav-icon'}
                                                       onClick={() => history.push(findRouteByUuid(reportRoute).url)}/>
                            </div>
                        </Tooltip>
                    </>
                )}
            </Row>
        )
    }

    getForm = () => {
        const {history, match} = this.props
        const form = TimeTrackerService.getInstance().getSlaveContentTypeForm(FORM_RESERVED_NAMES.TIME_TRACKER)
        if (form) {
            return <Form history={history} match={match} form={form} formRef={this.formRef} values={{}}
                         onSubmit={this.start}/>
        }

        return <AntFrom ref={this.formRef} onFinish={this.start}>
            <AntFrom.Item name={'name'}
                          rules={[{required: true, message: 'Vyplňte prosím název činnosti!'}]}>
                <Input placeholder={'název činnosti'}/>
            </AntFrom.Item>
        </AntFrom>
    }
}

const mapStateToProps = (state: RootStateOrAny) => {
    const {user} = state.setup
    return {
        user,
        findRouteByUuid: (uuid: string) => selectors.routes.findOneBy(state, 'uuid', uuid),
    }
}

export default connect(mapStateToProps)(Trigger)