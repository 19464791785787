import IRestServiceCollectionResponse from "../../../interface/api/IRestServiceCollectionResponse";
import RestService from "model/service/dataStorage/RestService";
import IRestServiceOptions from "model/interface/api/IRestServiceOptions";
import ITerm from "../../../interface/ui/dictionary/ITerm";
import IRestServiceChoiceListResponse from "../../../interface/api/IRestServiceChoiceListResponse";
import IContentType from "../../../interface/dataStorage/IContentType";
import fetch from "../../../auth/ETagCachedFetchInterceptor";

interface IRestDictionaryTermsServiceCollectionResponse extends IRestServiceCollectionResponse {
    results: Array<ITerm>
}

interface IRestDictionaryTermsService {
    collectionList(options?: IRestServiceOptions): Promise<IRestDictionaryTermsServiceCollectionResponse>,

    collectionCreate(data: any): Promise<ITerm>,

    resourceRetrieve(id: number | string): Promise<ITerm>,

    resourceUpdate(id: number | string, data: any): Promise<ITerm>,

    resourceDelete(id: number | string): Promise<void>,

    collectionList(): Promise<IRestServiceCollectionResponse>,

    import(id: number | string, file: number | string, changeKeys: string[]): Promise<boolean>

    choiceList(presenterName?: string, options?: IRestServiceOptions): Promise<IRestServiceChoiceListResponse>,
}

const COLLECTION = 'dictionary-terms'
const CHOICES = 'dictionary-terms-choices/'
const EXPORT = 'dictionary-terms-export'
const IMPORT = 'dictionary-terms-import'


const DictionaryTermsService: IRestDictionaryTermsService = {
    collectionList: function (options?: IRestServiceOptions) {
        return RestService.collectionList(COLLECTION, options as unknown as IRestServiceOptions) as Promise<IRestDictionaryTermsServiceCollectionResponse>
    },
    collectionCreate: function (data) {
        return RestService.collectionCreate(COLLECTION, data) as Promise<ITerm>
    },
    resourceRetrieve: function (id) {
        return RestService.resourceRetrieve(COLLECTION, id) as Promise<ITerm>
    },
    resourceDelete: function (id) {
        return RestService.resourceDelete(COLLECTION, id)
    },
    resourceUpdate: function (id, data) {
        return RestService.resourceUpdate(COLLECTION, id, data) as Promise<ITerm>
    },
    choiceList: function (presenterName = 'default', options) {
        return RestService.collectionList(CHOICES + presenterName, options) as unknown as Promise<IRestServiceChoiceListResponse>
    },
    import(id, file, changeKeys): Promise<boolean> {
        return fetch({
            method: 'post',
            url: '/' + IMPORT + '/' + file,
            data: {changeKeys}
        }).then(response => {
            // @ts-ignore // TODO
            return response.result;
        })
    },
}

export default DictionaryTermsService