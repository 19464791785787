import moment from "moment";

import {DATE_FORMAT_YYYY_MM_DD_HH_mm_ss} from "../../../../../../model/constants/DateConstant";
import {API_TOKEN_LIST} from "../../../../../../model/constants/ApiConstant";
import {
    COMPOSITE_FIELD_TYPE,
    FIELD_NUMBER_TYPES, FIELD_STRING_TYPES,
    FIELD_TEXT_TYPES,
    FIELD_TYPE,
    RELATION_FIELD_TYPES
} from "../../../../../../model/interface/dataStorage/IField";

class FormFieldType {
    static FIELD_SELECT = 'select'
    static FIELD_COLOR= 'color'
    static FIELD_ICON= 'icon'
    static FIELD_TEXT = 'input'
    static FIELD_NUMBER = 'number'
    static FIELD_BOOLEAN = 'boolean'
    static FIELD_SLIDER = 'slider'
    static FIELD_DATE = 'date'
    static FIELD_DATE_RANGE = 'dateRange'
    static FIELD_AUTOCOMPLETE = 'autocomplete'
    static FIELD_WYSIWYG = 'WYSIWYG'
    static FIELD_FILE = 'file'
    static FIELD_IMAGE_EDITOR = 'imageEditor'
    static FIELD_CONTENT_TYPE = 'contentType'
    static FIELD_CONTENT_TYPE_CASCADE = 'contentTypeCascade'
    static FIELD_COUNT = 'count'
    static FIELD_CURRENCY = 'currency'
    static FIELD_PHONE_NUMBER = 'phoneNumber'
    static FIELD_URL = 'url'
    static FIELD_EMAIL = 'email'
    static FIELD_ICO = 'ico'
    static FIELD_BANK_ACCOUNT_NUMBER = 'bank_account_number'
    static FIELD_SIGNATURE = 'signature'
    static FIELD_WORKFLOW_STATE = 'workflow_state'
    static FIELD_RATE = 'rate'
    static FIELD_EMPLOYEE = 'employee'
    static FIELD_COMPANY_STRUCTURE = 'companyStructure'
    static COMPOSITE_FIELD_APPROVAL = 'compositeApproval'
    static FIELD_CODE = 'code'
    static FIELD_EMOJI = 'emoji'


    static FIELD_TYPES = [
        {
            label: 'Select',
            value: FormFieldType.FIELD_SELECT,
            allowedMasterType: RELATION_FIELD_TYPES,
        },
        {
            label: 'Barva',
            value: FormFieldType.FIELD_COLOR,
            allowedMasterType: [FIELD_TYPE.COLOR],
        },
        {
            label: 'Ikona',
            value: FormFieldType.FIELD_ICON,
            allowedMasterType: [FIELD_TYPE.ICON],
        },
        {
            label: 'Text',
            value: FormFieldType.FIELD_TEXT,
            allowedMasterType: [...FIELD_TEXT_TYPES, ...FIELD_STRING_TYPES],
        },
        {
            label: 'Číslo',
            value: FormFieldType.FIELD_NUMBER,
            allowedMasterType: FIELD_NUMBER_TYPES,
        },
        {
            label: 'Logická hodnota',
            value: FormFieldType.FIELD_BOOLEAN,
            allowedMasterType: [FIELD_TYPE.BOOLEAN],
        },
        {
            label: 'Posuvník',
            value: FormFieldType.FIELD_SLIDER,
            allowedMasterType: FIELD_NUMBER_TYPES,
        },
        {
            label: 'Datum',
            value: FormFieldType.FIELD_DATE,
            allowedMasterType: [FIELD_TYPE.DATE_TIME],
        },
        {
            label: 'Datum interval',
            value: FormFieldType.FIELD_DATE_RANGE,
            allowedMasterType: [FIELD_TYPE.ARRAY],
        },
        {
            label: 'Našeptávač',
            value: FormFieldType.FIELD_AUTOCOMPLETE,
            allowedMasterType: RELATION_FIELD_TYPES,
        },
        {
            label: 'WYSIWYG',
            value: FormFieldType.FIELD_WYSIWYG,
            allowedMasterType: FIELD_TEXT_TYPES,
        },
        {
            label: 'Editor obrázků',
            value: FormFieldType.FIELD_IMAGE_EDITOR,
            allowedMasterType: RELATION_FIELD_TYPES,
        },
        {
            label: 'Soubor',
            value: FormFieldType.FIELD_FILE,
            allowedMasterType: RELATION_FIELD_TYPES,
        },
        {
            label: 'Výběr obsahu',
            value: FormFieldType.FIELD_CONTENT_TYPE,
            allowedMasterType: RELATION_FIELD_TYPES,
        },
        {
            label: 'Postupný výběr obsahu',
            value: FormFieldType.FIELD_CONTENT_TYPE_CASCADE,
            allowedMasterType: RELATION_FIELD_TYPES,
        },
        {
            label: 'Počet',
            value: FormFieldType.FIELD_COUNT,
            allowedMasterType: FIELD_NUMBER_TYPES,
        },
        {
            label: 'Měna (použít s datovým typem text/string)', //TODO data type PriceType.php from server is an integer, maybe use string ??
            value: FormFieldType.FIELD_CURRENCY,
            allowedMasterType: [...FIELD_TEXT_TYPES, ...FIELD_STRING_TYPES],
        },
        {
            label: 'Telefonní číslo',
            value: FormFieldType.FIELD_PHONE_NUMBER,
            allowedMasterType: [...FIELD_TEXT_TYPES, ...FIELD_STRING_TYPES],
        },
        {
            label: 'Url',
            value: FormFieldType.FIELD_URL,
            allowedMasterType: [...FIELD_TEXT_TYPES, ...FIELD_STRING_TYPES],
        },
        {
            label: 'E-mail',
            value: FormFieldType.FIELD_EMAIL,
            allowedMasterType: [...FIELD_TEXT_TYPES, ...FIELD_STRING_TYPES]
        },
        {
            label: 'IČO',
            value: FormFieldType.FIELD_ICO,
            allowedMasterType: [...FIELD_TEXT_TYPES, ...FIELD_STRING_TYPES]
        },
        {
            label: 'Číslo účtu',
            value: FormFieldType.FIELD_BANK_ACCOUNT_NUMBER,
            allowedMasterType: [...FIELD_TEXT_TYPES, ...FIELD_STRING_TYPES]
        },
        {
            label: 'Digitální podpis',
            value: FormFieldType.FIELD_SIGNATURE,
            allowedMasterType: [FIELD_TYPE.BASE64],
        },
        {
            label: 'Stav workflow',
            value: FormFieldType.FIELD_WORKFLOW_STATE,
            allowedMasterType: RELATION_FIELD_TYPES,
        },
        {
            label: 'Hodnocení',
            value: FormFieldType.FIELD_RATE,
            allowedMasterType: [FIELD_TYPE.RATE],
        },
        {
            label: 'Zaměstnanec',
            value: FormFieldType.FIELD_EMPLOYEE,
            allowedMasterType: RELATION_FIELD_TYPES,
        },
        {
            label: 'Organigram',
            value: FormFieldType.FIELD_COMPANY_STRUCTURE,
            allowedMasterType: RELATION_FIELD_TYPES
        },
        {
            label: 'Schválení',
            value: FormFieldType.COMPOSITE_FIELD_APPROVAL,
            allowedMasterType: [COMPOSITE_FIELD_TYPE.APPROVAL],
        },
        {
            label: 'Kód',
            value: FormFieldType.FIELD_CODE,
            allowedMasterType: FIELD_TEXT_TYPES,
        },
        {
            label: 'Emoji',
            value: FormFieldType.FIELD_EMOJI,
            allowedMasterType: [FIELD_TYPE.EMOJI],
        }
    ]

    static UNIT_GROUP_CUSTOM = [
        FormFieldType.FIELD_SLIDER
    ]

    static FIELD_DATE_DATE_PICKER: 'date' = 'date'
    static FIELD_DATE_DATE_TIME_PICKER: 'dateTime' = 'dateTime'
    static FIELD_DATE_YEAR_PICKER: 'year' = 'year'
    static FIELD_DATE_QUARTER_PICKER: 'quarter' = 'quarter'
    static FIELD_DATE_MONTH_PICKER: 'month' = 'month'
    static FIELD_DATE_WEEK_PICKER: 'week' = 'week'
    static FIELD_DATE_TIME_PICKER: 'time' = 'time'
    static FIELD_DATE_PICKER_TYPES = [
        {
            label: 'Datum',
            value: FormFieldType.FIELD_DATE_DATE_PICKER
        },
        {
            label: 'Datum a čas',
            value: FormFieldType.FIELD_DATE_DATE_TIME_PICKER
        },
        {
            label: 'Rok',
            value: FormFieldType.FIELD_DATE_YEAR_PICKER
        },
        {
            label: 'Kvartál',
            value: FormFieldType.FIELD_DATE_QUARTER_PICKER
        },
        {
            label: 'Měsíc',
            value: FormFieldType.FIELD_DATE_MONTH_PICKER
        },
        {
            label: 'Týden',
            value: FormFieldType.FIELD_DATE_WEEK_PICKER
        },
        {
            label: 'Čas',
            value: FormFieldType.FIELD_DATE_TIME_PICKER
        }
    ]

    static formatToForm(type: string, value: any): any {
        if (!value){
            return value
        }
        if (API_TOKEN_LIST.find(token => token.value === value)){
            return value
        }
        switch (type) {
            case(FormFieldType.FIELD_NUMBER):
            case(FormFieldType.FIELD_SLIDER):
                return Number(value)
            case(FormFieldType.FIELD_DATE):
            case(FormFieldType.FIELD_DATE_RANGE):
                if (moment.isMoment(value)){
                    return value
                }
                if (value instanceof Array){
                    return value.map(date => this.formatToForm(type, date))
                }
                if(typeof value === 'object') {
                    return moment(value.raw).isValid() ? moment(value.raw) : ''
                }
                return moment(value).isValid() ? moment(value) : ''
            default:
                return value
        }
    }

    static formatFromForm(type: string, value: any): any {
        if (!value){
            return value
        }
        switch (type) {
            case(FormFieldType.FIELD_DATE):
            case(FormFieldType.FIELD_DATE_RANGE):
                if (value instanceof Array){
                    return value.map(date => this.formatFromForm(type, date))
                }
                if (moment.isMoment(value)) {
                    return  value.format(DATE_FORMAT_YYYY_MM_DD_HH_mm_ss)
                }
                return value
            default:
                return value
        }
    }
}

export default FormFieldType