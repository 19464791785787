import RestService from "model/service/dataStorage/RestService";
import IRestServiceOptions from "../../interface/api/IRestServiceOptions";
import IRestServiceCollectionResponse from "../../interface/api/IRestServiceCollectionResponse";
import IEmployee from "../../interface/company/IEmployee";
import IRepositoryService from "../../interface/IRepositoryService";
import IPresenter from "../../interface/dataStorage/IPresenter";
import IField from "../../interface/dataStorage/IField";
import MasterRepositoryService from "../dataStorage/MasterRepositoryService";
import IRestServiceChoiceListResponse from "../../interface/api/IRestServiceChoiceListResponse";
import _ from "underscore";
import IUser from "../../interface/security/IUser";

interface IRestEmployeesServiceCollectionResponse extends IRestServiceCollectionResponse {
    results: Array<IEmployee>
}

interface IRestEmployeesService extends IRepositoryService {
    collectionList(options?: IRestServiceOptions): Promise<IRestEmployeesServiceCollectionResponse>,

    collectionCreate(data: any): Promise<IEmployee>,

    resourceRetrieve(id: number | string, options?: IRestServiceOptions): Promise<IEmployee>,

    resourceUpdate(id: number | string, data: any): Promise<IEmployee>,

    resourceDelete(id: number | string): Promise<void>,

    getCurrentEmployee(user: IUser): undefined|IEmployee

    setCurrentEmployee(uuid: string): void

}

const EmployeesService_COLLECTION = 'company/employees'
const EmployeesService_CHOICES = 'company/employees-choices/'
const COLLEAGUE_COLLECTION = '/colleagues'
const COLLEAGUE_CHOICES = '/colleagues-choices/'
const COLLEAGUE_AND_SUBORDINATES_COLLECTION = '/colleagues-and-subordinates'
const COLLEAGUE_AND_SUBORDINATES_CHOICES = '/colleagues-and-subordinates-choices/'

const CURRENT_EMPLOYEE = '_current_employee'

export type EmployeePresenterType =
    'default'
    | 'nameWithPersonalNumber'
    | 'personalNumber'
    | 'nameWithPersonalNumberAndTerminationDate'
    | 'surnameFirstWithPersonalNumber'
    | 'surnameFirstWithPersonalNumberAndTerminationDate'
    | 'nameWithDegrees'

const presenters = [
    {
        type: 'label',
        name: 'default',
        label: 'Výchozí',
        options: {
            label: "#fullName"
        }
    } as IPresenter,
    {
        type: 'label',
        name: 'nameWithPersonalNumber',
        label: 'Jméno s OČ',
        options: {
            label: "#fullName (#personalNumber)"
        }
    } as IPresenter,
    {
        type: 'label',
        name: 'nameWithUsername',
        label: 'Jméno s username',
        options: {
            label: "#name #surname (#user|username)"
        }
    } as IPresenter,
    {
        type: 'label',
        name: 'personalNumber',
        label: 'OČ',
        options: {
            label: "#personalNumber"
        }
    } as IPresenter,
    {
        type: 'label',
        name: 'nameWithPersonalNumberAndTerminationDate',
        label: 'Jméno s OČ a datumem odchodu',
        options: {
            label: "#fullName (#personalNumber)[odchod #terminationDate]"
        }
    } as IPresenter,
    {
        type: 'label',
        name: 'surnameFirst',
        label: 'Příjmení a jméno',
        options: {
            label: "#surname #name"
        }
    } as IPresenter,
    {
        type: 'label',
        name: 'surnameFirstWithPersonalNumber',
        label: 'Příjmení a jméno s OČ',
        options: {
            label: "#surname #name (#personalNumber)"
        }
    } as IPresenter,
    {
        type: 'label',
        name: 'surnameFirstWithPersonalNumberAndTerminationDate',
        label: 'Příjmení a jméno s OČ a datumem odchodu',
        options: {
            label: "#surname #name (#personalNumber)[odchod #terminationDate]"
        }
    } as IPresenter,
    {
        type: 'label',
        name: 'nameWithDegrees',
        label: 'Jméno s tituly',
        options: {
            label: "#degreeBefore #surname #name $degreeAfter"
        }
    } as IPresenter
]

class EmployeesService extends MasterRepositoryService implements IRestEmployeesService {
    private static instance?: EmployeesService

    public static getInstance(): EmployeesService {
        if (!EmployeesService.instance) {
            EmployeesService.instance = new EmployeesService()
        }
        return EmployeesService.instance
    }

    getBaseFields(): IField[] {
        // TODO
        return [
            {
                uuid: '',
                name: 'name',
                label: 'Jméno',
                mode: "scalar",
                type: 'string',
                targetEntity: null,
                options: [],
                weight: 1,
                contentTypeId: null,
                contentTypeName: false,
                locked: true,
                arguments: {}
            },
            {
                uuid: '',
                name: 'surname',
                label: 'Příjmení',
                mode: "scalar",
                type: 'string',
                targetEntity: null,
                options: [],
                weight: 1,
                contentTypeId: null,
                contentTypeName: false,
                locked: true,
                arguments: {}
            },
            {
                uuid: '',
                name: 'degreeBefore',
                label: 'Titul před',
                mode: "scalar",
                type: 'string',
                targetEntity: null,
                options: [],
                weight: 1,
                contentTypeId: null,
                contentTypeName: false,
                locked: true,
                arguments: {}
            },
            {
                uuid: '',
                name: 'degreeAfter',
                label: 'Titul za',
                mode: "scalar",
                type: 'string',
                targetEntity: null,
                options: [],
                weight: 1,
                contentTypeId: null,
                contentTypeName: false,
                locked: true,
                arguments: {}
            },
            {
                uuid: '',
                name: 'personalNumber',
                label: 'Osobní číslo',
                mode: "scalar",
                type: 'string',
                targetEntity: null,
                options: [],
                weight: 1,
                contentTypeId: null,
                contentTypeName: false,
                locked: true,
                arguments: {}
            },
            {
                uuid: '',
                name: 'beginDate',
                label: 'Datum nástupu',
                mode: "scalar",
                type: 'date',
                targetEntity: null,
                options: [],
                weight: 1,
                contentTypeId: null,
                contentTypeName: false,
                locked: true,
                arguments: {}
            },
            {
                uuid: '',
                name: 'terminationDate',
                label: 'Datum odchodu',
                mode: "scalar",
                type: 'date',
                targetEntity: null,
                options: [],
                weight: 1,
                contentTypeId: null,
                contentTypeName: false,
                locked: true,
                arguments: {}
            },
            {
                uuid: '',
                name: 'user',
                label: 'Uživatel',
                mode: "relation",
                type: 'many_to_one',
                targetEntity: 'App\\Security\\Entity\\User',
                options: [],
                weight: 1,
                contentTypeId: null,
                contentTypeName: false,
                locked: true,
                arguments: {}
            },

        ];
    }

    getRecordClassName() {
        return 'App\\Company\\Entity\\Employee'
    }

    getMasterClassName() {
        return this.getRecordClassName()
    }

    getTitle(): string {
        return "Zaměstnanec";
    }

    collectionList(options?: IRestServiceOptions): Promise<IRestEmployeesServiceCollectionResponse> {
        return RestService.collectionList(EmployeesService_COLLECTION, options as unknown as IRestServiceOptions) as Promise<IRestEmployeesServiceCollectionResponse>
    }

    collectionCreate(data: any) {
        return RestService.collectionCreate(EmployeesService_COLLECTION, data) as Promise<IEmployee>
    }

    resourceRetrieve(id: number | string, options?: IRestServiceOptions) {
        return RestService.resourceRetrieve(EmployeesService_COLLECTION, id, options) as Promise<IEmployee>
    }

    resourceDelete(id: number | string) {
        return RestService.resourceDelete(EmployeesService_COLLECTION, id)
    }

    resourceUpdate(id: number | string, data: any) {
        return RestService.resourceUpdate(EmployeesService_COLLECTION, id, data) as Promise<IEmployee>
    }

    getStringValue(employee: IEmployee): string {
        return employee.fullName ?? employee.surname
    }

    getPresenter(name: string): IPresenter | null {
        return _.findWhere(presenters, {name}) || null
    }

    getDefaultPresenter(): IPresenter {
        return presenters[0]
    }

    getPresenterList() {
        return presenters.map(presenter => {
            return {value: presenter.name, label: presenter.label}
        })
    }

    choiceList(presenterName: EmployeePresenterType | string = 'default', options?: IRestServiceOptions): Promise<IRestServiceChoiceListResponse> {
        return RestService.collectionList(EmployeesService_CHOICES + presenterName, options || {
            filters: {
                active: { //TODO should it be here ?? dont know
                    type: 'equal', // can't use API_FILTER_TYPE.EQUAL / bidirectional assoc
                    field: 'active',
                    value: true
                }
            }
        }) as unknown as Promise<IRestServiceChoiceListResponse>
    }

    subordinatesList(employee: IEmployee, options?: IRestServiceOptions, includeCurrentEmployee?: boolean): Promise<IRestEmployeesServiceCollectionResponse> {
        return RestService.collectionList(EmployeesService_COLLECTION + '/' + employee.uuid + '/subordinates' + (includeCurrentEmployee ? '?me=1' : ''), options as unknown as IRestServiceOptions) as Promise<IRestEmployeesServiceCollectionResponse>
    }

    subordinatesChoiceList(presenterName: string, employee: IEmployee, options?: IRestServiceOptions, includeCurrentEmployee?: boolean): Promise<IRestServiceChoiceListResponse> {
        return RestService.collectionList(EmployeesService_COLLECTION + '/' + employee.uuid + '/subordinates-choices/' + presenterName + (includeCurrentEmployee ? '?me=1' : ''), options as unknown as IRestServiceOptions) as unknown as Promise<IRestServiceChoiceListResponse>
    }

    workloadPlanSubordinatesChoiceList(presenterName: string, employee: IEmployee, options?: IRestServiceOptions, includeCurrentEmployee?: boolean): Promise<IRestServiceChoiceListResponse> {
        return RestService.collectionList(EmployeesService_COLLECTION + '/' + employee.uuid + '/workload-plan-subordinates-choices/' + presenterName + (includeCurrentEmployee ? '?me=1' : ''), options as unknown as IRestServiceOptions) as unknown as Promise<IRestServiceChoiceListResponse>
    }

    workloadReportSubordinatesChoiceList(presenterName: string, employee: IEmployee, options?: IRestServiceOptions, includeCurrentEmployee?: boolean): Promise<IRestServiceChoiceListResponse> {
        return RestService.collectionList(EmployeesService_COLLECTION + '/' + employee.uuid + '/workload-report-subordinates-choices/' + presenterName + (includeCurrentEmployee ? '?me=1' : ''), options as unknown as IRestServiceOptions) as unknown as Promise<IRestServiceChoiceListResponse>
    }

    colleaguesList(employee: string | number, options?: IRestServiceOptions, includeCurrentEmployee?: boolean): Promise<IRestEmployeesServiceCollectionResponse> {
        return RestService.collectionList(EmployeesService_COLLECTION + '/' + employee + COLLEAGUE_COLLECTION + (includeCurrentEmployee ? '?me=1' : ''), options as unknown as IRestServiceOptions) as Promise<IRestEmployeesServiceCollectionResponse>
    }

    colleaguesChoiceList(presenterName: EmployeePresenterType, employee: string | number, options?: IRestServiceOptions, includeCurrentEmployee?: boolean): Promise<IRestServiceChoiceListResponse> {
        return RestService.collectionList(EmployeesService_COLLECTION + '/' + employee + COLLEAGUE_CHOICES + presenterName +  (includeCurrentEmployee ? '?me=1' : ''), options as unknown as IRestServiceOptions) as unknown as Promise<IRestServiceChoiceListResponse>
    }

    colleaguesAndSubordinatesList(employee: string | number, options?: IRestServiceOptions, includeCurrentEmployee?: boolean): Promise<IRestEmployeesServiceCollectionResponse> {
        return RestService.collectionList(EmployeesService_COLLECTION + '/' + employee + COLLEAGUE_AND_SUBORDINATES_COLLECTION + (includeCurrentEmployee ? '?me=1' : ''), options as unknown as IRestServiceOptions) as Promise<IRestEmployeesServiceCollectionResponse>
    }

    colleaguesAndSubordinatesChoiceList(presenterName: EmployeePresenterType, employee: string | number, options?: IRestServiceOptions, includeCurrentEmployee?: boolean): Promise<IRestServiceChoiceListResponse> {
        return RestService.collectionList(EmployeesService_COLLECTION + '/' + employee + COLLEAGUE_AND_SUBORDINATES_CHOICES + presenterName + (includeCurrentEmployee ? '?me=1' : ''), options as unknown as IRestServiceOptions) as unknown as Promise<IRestServiceChoiceListResponse>
    }

    getCurrentEmployee(user: IUser): undefined|IEmployee {
        const employees = user.employees
        const local = localStorage.getItem(CURRENT_EMPLOYEE) || user.currentEmployee
        return local ? (employees.find(e => e.uuid === local) || employees[0]) : employees[0]
    }

    setCurrentEmployee(uuid: string) {
        localStorage.setItem(CURRENT_EMPLOYEE, uuid)
    }
}

export default EmployeesService