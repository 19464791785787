import RestService from "model/service/dataStorage/RestService";
import IRestServiceOptions from "../../interface/api/IRestServiceOptions";
import IRestServiceCollectionResponse from "../../interface/api/IRestServiceCollectionResponse";
import IUnit from "../../interface/company/IUnit";
import IRepositoryService from "../../interface/IRepositoryService";
import IField from "../../interface/dataStorage/IField";
import IPresenter from "../../interface/dataStorage/IPresenter";
import IRestServiceChoiceListResponse from "../../interface/api/IRestServiceChoiceListResponse";

interface IRestUnitsServiceCollectionResponse extends IRestServiceCollectionResponse {
    results: Array<IUnit>
}

interface IRestUnitsService extends IRepositoryService {
    collectionList(options?: IRestServiceOptions): Promise<IRestUnitsServiceCollectionResponse>,

    collectionCreate(data: any): Promise<IUnit>,

    resourceRetrieve(id: number | string): Promise<IUnit>,

    resourceUpdate(id: number | string, data: any): Promise<IUnit>,

    resourceDelete(id: number | string): Promise<void>,
}

const UnitsService_COLLECTION = 'company/units'
const UnitsService_CHOICES = 'company/units-choices/'

class UnitsService implements IRestUnitsService {
    private static instance?: UnitsService

    public static getInstance(): UnitsService {
        if (!UnitsService.instance) {
            UnitsService.instance = new UnitsService()
        }
        return UnitsService.instance
    }

    getFields(): IField[] {
        return [
            {
                uuid: '',
                name: 'name',
                label: 'Název',
                mode: "scalar",
                type: 'string',
                targetEntity: null,
                options: [],
                weight: 1,
                contentTypeId: null,
                contentTypeName: false,
                locked: true,
                arguments: {}
            },
            {
                uuid: '',
                name: 'code',
                label: 'Kód',
                mode: "scalar",
                type: 'string',
                targetEntity: null,
                options: [],
                weight: 1,
                contentTypeId: null,
                contentTypeName: false,
                locked: true,
                arguments: {}
            }
        ];
    }
    getRecordClassName() {
        return 'App\\Company\\Entity\\Unit'
    }
    getTitle(): string {
        return "Jednotka";
    }
    collectionList(options?: IRestServiceOptions): Promise<IRestUnitsServiceCollectionResponse> {
        return RestService.collectionList(UnitsService_COLLECTION, options as unknown as IRestServiceOptions) as Promise<IRestUnitsServiceCollectionResponse>
    }
    collectionCreate(data: any) {
        return RestService.collectionCreate(UnitsService_COLLECTION, data) as Promise<IUnit>
    }
    resourceRetrieve(id: number | string, options?: IRestServiceOptions) {
        return RestService.resourceRetrieve(UnitsService_COLLECTION, id) as Promise<IUnit>
    }
    resourceDelete(id: number | string) {
        return RestService.resourceDelete(UnitsService_COLLECTION, id)
    }
    resourceUpdate(id: number | string, data: any) {
        return RestService.resourceUpdate(UnitsService_COLLECTION, id, data) as Promise<IUnit>
    }
    getStringValue(company: IUnit): string {
        return company.name
    }
    getPresenter(name: string): IPresenter | null {
        return this.getDefaultPresenter()
    }
    getDefaultPresenter(): IPresenter {
        return {
            type: 'label',
            name: 'default',
            label: 'Výchozí',
            options: {
                label: "#name"
            }
        } as IPresenter
    }
    getPresenterList() {
        //TODO ADD PRESENTERS ?
        return [this.getDefaultPresenter()].map(presenter => {
            return {value: presenter.name, label: presenter.label}
        })
    }
    choiceList(presenterName: string = 'default', options?: IRestServiceOptions): Promise<IRestServiceChoiceListResponse> {
        return RestService.collectionList(UnitsService_CHOICES + presenterName, options) as unknown as Promise<IRestServiceChoiceListResponse>
    }
}

export default UnitsService