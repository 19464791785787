import React from "react";
import {CloseOutlined, CopyOutlined, EditOutlined, PlusOutlined} from "@ant-design/icons";
import {Modal, Row, Table, Tooltip} from "antd";
import {IContentTypeStepProps} from "./ContentTypeConfiguration";
import {ColumnProps} from "antd/es/table";
import Utils from "../../../../utils";
import IForm from "../../../../model/interface/form/IForm";
import FormConfiguration from "../form/FormConfiguration";
import IWidget from "../../../../model/interface/form/IWidget";
import FormsService from "../../../../model/service/dataStorage/FormsService";
import Hotkey from "../../../shared/hotkey/Hotkey";
import Button from "components/shared/button/Button";
import LocaleText from "../../settings/dictionary/LocaleText";

interface IState {
    form?: IForm
}

class ContentTypeForms extends React.Component<IContentTypeStepProps, IState> {

    constructor(props: IContentTypeStepProps) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        if (this.props.resource.forms.length === 0) {
            Modal.confirm({
                title: "Není nastaven žádný formulář, chcete provést vytvoření kostry formuláře?",
                onOk: () => this.buildBasicForm()
            })
        }
    }

    buildBasicForm(): any {
        const {resource} = this.props
        const forms = resource.forms
        forms.push(FormsService.buildBasicForm(resource))
        this.props.onValuesChange({
            forms
        })
    }

    create() {
        this.setState({
            form: {
                uuid: Utils.uuid(),
                contentType: this.props.resource.uuid,
                label: '',
                name: '',
                widgets: [] as IWidget[],
            } as IForm
        })
    }

    edit = (form: IForm) => {
        this.setState({form})
    }

    update = (form: IForm, changedForms: IForm[] = []) => {
        const forms = Utils.arrayAddOrUpdateWhere([...this.props.resource.forms], {uuid: form.uuid}, form)
        this.props.onValuesChange({
            forms: ContentTypeForms.updateWithChangedForms(changedForms, forms)
        })
        this.cancel()
        return Promise.resolve()
    }

    cancel = () => {
        this.setState({form: undefined})
    }

    onRemove(form: IForm) {
        let forms = [...this.props.resource.forms].filter(value => !Utils.compareTemporaryApiObjects(form, value))
        this.props.onValuesChange({forms})
    }

    onClone(form: IForm) {
        const {resource, onValuesChange} = this.props
        Modal.confirm({
            title: 'Opravdu chcete vytvořit kopii tohoto formuláře?',
            onOk: () => {
                const clonedForm = FormsService.clone(form, Math.max(...resource.forms.map(c => c.priority || 0)))
                onValuesChange({forms: [...resource.forms, clonedForm]})
                this.edit(clonedForm)
            }
        })
    }

    static updateWithChangedForms(changedForms: IForm[], forms: IForm[]) {
        changedForms.forEach(c => {
            const i = forms.findIndex(item => item.uuid === c.uuid)
            if (i > -1) {
                forms[i] = {...forms[i], ...c}
            }
        })
        return forms
    }

    render() {
        const presenterColumns: ColumnProps<IForm>[] = [
            {
                title: 'ID',
                dataIndex: 'id'
            },
            {
                title: 'Název',
                dataIndex: 'name'
            },
            {
                title: 'Titulek',
                dataIndex: 'label',
                render:(value) => <LocaleText code={value}/>
            },
            {
                title: <Row justify={"end"}>
                    <Hotkey help={"Přidat nový formulář"} keys={["Alt", "p"]} trigger={() => this.create()}>
                        <Button type={"success"} onClick={() => this.create()} icon={<PlusOutlined/>}>
                            <u className={'pl-2'}>P</u>řidat
                        </Button>
                    </Hotkey>
                </Row>,
                key: 'actions',
                dataIndex: 'actions',
                render: (_, form) => (
                    <div className="text-right d-flex justify-content-end">
                        <Tooltip title={"Upravit"}>
                            <Button onClick={() => this.edit(form)} type="link" className="mr-2"
                                    icon={<EditOutlined/>} size="small"/>
                        </Tooltip>
                        <Tooltip title={"Vytvořit kopii"}>
                            <Button onClick={() => this.onClone(form)} type="link" className="mr-2"
                                    icon={<CopyOutlined/>} size="small"/>
                        </Tooltip>
                        <Tooltip title={"Odstranit"}>
                            <Button onClick={() => this.onRemove(form)} danger type="link" className="mr-2"
                                    icon={<CloseOutlined/>} size="small"/>
                        </Tooltip>
                    </div>
                )
            }
        ]
        const {resource, history, match} = this.props
        const {form} = this.state
        const {forms} = resource
        return (
            <>
                <Table
                    pagination={{pageSize: 10, showSizeChanger: true}}
                    columns={presenterColumns}
                    dataSource={[...forms]}
                    rowKey={row => row.uuid + ''}
                />
                {form && <FormConfiguration contentType={resource} history={history} match={match} update={this.update}
                                            form={form} cancel={() => this.cancel()}/>}
            </>
        )
    }
}

export default ContentTypeForms