import React from "react";
import IAction from "../../../../../model/interface/dataStorage/IAction";
import IField, {
    FIELD_MODE_COMPUTED,
    FIELD_MODE_ICONS,
    RELATION_FIELD_TYPE
} from "../../../../../model/interface/dataStorage/IField";
import FormElementList from "./FormElementList";
import FormElementType from "./FormElementType";
import {TagOutlined, ThunderboltOutlined} from "@ant-design/icons";
import IContentType from "../../../../../model/interface/dataStorage/IContentType";
import IExtension from "../../../../../model/interface/dataStorage/IExtension";
import WidgetGallery, {GroupItem} from "../../widget/WidgetGallery";
import Utils from "../../../../../utils";
import {IAppState} from "../../../../../redux/store";
import selectors from "../../../../../redux/selectors";
import {connect} from "react-redux";

interface IProps {
    contentType: IContentType
    exists: (group: string, id: number, type?: string) => boolean
    getText: (code: string, fallBack?: string) => string
}

class FormElementGallery extends WidgetGallery<IProps> {

    getGroup(group: [string, GroupItem]) {
        const {contentType} = this.props
        return {
            [FormElementType.GROUP_FIELD]:
                this.getFieldOptions(contentType.fields, group, contentType.extensions),
            [FormElementType.GROUP_ACTION]:
                this.getActionOptions(contentType.actions, group),
        }[group[0]] || group[1].widgets.map((widget, index) =>
            this.getNodeOptionTag(widget, index)
        )
    }

    getActionOptions(actions: IAction[], group: [string, GroupItem]) {
        const {exists} = this.props

        return actions.map((action, index) => {
            return this.getNodeOptionTag({
                    type: FormElementType.ACTION,
                    label: this.props.getText(action.label || '[' + action.name + ']'),
                    group: FormElementType.GROUP_ACTION,
                    icon: <ThunderboltOutlined/>,
                }, index,
                () => this.onSelectItem(FormElementType.ACTION, action),
                exists(group[0], action.id as number)
            )
        });
    }

    onSelectItem(type: string, action?: IAction, field?: IField) {
        super.onSelectItem(type, action, field);
    }

    getFieldOptions(fields: IField[], group: [string, GroupItem], extensions: IExtension[]) {
        const {exists, getText} = this.props
        return [
            ...fields
                .filter(field => field.mode !== FIELD_MODE_COMPUTED)
                .map((field, index) => ({
                    widget: {
                        type: FormElementType.FIELD,
                        label: getText(field.label || field.name),
                        group: FormElementType.GROUP_FIELD,
                        icon: field.mode ? FIELD_MODE_ICONS[field.mode] : <TagOutlined/>
                    },
                    index,
                    onSelect: () => this.onSelectItem(FormElementType.FIELD, undefined, field),
                })),
            ...extensions.flatMap((extension, index) => {
                switch (extension.type) {
                    case 'App\\DataStorage\\Extension\\Comments':
                        const widget = FormElementList.getItemByType(FormElementType.COMMENT)
                        return {
                            widget,
                            index: index + fields.length,
                            onSelect: () => this.onSelectItem(FormElementType.COMMENT),
                            disabled: exists(group[0], -1, widget.type)
                        }
                    default:
                        return []
                }
            }),
            ...this.getEntityTableOption(fields).map((widget, index) => ({widget, index}))
        ]
            .sort((a, b) => a.widget.label > b.widget.label ? 1 : -1)
            .map(item => this.getNodeOptionTag(item.widget, item.index, 'onSelect' in item ? item.onSelect : undefined, 'disabled' in item ? item['disabled'] : undefined));
    }

    getEntityTableOption(fields: IField[]) {
        const field = fields.find(field => Utils.isTargetEntityContentType(field.targetEntity) && [
                RELATION_FIELD_TYPE.ONE_TO_MANY,
                RELATION_FIELD_TYPE.MANY_TO_MANY
            ].includes(field.type)
        )
        return field ? [FormElementList.getItemByType(FormElementType.ENTITY_TABLE)] : []
    }
}

const mapStateToProps = (state: IAppState) => {
    return {
        getText: (code: string, fallback?: string) => selectors.dictionary.getMessage(state, code, fallback)
    }
}

export default connect(mapStateToProps,)(FormElementGallery)