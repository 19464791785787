import IFund from "./workload/IFund";
import IShift from "./IShift";
import IDateTime from "../api/IDateTime";

export const OBLIGATION_MODE = {
    EVERY_DAY: 'every_day',
    WORK_DAY: 'work_day',
    WEEK: 'week',
    EVEN_ODD_WEEK: 'even_odd_week',
    INDIVIDUAL: 'individual'
}

export const OBLIGATION_MODES = [
    {value: OBLIGATION_MODE.WORK_DAY, label: 'Pracovní dny'},
    {value: OBLIGATION_MODE.EVERY_DAY, label: 'Každý den'},
    {value: OBLIGATION_MODE.WEEK, label: 'Týden'},
    {value: OBLIGATION_MODE.EVEN_ODD_WEEK, label: '14 dní'},
    {value: OBLIGATION_MODE.INDIVIDUAL, label: 'Individuální'}
]

export const OBLIGATION_MAX_HOURS_INTERVAL = {
    DAY: 'day',
    WEEK: 'week',
    MONTH: 'month',
    YEAR: 'year'
}

export const OBLIGATION_MAX_HOURS_INTERVALS = [
    {value: OBLIGATION_MAX_HOURS_INTERVAL.DAY, label: 'Den'},
    {value: OBLIGATION_MAX_HOURS_INTERVAL.WEEK, label: 'Týden'},
    {value: OBLIGATION_MAX_HOURS_INTERVAL.MONTH, label: 'Měsíc'},
    {value: OBLIGATION_MAX_HOURS_INTERVAL.YEAR, label: 'Rok'}
]

export default interface IObligation {
    id: number,
    uuid: string,
    title: string,
    mode: string
    short?: string,
    funds: IFund[],
    shifts: IShift[],
    employeesCount: number,
    dayWorkHours?: number
    firstShiftStartAt?: IDateTime
    allowedDays?: { [day: string]: boolean }
    maxHours?: { [interval: string]: number }
    breakInterval?: number
    breakDuration?: number
    breakType?: string
    breakExtendsTime?: boolean
    workloadTypes?: string[],
    forbiddenWorkloadTypes?: string[],
    attendanceEntryPreset?: boolean
}