import React from "react";
import IActionOptions from "../../../model/interface/widget/option/IActionOptions";
import IAction from "../../../model/interface/dataStorage/IAction";
import {connect, RootStateOrAny} from "react-redux";
import selectors from "../../../redux/selectors";
import IWorkflow from "../../../model/interface/dataStorage/workflow/IWorkflow";
import IContentType from "../../../model/interface/dataStorage/IContentType";
import IRestResource from "../../../model/interface/api/IRestResource";
import IconBuilder from "../../../utils/IconBuilder";
import Button from "../../shared/button/Button";
import {Col, Input, List, Popover, Row, Tooltip} from "antd";
import IWorkflowState from "../../../model/interface/dataStorage/workflow/IState";
import ITransition from "../../../model/interface/dataStorage/workflow/ITransition";
import {ExclamationOutlined, StopOutlined} from "@ant-design/icons";
import IRepositoryService from "../../../model/interface/IRepositoryService";
import PresenterBuilder from "../../../views/dataStorage/PresenterBuilder";
import IBaseProps from "../../../model/interface/IBaseProps";
import WorkflowsService from "../../../model/service/dataStorage/WorkflowsService";
import LocaleText from "../settings/dictionary/LocaleText";


interface IProps extends IBaseProps {
    onExecute: (payload: any, action: IAction, resources: IRestResource[]) => Promise<void>,
    action: IAction
    options: IActionOptions
    workflows: IWorkflow[]
    findWorkflowByContentType: (contentType: IContentType) => IWorkflow
    findContentType: (uuid: string) => IContentType
    findServiceByContentType: (contentType: IContentType) => IRepositoryService
    resources?: IRestResource[]
}

interface IState {
    loading: string[]
}

class WorkflowActionButton extends React.Component<IProps, IState> {

    constructor(props: IProps, context: any) {
        super(props, context);
        this.state = {
            loading: []
        }
    }

    render() {
        const {
            action,
            resources,
            findServiceByContentType,
            findWorkflowByContentType,
            findContentType
        } = this.props
        const {loading} = this.state
        const contentType = findContentType(action.contentType)
        const workflow = findWorkflowByContentType(contentType)
        const service = findServiceByContentType(contentType)
        if (!workflow) {
            return (<>Workflow nenalezeno!</>);
        }
        if (!resources || resources.length === 0) {
            return (<>Žádná resource</>);
        }

        if (resources.filter(r => r.state).length !== resources.length) {
            return (<>Žádný stav</>);
        }
        const version = workflow.activeVersion

        if (!version) {
            return (<>Žádna verze</>);
        }
        let groups: { [id: number]: { transitions: (ITransition & { state?: IWorkflowState })[], total: number, resources: IRestResource[] } } = {}
        resources.forEach(r => {
            groups[r.state.id] = {
                transitions: WorkflowsService.getOutgoingTransitions(version, r, contentType),
                total: (groups[r.state.id]?.total || 0) + 1,
                resources: [...(groups[r.state.id] ? groups[r.state.id].resources : []), r]
            }
        })

        return (
            <>
                {Object.entries(groups).map(([id, group]) => (
                        <Row gutter={[6, 6]} key={id}>
                        {group.transitions.map(transition => {
                            const totalFailedConditions = group.resources?.reduce((prev, curr) => prev + (curr._failedWorkflowTransitionConditions?.filter(c => c.transition === transition.uuid).length ? 1 : 0), 0)
                            const button = <Button
                                loading={!!transition.state?.uuid && loading.includes(transition.state?.uuid)}
                                type={transition.buttonType}
                                size={"small"}
                                disabled={totalFailedConditions === group.resources?.length}
                                icon={transition.buttonIcon ? IconBuilder(transition.buttonIcon) : null}
                                onClick={() => this.run(transition, group.resources)}>
                                <LocaleText code={transition.title || transition.state?.name || ''}/> {Object.keys(groups).length > 1 && `(${group.total})`}
                            </Button>
                            return (
                                <Col key={transition.id}>
                                    {/*TODO maybe add tooltip/popover with list resources ??*/}
                                    <Tooltip title={transition.buttonTooltip}>
                                        {totalFailedConditions > 0 ?
                                            <Input.Group compact className={'d-inline-block'}>
                                                {button}
                                                <Popover title={'Nesplněné podmínky'} content={
                                                    <div>
                                                        <List
                                                            size={'small'}
                                                            itemLayout="horizontal">
                                                            {group.resources
                                                                .map(resource => {
                                                                    const conditions = resource._failedWorkflowTransitionConditions?.filter(c => c.transition === transition.uuid)
                                                                    return (
                                                                        <List.Item className={'p-0'}>
                                                                            <List
                                                                                header={group.resources?.length > 1 ?
                                                                                    <b className={'pl-2'}>
                                                                                        {PresenterBuilder.build(service.getDefaultPresenter(), resource, service.getDefaultPresenter().options)}
                                                                                    </b> : false}
                                                                                size={'small'}
                                                                                itemLayout="horizontal">
                                                                                {conditions?.map(condition => (
                                                                                    <List.Item>
                                                                                        <div
                                                                                            className={'pr-2 d-inline-block'}>
                                                                                            {IconBuilder(condition.icon || '',
                                                                                                <ExclamationOutlined/>)}
                                                                                        </div>
                                                                                        {condition.failText ?? 'Nejsou splněny požadované podmínky!'}
                                                                                    </List.Item>
                                                                                ))}
                                                                            </List>
                                                                        </List.Item>
                                                                    )
                                                                })
                                                            }
                                                        </List>
                                                    </div>
                                                }>
                                                    <Button icon={<StopOutlined/>} size={"small"} danger/>
                                                </Popover>
                                            </Input.Group> : button}
                                    </Tooltip>
                                </Col>
                            )
                        })}
                    </Row>
                    )
                )}
            </>
        )
    }

    run(transition: ITransition & {
        state?: IWorkflowState
    }, resources: IRestResource[]) {
        const {onExecute, action} = this.props
        const contentType = this.props.findContentType(action.contentType)
        const executeAction = transition.action ? contentType.actions.find(action => action.uuid === transition.action)! : action
        const uuid = transition.state?.uuid
        const loading = [...this.state.loading]
        if (uuid) {
            this.setState(state => ({loading: [...state.loading, uuid]}))
            return onExecute({
                    transition: transition.uuid,
                    state: transition.state?.uuid,
                },
                executeAction,
                resources
            ).then(() => {
                this.setState({loading: [...loading.slice(loading.indexOf(uuid), 1)]})
            }).catch(() => {
                this.setState({loading: [...loading.slice(loading.indexOf(uuid), 1)]})
            })
        }
    }
}


const mapStateToProps = (state: RootStateOrAny) => {
    const {workflows} = state.setup
    return {
        workflows,
        findWorkflowByContentType: (contentType: IContentType) => selectors.workflow.findOneByContentType(state, contentType),
        findContentType: (uuid: string) => selectors.contentTypes.findOneBy(state, 'uuid', uuid),
        findServiceByContentType: (contentType: IContentType) => selectors.services.findOneByContentType(state, contentType)
    }
}

export default connect(mapStateToProps)(WorkflowActionButton);