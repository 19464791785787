import React, {Component, RefObject} from 'react';
import {FormInstance, Input, Row, Select} from "antd";
import Button, {ButtonTypes} from "./Button";
import {EditOutlined, PlusOutlined} from "@ant-design/icons";
import IButtonType from "../../../model/interface/ui/IButtonType";
import ButtonTypeForm from "./ButtonTypeForm";
import {IAppState} from "../../../redux/store";
import {connect} from "react-redux";

interface IProps {
    value?: string
    onChange?: (key: string) => void
    className?: string
    style?: React.CSSProperties,
    disabled?: boolean
    canAdd?: boolean,
    canEdit?: boolean
    buttonTypes: IButtonType[]
}

interface IState {
    showModal?: string | true
}

const BUTTON_TYPES: { value: ButtonTypes, label: string }[] = [
    {value: 'default', label: 'Výchozí'},
    {value: 'primary', label: 'Zvýrazněné'},
    {value: 'link', label: 'Odkaz'},
    {value: 'info', label: 'Info'},
    {value: 'success', label: 'Úspěch'},
    {value: 'warning', label: 'Varování'},
    {value: 'danger', label: 'Kritické'},
    {value: 'special', label: 'Speciální'},
    {value: 'dashed', label: 'Čárkovaný'}
]

class ButtonTypePicker extends Component<IProps, IState> {

    formRef: RefObject<FormInstance> = React.createRef()

    constructor(props: IProps) {
        super(props);
        this.state = {}
    }

    static defaultProps = {
        canAdd: true,
        canEdit: true
    }

    onPickerChoose = (route: string) => {
        if (this.props.onChange) {
            this.props.onChange(route)
        }
    }

    onModalChange = (buttonType?: IButtonType) => {
        if (buttonType) {
            if (this.props.onChange && buttonType.uuid) {
                this.props.onChange(buttonType.uuid)
            }
        }
        this.setState({showModal: undefined})
    }

    showModal = (background?: string | true) => {
        this.setState({showModal: background})
    }

    render() {
        const {showModal} = this.state
        const {className, style, disabled, canAdd, value, canEdit, buttonTypes} = this.props

        return (
            <div className="route-picker" style={style}>
                {showModal && (
                    <ButtonTypeForm buttonType={buttonTypes?.find(r => r.uuid === showModal)}
                                    onChange={this.onModalChange} modal={true}/>
                )}
                <Input.Group compact className={'d-flex w-100'}>
                    <Select
                        allowClear
                        className={className + ' flex-grow-1 min-w-0'}
                        showSearch
                        disabled={disabled || !buttonTypes}
                        value={!buttonTypes ? undefined : value}
                        loading={!buttonTypes}
                        placeholder="Vyberte typ"
                        optionFilterProp="children"
                        onChange={this.onPickerChoose}
                        filterOption={(input, option) =>
                            option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {BUTTON_TYPES.map(type => (
                            <Select.Option key={type.value} value={type.value}>
                                <Row justify={"space-between"} align={"middle"}>
                                    {type.label} <Button
                                    type={type.value}
                                    size={"small"}>Ukazka</Button>
                                </Row>
                            </Select.Option>
                        ))}
                        <Select.OptGroup label={'Vlastní'}>
                            {buttonTypes?.map(b => (
                                    <Select.Option key={b.uuid} value={b.uuid}>
                                        <Row justify={"space-between"} align={"middle"}>
                                            {b.label} <Button
                                            color={b.color}
                                            shape={b.shape}
                                            style={{background: b.colorDark, color: b.textColorDark}}
                                            size={"small"}>Ukazka</Button>
                                        </Row>
                                    </Select.Option>
                                )
                            )}
                        </Select.OptGroup>
                    </Select>
                    {canAdd && !disabled &&
                        <Button className={'flex-shrink-0'} onClick={() => this.showModal(true)}
                                icon={<PlusOutlined/>}/>}
                    {canEdit && !disabled && buttonTypes.some(t => t.uuid === value) &&
                        <Button className={'flex-shrink-0'} onClick={() => this.showModal(value)}
                                icon={<EditOutlined/>}/>}
                </Input.Group>
            </div>
        )
    }
}

const mapStateToProps = (state: IAppState) => {
    return {
        buttonTypes: state.setup.buttonTypes,
    }
}

export default connect(mapStateToProps)(ButtonTypePicker)
