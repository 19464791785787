import { all, call, fork, put, take, takeEvery, cancel, cancelled } from 'redux-saga/effects';
import {
    NOTIFICATIONS_CHANGE_STATE,
    NOTIFICATIONS_LOAD,
    NOTIFICATIONS_RELOAD,
    NOTIFICATIONS_SSE_ERROR
} from '../constants/Notifications';
import { load, save, saveOne } from "../actions/Notifications";
import { eventChannel, END, EventChannel } from 'redux-saga';
import MyNotificationsService from 'model/service/ui/MyNotificationsService';
import IRestServiceCollectionResponse from "../../model/interface/api/IRestServiceCollectionResponse";
import INotification, { STATES } from "../../model/interface/ui/INotification";
import { env } from "../../configs/EnvironmentConfig";

let currentEventChannel: EventChannel<IRestServiceCollectionResponse> | null = null;
let intentionalClose: boolean = false;

function createEventChannel(token: string): EventChannel<IRestServiceCollectionResponse> {
    return eventChannel<IRestServiceCollectionResponse>((emitter) => {
        const eventSource = new EventSource(env.API_ENDPOINT_URL + '/my-notifications-sse?token=' + token);

        eventSource.onmessage = (event) => {
            try {
                const data = JSON.parse(event.data);
                emitter(data);
            } catch (error) {
                console.error('Error parsing event data:', error);
            }
        };

        eventSource.onerror = (error) => {
            console.error('EventSource error:', error);
            emitter(END); // Signal the channel to close
        };

        const unsubscribe = () => {
            eventSource.close();
        };

        return unsubscribe;
    });
}

function* handleEventSource(token: string) {
    // @ts-ignore
    const channel = yield call(createEventChannel, token);
    currentEventChannel = channel; // Store the current channel

    try {
        while (true) {
            const data: IRestServiceCollectionResponse<INotification> = yield take(channel);
            yield put(save(data)); // Dispatch the save action
        }
    } catch (error) {
        console.error('handleEventSource error:', error);
    } finally {
        channel.close();
        currentEventChannel = null; // Clear the channel
        yield put({ type: NOTIFICATIONS_SSE_ERROR }); // Dispatch error action
    }
}

export function* notificationsLoad() {
    try {
        if (currentEventChannel) {
            intentionalClose = true; // Set the flag before closing
            currentEventChannel.close();
            currentEventChannel = null;
        }

        const response: IRestServiceCollectionResponse = yield call(MyNotificationsService.collectionList, { limit: 100, cache: false });
        const token = response['sse_token'];
        if (token) {
            yield fork(handleEventSource, token); // Fork the event source handling
        }
        yield put(save(response));
    } catch (error) {
        console.error('notificationsLoad error:', error);
    }
}

export function* watchNotificationsSSEError() {
    yield takeEvery(NOTIFICATIONS_SSE_ERROR, function* () {
        if (!intentionalClose) {
            yield call(notificationsLoad);
        }
    });
}

export function* watchNotificationsLoad() {
    yield takeEvery(NOTIFICATIONS_LOAD, notificationsLoad);
}

export function* notificationsReload() {
    yield takeEvery(NOTIFICATIONS_RELOAD, function* () {
        yield put(load());
    });
}

export function* notificationsChangeState() {
    yield takeEvery(NOTIFICATIONS_CHANGE_STATE, function* ({ notification, state }: any) {
        yield put(saveOne({ ...notification, state: STATES.STATE_LOADING }));
        yield call(MyNotificationsService.resourceUpdate, notification.id, { state });
        yield put(saveOne({ ...notification, state }));
    });
}

export default function* rootSaga() {
    yield all([
        fork(watchNotificationsLoad),
        fork(watchNotificationsSSEError),
        fork(notificationsReload),
        fork(notificationsChangeState)
    ]);
}