import React from "react"
import {Card, Col, Row, Spin, Steps} from "antd";
import {Prompt} from "react-router-dom";
import IBaseProps from "../../../../model/interface/IBaseProps";
import SourcesService from "../../../../model/service/import/SourcesService";
import ISource from "../../../../model/interface/import/ISource";
import SourceFile from "./SourceFile";
import SourcePreview from "./SourcePreview";
import SourceExecution from "../source/SourceExecution";
import {connect, RootStateOrAny} from "react-redux";
import selectors from "../../../../redux/selectors";
import IRepositoryService from "../../../../model/interface/IRepositoryService";
import IAction from "../../../../model/interface/dataStorage/IAction";

export interface IProps extends IBaseProps {
    parentSource: string
    findServiceByClassName: (className: string) => IRepositoryService
    standAlone: boolean,
    action?: IAction
    onFinish?: () => void
}

export interface IStateSourceStep {
    resource: ISource
}

interface IState extends IStateSourceStep {
    loading: boolean,
    shouldBlockNavigation: boolean,
    step: number,
    parentSource?: ISource
}

export interface ISourceImportStepProps {
    setStep: (step: number) => void,
    updateResource: (resource: ISource | any, server?: boolean) => Promise<void>,
    resource: ISource,
    parentSource: ISource
}

class SourceImport extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            step: 0,
            loading: false,
            shouldBlockNavigation: false,
            resource: this.newSource()
        }
    }

    static defaultProps = {
        standAlone: true
    }

    newSource() {
        return {
            name: '',
            active: false,
            parent: this.props.parentSource
        } as ISource
    }

    componentDidMount() {
        this.load()
    }

    load() {
        this.setState({loading: true})
        SourcesService.resourceRetrieve(this.props.parentSource).then(source => {
            this.setState(state => ({
                parentSource: source,
                resource: {
                    ...state.resource,
                    name: source.name + '-' + (new Date()).toISOString(),
                    parent: source.uuid
                },
                loading: false
            }))
        })
    }

    updateResource = (resource: ISource, server = false) => {
        return new Promise<void>((resolve, reject) => {
            this.setState(state => ({resource: {...state.resource, ...resource}}),
                () => server && this.save().catch(() => reject)
                    .then(() => resolve())
                    .catch(() => reject))
        })
    }

    save = () => {
        let promises: Promise<void>[] = []
        const resource = this.state.resource
        const data = {
            ...resource
        }
        promises.push(SourcesService.collectionCreate(data).then(resource => {
            this.setState({resource})
        }))

        return Promise.all(promises)
    }

    componentDidUpdate = () => {
        if (this.state.shouldBlockNavigation) {
            window.onbeforeunload = () => true
        } else {
            window.onbeforeunload = null
        }
    }

    setStep(step: number): void {
        this.setState(state => ({
            step: state.step + step
        }))
    }

    reset = () => {
        this.setState({
            step: 0, resource: this.newSource()
        })
    }

    getTitle(parentSource: ISource | undefined, findServiceByClassName: (className: string) => IRepositoryService) {
        return parentSource && 'Import [' + findServiceByClassName(parentSource.type).getTitle() + ']';
    }

    render() {
        const {shouldBlockNavigation, loading, step, resource, parentSource} = this.state
        const {match, history, findServiceByClassName, standAlone, action, onFinish} = this.props

        const content = (
            <Row gutter={[16, 16]}>
                <Col sm={24}>
                    <Steps current={step} direction={"horizontal"}>
                        <Steps.Step title="Soubor"/>
                        <Steps.Step title="Náhled dat"/>
                        <Steps.Step title="Import"/>
                    </Steps>
                </Col>
                <Col sm={24}>
                    <Spin spinning={loading} className={'d-block'}>
                        {parentSource && {
                            0: <SourceFile
                                setStep={(step) => this.setStep(step)}
                                updateResource={this.updateResource}
                                resource={resource}
                                parentSource={parentSource}
                            />,
                            1: <SourcePreview
                                setStep={(step) => this.setStep(step)}
                                updateResource={this.updateResource}
                                resource={resource}
                                parentSource={parentSource}
                                onImportNew={this.reset}
                            />,
                            2: <SourceExecution
                                setStep={(step) => this.setStep(step)}
                                history={history}
                                match={match}
                                updateResource={this.updateResource}
                                resource={resource}
                                showBackButton={standAlone}
                                configuration={false}
                                onImportNew={this.reset}
                                action={action}
                                onFinish={onFinish}
                            />
                        }[step]}
                    </Spin>
                </Col>
            </Row>
        )

        return (
            <>
                <Prompt
                    when={shouldBlockNavigation}
                    message='You have unsaved changes, are you sure you want to leave?'
                />
                {standAlone ? (
                    <Card title={this.getTitle(parentSource, findServiceByClassName)} loading={loading}>
                        {content}
                    </Card>) : (<div>
                    {content}
                </div>)}

            </>
        )
    }
}

const mapStateToProps = (store: RootStateOrAny) => {
    return {
        findServiceByClassName: (className: string) => selectors.services.findOneByFullClassName(store, className)
    }
}

export default connect(mapStateToProps)(SourceImport)