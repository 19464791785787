import {Form, Select, Switch} from 'antd';
import React from "react";
import IFieldOptions from "../../../../../../../model/interface/form/elementOptions/IFieldOptions";
import FormFieldType from "../FormFieldType";
import MomentJsDateFormat from "../../../../../../shared/input/MomentJsDateFormat";
import TimeSectionsPicker from "../../../../../../shared/input/TimeSectionsPicker";

export interface IProps {
    options: IFieldOptions
}

class FormFieldDateEditor extends React.Component<IProps> {

    render() {
        const {options} = this.props

        return (
            <>
                <Form.Item name={'datePicker'} label={'Zvolte typ'} rules={[{required: true}]}
                           initialValue={FormFieldType.FIELD_DATE_DATE_PICKER}>
                    <Select>
                        {FormFieldType.FIELD_DATE_PICKER_TYPES.map(type => (
                            <Select.Option key={type.value} value={type.value}>{type.label}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item name={'datePickerFormat'} label={'Formát data'} initialValue={'YYYY-MM-DD'}>
                    <MomentJsDateFormat/>
                </Form.Item>
                <Form.Item name={'datePickerTimeFormat'} label={'Formát času'} initialValue={'HH:mm:ss'}>
                    <MomentJsDateFormat/>
                </Form.Item>
                {options.type === FormFieldType.FIELD_DATE && (
                    <Form.Item name={'datePickerConfirm'} label={'Potvrzovací tlačítko'} valuePropName={'checked'}>
                        <Switch/>
                    </Form.Item>
                )}
                <Form.Item name={'datePickerAllowedFrom'} label={'Povolit zpětne od'}>
                    <TimeSectionsPicker maxSection={'day'}/>
                </Form.Item>
                <Form.Item name={'datePickerAllowedTo'} label={'Povolit do'}>
                    <TimeSectionsPicker maxSection={'day'}/>
                </Form.Item>
            </>
        )
    }
}

export default FormFieldDateEditor