import React, {Component, RefObject} from 'react';
import {Button, FormInstance, Input, Select} from "antd";
import {connect, RootStateOrAny} from "react-redux";
import {PlusOutlined} from "@ant-design/icons";
import IAction, {ActionType} from "../../../model/interface/dataStorage/IAction";
import IContentType from "../../../model/interface/dataStorage/IContentType";
import selectors from "../../../redux/selectors";
import ContentTypeActionModal from "../../app/configuration/content-type/action/ContentTypeActionModal";
import ContentTypeActions from "../../app/configuration/content-type/ContentTypeActions";
import Utils from "../../../utils";
import {IAppState} from "../../../redux/store";
import IForm from "../../../model/interface/form/IForm";

interface IProps {
    value?: string | string[] | IAction | IAction[]
    onChange?: (action?: string | IAction | string[] | IAction[]) => void
    className?: string,
    contentTypeUuid: string,
    findContentType: (value: string) => IContentType
    style?: React.CSSProperties,
    disabled?: boolean
    canAdd?: boolean,
    types?: ActionType[],
    autoSave?: boolean
    multiple?: boolean
    output?: 'uuid' | 'object' | 'name' | string,
    defaultType?: ActionType
    forms: IForm[]
}

interface IState {
    formRef: RefObject<FormInstance>,
    showModal: boolean
}

class ActionPicker extends Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            formRef: React.createRef(),
            showModal: false
        }
    }

    static defaultProps = {
        canAdd: true,
        autoSave: true,
        output: "uuid"
    }

    getContentType = () => {
        const {findContentType, contentTypeUuid} = this.props
        return findContentType(contentTypeUuid);
    }

    onPickerChoose = (action?: string | string[]) => {
        const {onChange, output} = this.props

        if (Array.isArray(action)){
            const arrayOutput: any[] = []
            this.getContentType().actions.forEach(a => {
                if(action.includes(a.uuid)){
                    arrayOutput.push(this.evaluateOutput(output, a))
                }
                onChange?.(arrayOutput)
            })
        } else {
            const actionObject = this.getContentType().actions.find(a => a.uuid === action)
            onChange?.(this.evaluateOutput(output, actionObject))
        }
    }

    onModalChange = (action?: IAction) => {
        const {onChange, output, multiple, value} = this.props
        if (action){
            if (multiple && Array.isArray(value)){
                onChange?.([...value, this.evaluateOutput(output, action)] as any);
            } else {
                onChange?.(action);
            }
        }
        this.setState({showModal: false})
    }

    evaluateOutput(output: "uuid" | "object" | "name" | string | undefined, action?: IAction) {
        switch (output) {
            case "object":
                return action
            case "uuid":
                return action?.uuid
            case "name":
                return action?.name
            default:
                return action
        }
    }

    showModal = () => {
        this.setState(state => ({showModal: !state.showModal}))
    }

    getOptions() {
        const {types, value, autoSave} = this.props
        let options = [
            ...this.getContentType().actions.filter(a => !types || types.includes(a.type)),
        ]
        if (value && (typeof value === 'object') && !autoSave){
            if (Array.isArray(value)){
                value.forEach((item: any) => {
                    if (typeof  item === 'object' && item.id){
                        options.push(item)
                    }
                })
            } else if (value.id) {
                options.push(value)
            }
        }
        return options
    }

    render() {
        const {showModal} = this.state
        const {className, contentTypeUuid, style, disabled, canAdd, autoSave, defaultType, multiple, forms} = this.props
        const contentType = this.getContentType();
        const value = Utils.parseObjectToIdentifier(this.props.value, 'uuid')

        return (
            <div className="route-picker" style={style}>
                {showModal && (
                    <ContentTypeActionModal contentType={contentType}
                                            resource={{
                                                ...ContentTypeActions.createNewActionObject(contentTypeUuid),
                                                type: defaultType || ''
                                            }}
                                            onCancel={this.showModal}
                                            forms={forms.filter(f => f.contentType === contentType.uuid)} onSave={this.onModalChange}
                                            saveSeparately={autoSave}/>
                )}
                <Input.Group compact className={'d-flex w-100'}>
                    <Select
                        allowClear
                        className={className + ' flex-grow-1 min-w-0'}
                        disabled={disabled}
                        value={value}
                        mode={multiple ? 'multiple' : undefined}
                        placeholder="Vyberte akci"
                        optionFilterProp="children"
                        onChange={this.onPickerChoose}
                        filterOption={(input, option) =>
                            option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {this.getOptions().map(action => (
                                <Select.Option key={action.uuid} value={action.uuid as string}>
                                    {action.label + ' [' + action.name + ']'}
                                </Select.Option>
                            )
                        )}
                    </Select>
                    {canAdd && <Button className={'flex-shrink-0'} disabled={disabled} onClick={this.showModal}
                                       icon={<PlusOutlined/>}/>}
                </Input.Group>
            </div>
        )
    }
}

const mapStateToProps = (state: IAppState) => {
    return {
        findContentType: (value: string) => selectors.contentTypes.findOneBy(state, 'uuid', value),
        forms: state.setup.forms
    }
}

export default connect(mapStateToProps)(ActionPicker)
