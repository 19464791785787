import React from 'react'
import PasswordResetForm from "./PasswordResetForm";
import {RouteComponentProps, withRouter} from "react-router-dom";

interface IProps extends RouteComponentProps<any> {
    settings: any
}

interface IState {
}

class PasswordReset extends React.Component<IProps, IState> {

    render() {
        const {match} = this.props
        return (
            <PasswordResetForm token={'hash' in match.params ? match.params['hash'] : ''}/>
        )
    }
}

export default withRouter(PasswordReset)