import {
    NOTIFICATIONS_CHANGE_STATE,
    NOTIFICATIONS_LOAD,
    NOTIFICATIONS_SAVE,
    NOTIFICATIONS_SAVE_ONE
} from '../constants/Notifications';
import INotification from "../../model/interface/ui/INotification";
import Utils from "../../utils";

export interface INotificationState {
    loading: boolean,
    loaded: boolean,
    notifications: INotification[],
}

type IAction = {
    type: string,
    [x: string]: any
}

const initState: INotificationState = {
    loading: false,
    loaded: false,
    notifications: []
}

const notifications = (state = initState, action: IAction) => {
    switch (action.type) {
        case NOTIFICATIONS_LOAD: {
            return {
                ...state,
                loading: true
            }
        }
        case NOTIFICATIONS_SAVE: {
            return {
                ...state,
                notifications: action.results,
                loaded: true,
                loading: false
            }
        }
        case NOTIFICATIONS_SAVE_ONE: {
            const notification = action.notification
            let notifications = [...state.notifications]
            const index = Utils.findIndex(notifications, {id: notification.id})
            if(index < 0) {
                notifications = [...notifications, notification]
            } else {
                notifications[index] = notification
            }
            return {
                ...state,
                notifications,
                loading: false
            }
        }
        case NOTIFICATIONS_CHANGE_STATE:
            return {
                ...state,
                loading: true
            };
        default:
            return state
    }
}

export default notifications