import React, {RefObject} from "react";
import {Button, Form, FormInstance, Mentions, Modal, Select, Switch} from "antd";
import RoutePicker from "../../../../shared/pickers/RoutePicker";
import IAction, {
    ACTION_RESERVED_NAMES,
    ActionType,
    ActionTypes,
    ActionTypesList
} from "../../../../../model/interface/dataStorage/IAction";
import IForm from "../../../../../model/interface/form/IForm";
import {connect, RootStateOrAny} from "react-redux";
import {ISetupState} from "../../../../../redux/reducers/Setup";
import IRoute from "../../../../../model/interface/dataStorage/IRoute";
import Wysiwyg from "../../../../shared/input/Wysiwyg";
import ActionsService from "../../../../../model/service/dataStorage/ActionsService";
import IContentType from "../../../../../model/interface/dataStorage/IContentType";
import Utils from "../../../../../utils";
import SourcesService from "../../../../../model/service/import/SourcesService";
import {API_FILTER_TYPE} from "../../../../../model/constants/ApiConstant";
import IPattern from "../../../../../model/interface/file/generated-document/IPattern";
import PatternsService from "../../../../../model/service/file/generated-document/PatternsService";
import ContentTypeConfiguration from "../ContentTypeConfiguration";
import {update} from "../../../../../redux/actions/Setup";
import ILabelValue from "../../../../../model/interface/util/ILabelValue";
import WorkflowStatesService from "../../../../../model/service/file/WorkflowStatesService";
import GeneratedDocumentsService from "../../../../../model/service/file/generated-document/DocumentsService";
import {RELATION_FIELD_TYPE} from "../../../../../model/interface/dataStorage/IField";
import LocaleText from "../../../settings/dictionary/LocaleText";
import TranslationInput from "../../../../shared/input/TranslationInput";

interface IProps {
    resource: IAction | null,
    onSave: (action: IAction, close: boolean) => void,
    onCancel: () => void,
    forms: IForm[],
    routes: IRoute[],
    contentType: IContentType,
    contentTypes: IContentType[]
    saveSeparately?: boolean,
    update: (changes: any) => void,
}

interface IState {
    refForm: RefObject<FormInstance>,
    type: ActionType,
    confirmModalEnabled?: boolean
    importSources?: ILabelValue[],
    generatedDocumentPatterns?: IPattern[]
    saving?: boolean
}

class ContentTypeActionModal extends React.Component<IProps, IState> {

    constructor(props: Readonly<IProps> | IProps) {
        super(props)
        this.state = {
            refForm: React.createRef() as RefObject<FormInstance>,
            type: props.resource?.type || ''
        }
    }

    /**
     * Handle form reload after another action is opened
     * @param prevProps
     * @param prevState
     * @param snapshot
     */
    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (prevProps.resource?.uuid !== this.props.resource?.uuid) {
            this.state.refForm.current?.resetFields()
            this.setState({
                type: this.props.resource?.type || ActionTypes.SCRIPT,
                confirmModalEnabled: this.props.resource?.confirmModalEnabled
            }, this.onTypeChange)
        }
    }

    buildTypeOptions() {
        return [...ActionTypesList].filter(type => {
            switch (type.value) {
                case ActionTypes.WORKFLOW_STATE:
                    return this.hasExtension(WorkflowStatesService.EXTENSION_NAME)
                case ActionTypes.GENERATE_DOCUMENT:
                    return this.hasExtension(GeneratedDocumentsService.NAME)
                default:
                    return true
            }
        })
    }

    hasExtension(extension: string) {
        const {contentType} = this.props
        return contentType.extensions.some(e => e.type === extension);
    }

    buildFormOptions() {
        return this.getForms().map((form: IForm) => {
            return {
                value: form.uuid + '',
                label: <LocaleText code={form.label || ''}/>
            }
        })
    }

    buildActionOptions() {
        const {contentType, contentTypes} = this.props
        let actions: ILabelValue[] = []
        contentType.fields.forEach(f => {
            const c = contentTypes.find(c => c.fullClassName === f.targetEntity)
            c?.actions.forEach(action => {
                    let push
                    switch (action.type) {
                        case ActionTypes.FORM:
                        case ActionTypes.SCRIPT:
                        case ActionTypes.DELETE:
                            push = true
                            break
                        case ActionTypes.SHOW:
                            push = f.type === RELATION_FIELD_TYPE.ONE_TO_ONE
                            break
                        default:
                            push = false
                        //TODO what if type is workflowState or other types ??

                    }
                    if (push) {
                        actions.push({
                            value: action.uuid,
                            label: action.label + ' [' + c.name + ' - ' + (f.name) + ']'
                        })
                    }
                }
            )
        })
        return actions
    }

    onTypeChange = () => {
        const {type, refForm} = this.state
        const {contentType} = this.props
        if (type === ActionTypes.IMPORT) {
            SourcesService.choiceList('name-reader', {
                filters: {
                    0: {
                        field: 'type',
                        type: API_FILTER_TYPE.EQUAL,
                        value: contentType.fullClassName
                    },
                    1: {
                        field: 'parent',
                        type: API_FILTER_TYPE.IS_NULL
                    }
                }
            }).then(({results}) => {
                this.setState({
                    importSources: Object.entries(results).map(([value, label]) => ({value, label}))
                }, () => refForm.current?.resetFields(['importSource']))
            })
        }
        if (type === ActionTypes.GENERATE_DOCUMENT) {
            PatternsService.collectionList({
                filters: {
                    0: {
                        field: 'contentType',
                        type: API_FILTER_TYPE.EQUAL,
                        value: contentType.uuid
                    }
                }
            }).then(({results}) => {
                this.setState({generatedDocumentPatterns: results},
                    () => refForm.current?.resetFields(['generatedDocumentPattern']))
            })
        }
    }

    change(values: any) {
        if (values.type) {
            this.setState({type: values.type}, this.onTypeChange)
        }

        if (values.confirmModalEnabled !== undefined) {
            this.setState({confirmModalEnabled: values.confirmModalEnabled})
        }
    }

    submit = () => {
        const {saveSeparately, resource, onSave, contentType, contentTypes, update} = this.props
        this.state.refForm.current?.validateFields().then(values => {
            const action = {...resource, ...values}
            if (saveSeparately) {
                this.setState({saving: true})
                if (action.id) {
                    return ActionsService.resourceUpdate(action?.id, ContentTypeConfiguration.mapAction(action))
                        .then(() => onSave(action, true))
                }
                return ActionsService.collectionCreate(ContentTypeConfiguration.mapAction(action))
                    .then(() => {
                        update({
                            contentTypes: [...contentTypes.filter(c => c.uuid !== contentType.uuid), {
                                ...contentType,
                                actions: [...contentType.actions.filter(a => a.uuid !== action.uuid), action]
                            }]
                        })
                        onSave(action, true)
                    })
            }
            onSave(action, true)
        })
    }

    showConfirmModal = () => {
        ActionsService.showConfirmModal(this.state.refForm.current?.getFieldValue('confirmModalText'))
            .then().catch(() => console.error('rejected'))
    }

    getForms(): IForm[] {
        const {forms, contentType} = this.props
        return forms.filter(form => form.contentType === contentType.uuid)
    }

    render() {
        const {resource, onCancel} = this.props
        const {type, refForm, confirmModalEnabled, importSources, generatedDocumentPatterns, saving} = this.state

        return (
            <Modal title={resource?.label ? resource.label : 'Nová akce'} visible={!!resource} onCancel={onCancel}
                   onOk={this.submit} destroyOnClose={true} closable={true}
                   footer={[
                       <Button key="submit" type="primary" loading={saving} onClick={this.submit}>
                           <LocaleText code={'save'} fallback={'Uložit'}/>
                       </Button>
                   ]}
            >
                <Form initialValues={{...resource, confirmModalText: 'Opravdu chcete provést tuto akci?'}} ref={refForm}
                      onValuesChange={(values) => this.change(values)}>
                    <Form.Item label={'Titulek'} name={'label'} rules={[{required: true, message: <LocaleText code={'form.item.required'}/>}]}>
                        <TranslationInput uuid={resource?.uuid || Utils.uuid()} code={'configuration.action.' + resource?.uuid}/>
                    </Form.Item>
                    <Form.Item
                        label={'Strojový název'}
                        name={'name'}
                        rules={[
                            () => ({
                                validator: (_: any, name: string) => {
                                    const index = Utils.findIndex(this.props.contentType.actions, {name})
                                    if (index < 0 || this.props.contentType.actions[index].uuid === this.props.resource!.uuid) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('Název musí být u typu obsahu unikátní'));
                                }
                            })
                        ]}
                    >
                        <Mentions
                            split={''}
                            readOnly={resource ? resource.locked : false}
                            placeholder={"název nebo # pro vyhrazený název"}
                            prefix={'#'}
                        >
                            {Object.entries(ACTION_RESERVED_NAMES).map(([label, value]) => (
                                <Mentions.Option key={value} value={value.slice(1)}>
                                    {label}
                                </Mentions.Option>
                            ))}
                        </Mentions>
                    </Form.Item>
                    <Form.Item
                        label={'Typ'}
                        name={'type'}
                    >
                        <Select options={this.buildTypeOptions()}/>
                    </Form.Item>
                    {[ActionTypes.FORM, ActionTypes.SCRIPT_FORM].indexOf(type) >= 0 && (
                        <Form.Item
                            label={'Formuláře'}
                            name={'forms'}
                        >
                            <Select mode={"multiple"} options={this.buildFormOptions()}/>
                        </Form.Item>
                    )}
                    {[ActionTypes.RELATION_ACTION].indexOf(type) >= 0 && (
                        <Form.Item
                            label={'Vztahová akce'}
                            name={'relationAction'}
                        >
                            <Select options={this.buildActionOptions()}/>
                        </Form.Item>
                    )}
                    {[ActionTypes.IMPORT].indexOf(type) >= 0 && (
                        <Form.Item
                            label={'Zdroj'}
                            name={'importSource'}
                        >
                            <Select allowClear={true} loading={!importSources}>
                                {importSources?.map(source => (
                                    <Select.Option value={source.value}>
                                        {source.label}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    )}
                    {[ActionTypes.GENERATE_DOCUMENT].indexOf(type) >= 0 && (
                        <Form.Item
                            label={'Vzor'}
                            name={'generatedDocumentPattern'}
                        >
                            <Select allowClear={true} loading={!generatedDocumentPatterns}>
                                {generatedDocumentPatterns?.map(pattern => (
                                    <Select.Option value={pattern.uuid}>
                                        {pattern.label} [{pattern.file.fullName}]
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    )}
                    {[
                        ActionTypes.FORM,
                        ActionTypes.SCRIPT_FORM,
                        ActionTypes.SHOW,
                        ActionTypes.IMPORT,
                        ActionTypes.REVISION_HISTORY
                    ].indexOf(type) >= 0 && (
                        <Form.Item
                            label={'URL'}
                            name={'route'}
                            rules={[{
                                required: [
                                    ActionTypes.IMPORT, ActionTypes.REVISION_HISTORY, ActionTypes.SHOW, ActionTypes.FORM
                                ].indexOf(type) >= 0
                            }]}>
                            <RoutePicker/>
                        </Form.Item>
                    )}
                    {![ActionTypes.RELATION_ACTION].includes(type) && (
                        <Form.Item
                            label={"Přesměrování"}
                            name={"afterRedirect"}
                        >
                            <RoutePicker/>
                            {/*<Select options={routeOptions} />*/}
                        </Form.Item>
                    )}

                    <Form.Item valuePropName={'checked'} label={'Provádět v modalu'} name={'modal'}>
                        <Switch disabled={![
                            ActionTypes.FORM, ActionTypes.SHOW, ActionTypes.IMPORT, ActionTypes.REVISION_HISTORY, ActionTypes.RELATION_ACTION
                        ].includes(type)}/>
                    </Form.Item>
                    <Form.Item valuePropName={'checked'} label={'Zobrazit dialog pro potvrzení'}
                               name={'confirmModalEnabled'}>
                        <Switch/>
                    </Form.Item>
                    {confirmModalEnabled && (
                        <div>
                            <Form.Item name={'confirmModalText'} rules={[{required: true}]}>
                                <Wysiwyg mode={'medium'} placeholder={'Obsah potvrzovacího dialogu'}/>
                            </Form.Item>
                            <Button onClick={this.showConfirmModal}>Zobrazit dialog</Button>
                        </div>
                    )}
                </Form>
            </Modal>
        )
    }


}

const mapStateToProps = (state: RootStateOrAny) => {
    const {contentTypes, routes} = state.setup as ISetupState
    return {
        contentTypes,
        routes
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        update: (changes: any) => dispatch(update(changes))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentTypeActionModal)
