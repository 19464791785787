import React, {RefObject} from "react"
import {Button, Form, FormInstance, Input, message, Switch, Tooltip} from "antd";
import {QuestionCircleFilled, SaveOutlined} from "@ant-design/icons";
import FilePicker from "../../shared/pickers/file/FilePicker";
import IBackground from "../../../model/interface/ui/IBackground";
import BackgroundsService from "../../../model/service/ui/BackgroundsService";
import Modal from "../../shared/modal/Modal";
import Background from "./Background";

interface IProps {
    background?: IBackground
    onChange: (background?: IBackground) => void
    modal?: boolean
}

interface IState {
    loading: boolean,
    values: any
}

class BackgroundForm extends React.Component<IProps, IState> {

    formRef: RefObject<FormInstance> = React.createRef()

    constructor(props: IProps) {
        super(props);
        this.state = {
            loading: false,
            values: props.background || {}
        }
    }

    onOk = () => {
        const {onChange, background} = this.props
        this.formRef.current?.validateFields()
            .then(values => {
                this.setState({loading: true})
                this.save({
                    ...background, ...values
                })
                    .then(resource => {
                        onChange(resource)
                        message.success('Pozadi uloženo').then()
                    })
            })
    }

    save(background: IBackground) {
        if (background.id) {
            return BackgroundsService.resourceUpdate(background.id, background)
        }

        return BackgroundsService.collectionCreate(background)
    }

    onCancel = () => {
        this.props.onChange()
    }

    onValuesChange = (values: any) => {
        this.setState({values})
    }


    render() {
        const {loading, values} = this.state
        const {background, modal} = this.props

        const form = <div>
            <Form style={{position: "relative", zIndex: 100}} ref={this.formRef} initialValues={{positionY: 'top', ...background}} layout={"vertical"}
                  onValuesChange={(_, values) => this.onValuesChange(values)}>
                <Form.Item label={"Url"} name={"url"} rules={[{required: !values['file']}]}>
                    <Input/>
                </Form.Item>
                <Form.Item label={"Soubor"} name={"file"} rules={[{required: !values['url']}]}>
                    <FilePicker/>
                </Form.Item>
                <Form.Item label={<div>Velikost pozadí
                    <Tooltip title={'Syntax'}>
                        <a className={'ml-2'} target={'_blank'}
                           href={'https://developer.mozilla.org/en-US/docs/Web/CSS/background-size'}
                           rel="noreferrer">
                            <QuestionCircleFilled/>
                        </a>
                    </Tooltip>
                </div>} name={"fit"} rules={[{required: true}]}>
                    <Input placeholder={'contain / cover / px / % ...'}/>
                </Form.Item>
                <Form.Item label={<div>Zarovnání vertikální
                    <Tooltip title={'Syntax'}>
                        <a className={'ml-2'} target={'_blank'}
                           href={'https://developer.mozilla.org/en-US/docs/Web/CSS/background-position-y'}
                           rel="noreferrer">
                            <QuestionCircleFilled/>
                        </a>
                    </Tooltip>
                </div>} name={"positionY"}>
                    <Input placeholder={'top / center / bottom / px / % ...'}/>
                </Form.Item>
                <Form.Item label={<div>Zarovnání horizontální
                    <Tooltip title={'Syntax'}>
                        <a className={'ml-2'} target={'_blank'}
                           href={'https://developer.mozilla.org/en-US/docs/Web/CSS/background-position-x'}
                           rel="noreferrer">
                            <QuestionCircleFilled/>
                        </a>
                    </Tooltip>
                </div>} name={"positionX"}>
                    <Input placeholder={' left / center / right / px / % ...'}/>
                </Form.Item>
                <Form.Item label={"Scrollovat pozadí"} name={"scroll"} valuePropName={'checked'}>
                    <Switch/>
                </Form.Item>
            </Form>
            {!modal && (
                <Button icon={<SaveOutlined/>} className={'mt-3'} type="primary" loading={loading} onClick={this.onOk}>
                    Uložit
                </Button>
            )}
            <Background style={{opacity: 0.1, top: 0, left: 0}} className={'position-absolute h-100 w-100'}
                        background={values}> </Background>

        </div>

        return modal ? (<Modal
                width={700}
                title={background ? 'Upravit pozadi' : 'Vytvořit pozadi'}
                visible={true}
                fullScreenOption={true}
                destroyOnClose={true}
                onOk={() => this.onOk()}
                onCancel={this.onCancel}
                closable={true}
                footer={[
                    <Button key="submit" type="primary" loading={loading} onClick={this.onOk} icon={<SaveOutlined/>}>
                        Uložit
                    </Button>
                ]}
            >
                {form}
            </Modal>
        ) : form
    }
}


export default BackgroundForm
