import {DISABLE_MASQUERADE, MASQUERADE, SIGNOUT, STORE_TOKEN} from '../constants/Auth';
import IUser from "../../model/interface/security/IUser";

export const storeToken = (token: string, redirect?: string) => {
  return {
    type: STORE_TOKEN,
    token,
    redirect
  }
}
export const signOut = () => {
  return {
    type: SIGNOUT
  };
};
export const masquerade = (user: IUser) => {
  return {
    type: MASQUERADE,
    masquerade: user.uuid
  };
};
export const disableMasquerade = () => {
  return {
    type: DISABLE_MASQUERADE
  };
};

export default {
  storeToken,
  signOut
}