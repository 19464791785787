import React from "react"
import {IFilterInput} from "../IFilterBase";
import {API_FILTER_TYPE} from "../../../model/constants/ApiConstant";
import {Input} from "antd";


interface IState {

}

interface IProps extends IFilterInput {
    onPressEnter?: () => void
    inputRef?: (node: Input) => void
}

class Search extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {}
    }

    render() {
        const {field, onChange, onPressEnter, selected, inputRef} = this.props
        const search = selected[0] ? selected[0].value + '' : undefined

        return (
            <Input
                ref={inputRef}
                placeholder={'Vyhledat'}
                value={search}
                onChange={e => onChange([{value: e.target.value, type: API_FILTER_TYPE.LIKE, name: field.name}])}
                onPressEnter={onPressEnter}
                style={{marginBottom: 8, display: 'block'}}
            />
        )
    }
}

export default Search