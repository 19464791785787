import React from "react";
import {Button, Spin} from "antd";
import {CaretRightOutlined} from "@ant-design/icons";
import IOrganigrammeNode from "../../../../../../model/interface/company/IOrganigrammeNode";
import CompanyStructureService, {
    IRestCompanyStructureAccept
} from "../../../../../../model/service/company/CompanyStructureService";
import IFieldOptions from "../../../../../../model/interface/form/elementOptions/IFieldOptions";
import IEmployee from "../../../../../../model/interface/company/IEmployee";
import ICompany from "../../../../../../model/interface/company/ICompany";
import IUnit from "../../../../../../model/interface/company/IUnit";
import Utils from "../../../../../../utils";
import ReactDOMServer from "react-dom/server";
import {API_FILTER_TYPE} from "../../../../../../model/constants/ApiConstant";
import {IRestServiceChoiceList} from "../../../../../../model/interface/api/IRestServiceChoiceListResponse";
import IRestResource from "../../../../../../model/interface/api/IRestResource";
import ProSelect from "../../../../../shared/input/ProSelect";
import ILabelValue from "../../../../../../model/interface/util/ILabelValue";
import IRestServiceFilters from "../../../../../../model/interface/api/IRestServiceFilters";
import IField, {RELATION_FIELD_TYPE} from "../../../../../../model/interface/dataStorage/IField";

interface IProps {
    value?: string | IRestResource | (string | IRestResource)[],
    onChange?: (key?: string | string[] | IOrganigrammeNode, label?: string | string[]) => void
    options: IFieldOptions
    currentEmployee?: IEmployee
    onOptionsChange?: (options: ILabelValue[]) => void
    filters?: IRestServiceFilters
    field?: IField
}

interface IState {
    roots: IOrganigrammeNode[]
    list?: IRestServiceChoiceList
    loading: boolean
    value?: string | string[]
}

class FormFieldCompanyStructure extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props)
        this.state = {
            loading: true,
            roots: [],
            value: undefined
        }
    }

    inputRef: any

    componentDidMount() {
        this.autoFocus()
        this.load().then()
    }

    autoFocus() {
        const {autoFocus} = this.props.options
        autoFocus && setTimeout(() => this.inputRef?.focus(), 100) // has to have a timeout...
    }

    isMultiple() {
        const {field, options} = this.props
        return field ? [RELATION_FIELD_TYPE.MANY_TO_MANY, RELATION_FIELD_TYPE.ONE_TO_MANY].includes(field.type) : (options.companyStructureMultiple || false)
    }

    getValueFromNode(node: IOrganigrammeNode): string | undefined {
        switch (this.props.options.companyStructureAccepts) {
            case("node"):
                return node.uuid
            case("company"):
                return node.company ? node.company.uuid : undefined
            case("unit"):
                return node.unit ? node.unit.uuid : undefined
            case("employee"):
                return node.employee ? node.employee.uuid : undefined
            case("job"):
                return node.job ? node.job.uuid : undefined
        }
        return undefined
    }

    onSelect(node?: IOrganigrammeNode | string) {
        let value = Utils.parseObjectToIdentifier(this.props.value, 'uuid');
        const {options} = this.props
        const {list} = this.state

        if (options.companyStructureMode === "list") {
            value = typeof node === "string" ? node : undefined
            if (Array.isArray(node)) {
                value = node
            }
            const label = list && (Array.isArray(value) ? value.map(v => list[v]) : (value ? list[value] : ''))
            return this.props.onChange?.(options.companyReturnNode ? node : value, label)
        }
        let selected = typeof node === "object" ? this.getValueFromNode(node) : undefined
        if (selected) {
            if (this.isMultiple()) {
                if (typeof value !== 'object') {
                    value = [] as string[]
                }
                let index = value?.indexOf(selected)
                if (index >= 0) {
                    value.splice(index)
                } else {
                    value.push(selected)
                }
            } else {
                if (value === selected) {
                    value = undefined
                } else {
                    value = selected
                }
            }
        }
        return this.props.onChange && this.props.onChange(options.companyReturnNode ? node : value)
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        const {options} = this.props
        if (JSON.stringify(options.companyStructureRoot) !== JSON.stringify(prevProps.options.companyStructureRoot)
            || options.companyStructureMode !== prevProps.options.companyStructureMode
            || (
                options.companyStructureMode === "list"
                && options.companyStructureAccepts !== prevProps.options.companyStructureAccepts
            )
        ) {
            this.load().then()
        }

        this.autoFocus()
    }

    load() {
        const {options, currentEmployee, filters} = this.props
        const root = options.companyStructureRoot
        // const activeFilter = options.companyStructureActive && {
        //     active: {
        //         field: 'active',
        //         type: API_FILTER_TYPE.EQUAL,s
        //         value: true,
        //     }
        // }
        if ((options.companyStructureMode || 'tree') === "tree" || options.companyStructureOnlyDirectChildren) {
            return new Promise<void>(resolve => {
                this.setState({loading: true}, () => resolve())
            }).then(() => {
                if (root && !options.companyStructureOnlyDirectChildren){
                    return CompanyStructureService.loadSharedChildrenOnly( Array.isArray(root) ? root : [root], {
                        limit: 0,
                        filters: {
                            ...(options.companyStructureHideCurrent && currentEmployee ? {
                                'self': {
                                    field: "employee.uuid",
                                    type: API_FILTER_TYPE.NOT_EQUAL,
                                    value: currentEmployee.uuid
                                }
                            } : undefined),
                            //...(options.companyStructureActive && activeFilter)
                            ...filters,
                        }
                    })
                }

                return CompanyStructureService.collectionList({
                    limit: 0,
                    filters: {
                        0: {
                            type: "or", children: {
                                0: root
                                    ? {
                                        field: "parent",
                                        type: typeof root === 'object' ? API_FILTER_TYPE.IN : API_FILTER_TYPE.EQUAL,
                                        value: root
                                    }
                                    : {field: "parent", type: "isNull"}
                            }
                        },
                        ...(options.companyStructureHideCurrent && currentEmployee ? {
                            'self': {
                                field: "employee.uuid",
                                type: API_FILTER_TYPE.NOT_EQUAL,
                                value: currentEmployee.uuid
                            }
                        } : undefined),
                        //...(options.companyStructureActive && activeFilter)
                        ...filters,
                    }
                })



            }).then(response => {
                return new Promise<void>(resolve => {
                    this.setState({roots: response.results}, () => {
                        resolve();
                    })
                })
            }).then(() => {
                return new Promise<void>(resolve => {
                    this.setState({loading: false}, () => {
                        resolve()
                    })
                })
            })
        }
        this.setState({loading: true})
        return CompanyStructureService.loadAllStructureChoicesById(root, options.companyStructureAccepts, true, {
            ...options, filters: {
                ...(options.companyStructureHideCurrent && currentEmployee ? {
                    'self': {
                        field: "employee.uuid",
                        type: API_FILTER_TYPE.NOT_EQUAL,
                        value: currentEmployee.uuid
                    }
                } : undefined)
            }
        })
            .then(({results}) => {
                return new Promise<void>(resolve => {
                    this.setState({loading: false, list: results}, () => {
                        resolve()
                    })
                })
            })
    }

    render() {
        const {loading, roots, list} = this.state
        const {value, options} = this.props
        const accepts = options.companyStructureAccepts || "node"
        const mode = options.companyStructureMode || 'tree'

        const parsedValue = Utils.parseObjectToIdentifier(value, 'uuid');

        return (
            <>
                {mode === 'tree' && (
                    <Spin spinning={loading} size={"small"}>
                        {roots && roots.map(node => (
                            <EmployeeCompanyStructureNode
                                accepts={accepts}
                                node={node}
                                onlyDirectChildren={options.companyStructureOnlyDirectChildren}
                                value={parsedValue}
                                search={options.companyStructureSearch}
                                onSelect={(node) => this.onSelect(node)}
                            />
                        ))}
                    </Spin>
                )}
                {mode === 'list' && (
                    <ProSelect
                        showSearch
                        inputRef={(inputRef) => this.inputRef = inputRef}
                        mode={this.isMultiple() ? 'multiple' : undefined}
                        value={loading ? undefined : parsedValue}
                        disabled={options.disabled}
                        allowClear={options.showClear}
                        loading={loading}
                        allowSelectAll={options.allowSelectAll}
                        notFoundContent={loading ? <Spin size="small"/> : null}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            Utils.stringContains(ReactDOMServer.renderToString(option?.label as any), input)
                        }
                        onChange={(value: any) => {
                            this.onSelect(value)
                        }}
                        className={'w-100'}
                        options={Object.entries(list || [])
                            .sort(([, a], [, b]) => a.toLowerCase() > b.toLowerCase() ? 1 : -1)
                            .map(([value, label]) => ({value, label}))}
                    />
                )}
            </>
        )
    }
}

interface INodeProps {
    node: IOrganigrammeNode
    value?: string | string[]
    accepts: IRestCompanyStructureAccept
    onSelect: (node: IOrganigrammeNode) => void
    onlyDirectChildren?: boolean
    search?: string
}

interface INodeState {
    opened: boolean
    loaded: boolean
    loading: boolean
    children?: IOrganigrammeNode[]
    selected?: boolean
}

class EmployeeCompanyStructureNode extends React.Component<INodeProps, INodeState> {

    constructor(props: INodeProps, context: any) {
        super(props, context);
        this.state = {
            opened: false,
            loading: false,
            loaded: false,
            children: undefined as undefined | IOrganigrammeNode[],
            selected: false
        }
    }

    toggle() {
        const {opened, loading, loaded} = this.state
        if (opened) {
            this.setState({opened: false})
        } else {
            if (loaded) {
                this.setState({opened: true})
            } else {
                if (!loading) {
                    this.setState({loading: true}, () => {
                        this.load().then(() => {
                            this.setState({loaded: true, loading: false, opened: true})
                        })
                    })
                }
            }
        }
    }

    load(): Promise<void> {
        const {node} = this.props
        return CompanyStructureService.collectionList({
            limit: 0,
            filters: {
                0: {
                    type: "or", children: {
                        0: node
                            ? {field: "parent", type: "equal", value: node.id}
                            : {field: "parent", type: "isNull"}
                    }
                }
            }
        }).then(response => {
            return new Promise<void>(resolve => {
                this.setState({
                    children: response.results
                })
                resolve();
            })
        })
    }

    static getAccepted = (node: IOrganigrammeNode, accepts: "company" | "employee" | "unit" | "job" | "node") => {
        switch (accepts) {
            case "employee":
                return node.employee
            case "company":
                return node.company
            case "job":
                return node.job
            case "unit":
                return node.unit
            case "node":
                return node
        }
    }

    isSelected(value: string | string[] | undefined, acceptedValue: IEmployee | ICompany | IUnit | IOrganigrammeNode) {
        return value && ((typeof value === "string" && acceptedValue.uuid === value) || (Array.isArray(value) && acceptedValue.id && value.includes(acceptedValue.uuid)));
    }

    static buildNodeElement(node: IOrganigrammeNode) {
        return <span>
            {node.company && node.company.name}
            {node.unit && node.unit.name}
            {(node.employee || node.job) && (
                <>
                    {node.employee ? node.employee!.fullName : ''}
                    {node.job && (<span className="text-muted">&nbsp;{node.job.name}</span>)}
                </>
            )}
        </span>;
    }

    render() {

        const {node, onSelect, value, onlyDirectChildren, search, accepts} = this.props
        const {children, opened, loading} = this.state

        const acceptedValue = EmployeeCompanyStructureNode.getAccepted(node, accepts)

        const nodeElement = EmployeeCompanyStructureNode.buildNodeElement(node)

        return (
            <div key={node.id}>
                <div>
                    <div className={''} style={{
                        width: 16,
                        display: 'inline-block',
                        cursor: onlyDirectChildren ? "not-allowed" : "pointer"
                    }}>
                        {node.children.length > 0 && (
                            <span onClick={() => !onlyDirectChildren && this.toggle()}>
                                <CaretRightOutlined style={{
                                    color: '#666',
                                    transition: 'all .3s',
                                    transform: opened ? 'rotate(90deg)' : 'rotate(0deg)'
                                }}/>
                            </span>
                        )}
                    </div>
                    <Spin size={"small"} spinning={loading}/>
                    {acceptedValue ? (
                        <Button type={this.isSelected(value, acceptedValue) ? 'primary' : 'link'} size={"small"}
                                onClick={() => onSelect(node)}
                                className={Utils.stringContains(ReactDOMServer.renderToString(nodeElement), search) ? 'bg-gray-lighter' : ''}>
                            {nodeElement}
                        </Button>
                    ) : nodeElement}

                </div>
                {opened && children && (
                    <div className={"pl-2"}>
                        {children.map(child => <EmployeeCompanyStructureNode {...this.props} node={child}/>)}
                    </div>
                )}
            </div>
        )
    }
}

export default FormFieldCompanyStructure