import React from "react"
import FormFieldContentType
    from "../../../components/app/configuration/form/FormElement/formField/FormFieldContentType";
import FormFieldCompanyStructure
    from "../../../components/app/configuration/form/FormElement/formField/FormFieldCompanyStructure";
import {IFieldOptions} from "../../../model/interface/dataStorage/IField";
import UnitsService from "../../../model/service/company/UnitsService";
import FormFieldType from "../../../components/app/configuration/form/FormElement/formField/FormFieldType";
import FormFieldWorkflowState
    from "../../../components/app/configuration/form/FormElement/formField/FormFieldWorkflowState";
import WorkflowStatesService from "../../../model/service/file/WorkflowStatesService";
import JobsService from "../../../model/service/company/JobsService";
import CompaniesService from "../../../model/service/company/CompaniesService";
import EmployeesService from "../../../model/service/company/EmployeesService";
import {IFilterInput} from "../IFilterBase";
import {API_FILTER_TYPE} from "../../../model/constants/ApiConstant";


interface IState {

}

interface IProps extends IFilterInput{
    autoFocus?: boolean
}

class Choose extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {}
    }

    render() {
        const {field, item, onChange, selected, autoFocus} = this.props
        const globalOptions: IFieldOptions & {label: string} = {
            showClear: true,
            multiple: true,
            companyStructureMultiple: true,
            autoFocus: autoFocus,
            label: '',
            companyStructureMode: 'list',
            allowSelectAll: field.options.allowSelectAll !== false,
            ...field.options,
            ...item.options?.fieldOptions
        }

        const onChangeLocal = (value: any, label?: string | JSX.Element | (string | JSX.Element)[]) => {
            if (value && (!Array.isArray(value) || value.length)) {
                onChange([{
                    value,
                    name: field.name,
                    type: Array.isArray(value) ? API_FILTER_TYPE.IN : API_FILTER_TYPE.EQUAL,
                    label: label,
                }])
            } else {
                onChange([{
                    value: '',
                    type: API_FILTER_TYPE.IS_NULL,
                    label: label,
                    name: field.name
                }])
            }
        }

        const selectedValue = selected[0]?.value && Array.isArray(selected[0].value) &&
        selected[0].value.length ? selected[0].value : undefined

        const sharedProps = {onChange : onChangeLocal, value: selectedValue as any}

        return (
            <>
                {field.targetEntity && field.contentTypeId ? {
                    [field.targetEntity]: <FormFieldContentType
                        {...sharedProps}
                        options={globalOptions}
                        field={field}
                    />,
                    [UnitsService.getInstance().getRecordClassName()]:
                        <FormFieldCompanyStructure
                            {...sharedProps}
                            options={{
                                companyStructureAccepts: 'unit',
                                ...globalOptions,
                                type: FormFieldType.FIELD_COMPANY_STRUCTURE
                            }}
                        />,
                    [JobsService.getInstance().getRecordClassName()]:
                        <FormFieldCompanyStructure
                            {...sharedProps}
                            options={{
                                companyStructureAccepts: 'job',
                                ...globalOptions,
                                type: FormFieldType.FIELD_COMPANY_STRUCTURE
                            }}
                        />,
                    [CompaniesService.getInstance().getRecordClassName()]:
                        <FormFieldCompanyStructure
                            {...sharedProps}
                            options={{
                                companyStructureAccepts: 'company',
                                ...globalOptions,
                                label: '',
                                type: FormFieldType.FIELD_COMPANY_STRUCTURE
                            }}
                        />,
                    [EmployeesService.getInstance().getRecordClassName()]:
                        <FormFieldCompanyStructure
                            {...sharedProps}
                            options={{
                                ...globalOptions,
                                label: '',
                                type: FormFieldType.FIELD_COMPANY_STRUCTURE,
                                companyStructureAccepts: 'employee',
                                companyStructureMode: 'list'
                            }}
                        />,
                    [WorkflowStatesService.getRecordClassName()]:
                        <FormFieldWorkflowState
                            {...sharedProps} className={'w-100'} options={{
                            ...globalOptions,
                            contentTypeId: field.contentTypeId,
                            type: '',
                        }}/>
                }[field.targetEntity] : 'Chyba konfigurace'}
            </>
        )
    }
}

export default Choose