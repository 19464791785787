import {Card, Form, FormInstance, Input, Select} from 'antd';
import React, {RefObject} from "react";
import IField, {RELATION_FIELD_TYPE} from "../../../../../../../model/interface/dataStorage/IField";
import {connect, RootStateOrAny} from "react-redux";
import selectors from "../../../../../../../redux/selectors";
import IRepositoryService from "../../../../../../../model/interface/IRepositoryService";
import IFieldOptions from "../../../../../../../model/interface/form/elementOptions/IFieldOptions";
import DataStorageService from "../../../../../../../model/service/dataStorage/DataStorageService";
import IRestResource from "../../../../../../../model/interface/api/IRestResource";

export interface IContentTypeCascadeEditorOptions {
    contentTypeFullClassName?: string
    contentTypePresenter?: string
    contentTypeOrderField?: 'ASC' | "DESC"
    contentTypeCascadeField?: string
    contentTypeAdditionalRows?: IRestResource[]
}

interface IProps {
    onChange: (options: IFieldOptions, afterCallback?: () => void) => void
    options: IFieldOptions
    formRef: RefObject<FormInstance>
    findServiceByClassName: (name: string) => IRepositoryService,
    field: IField
}

interface IState {
}

class FormFieldContentTypeEditor extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.populateMandatoryOptions()
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        this.populateMandatoryOptions()
    }

    populateMandatoryOptions = () => {
        const {field} = this.props
        let update: any = {}
        if ([RELATION_FIELD_TYPE.MANY_TO_MANY, RELATION_FIELD_TYPE.ONE_TO_MANY].indexOf(field.type) >= 0 && !this.props.options.multiple) {
            update.multiple = true
        }
        const presenters = this.getService().getPresenterList()
        if (presenters.length === 1 && !this.props.options.contentTypePresenter) {
            update.contentTypePresenter = presenters[0].value
        }
        console.log('populateMandatoryOptions', update)
        if (Object.keys(update).length > 0) {
            this.props.onChange({...this.props.options, ...update}, () => this.props.formRef.current?.resetFields(Object.keys(update)))
        }
    }

    getService(): IRepositoryService {
        const {field, findServiceByClassName} = this.props
        if (!field.targetEntity) {
            throw new Error(`Target entity not defined for contentType[${field.contentTypeId}] field[${field.name}]`)
        }

        let service: DataStorageService = findServiceByClassName(field.targetEntity) as DataStorageService
        if (!service) {
            throw new Error('Service for "' + field.targetEntity + '" is missing')
        }
        // if (!service.hasOwnProperty('contentType')) {
        //     throw new Error('Service for "' + field.targetEntity + '" is not content type')
        // }

        return service;
    }

    render() {
        const service = this.getService()
        // if(typeof service.getContentType !== 'function') {
        //     throw new Error('Current service is not data storage service')
        // }
        // const contentType = service.getContentType()
        const presenters = service.getPresenterList()
        const fields = service.getFields()
        const orderFields = fields.filter(field => field.mode === "scalar").map(field => ({
            value: field.uuid,
            label: field.label
        }))

        console.log('FormFieldContentTypeEditor', service, presenters, fields)

        return (
            <Card>
                <Form.Item label={"Typ obsahu"}>
                    <Input disabled value={service.getTitle()}/>
                </Form.Item>
                {presenters && (
                    <Form.Item label={"Zobrazení"} name={"contentTypePresenter"} rules={[{required: true}]}>
                        <Select options={presenters}/>
                    </Form.Item>
                )}

                <Form.Item label={"Řadit dle"} name={"contentTypeOrderField"}>
                    <Select options={orderFields} allowClear={true}/>
                </Form.Item>

                {fields && (
                    <Form.Item label={"Třídit podle"} name={"contentTypeCascadeField"}
                               rules={[{required: true}]}>
                        <Select>
                            {fields.map((field: IField) =>
                                <Select.Option key={field.id} value={field.name}>
                                    {`${field.label} [${field.name}]`}
                                </Select.Option>
                            )}
                        </Select>
                    </Form.Item>
                )}
            </Card>
        )
    }
}

const mapStateToProps = (state: RootStateOrAny) => {
    return {
        findServiceByClassName: (name: string) => selectors.services.findOneByFullClassName(state, name),
    }
}
export default connect(mapStateToProps)(FormFieldContentTypeEditor)