import React, {Component} from 'react';
import {Button, Input, Select} from "antd";
import {connect, RootStateOrAny} from "react-redux";
import {ISetupState} from "../../../redux/reducers/Setup";
import IRoute from "../../../model/interface/dataStorage/IRoute";
import {EditOutlined, PlusOutlined} from "@ant-design/icons";
import IScript from "../../../model/interface/dataStorage/IScript";
import Utils from "../../../utils";
import ScriptForm from "../../app/configuration/script/ScriptForm";
import LocaleText from "../../app/settings/dictionary/LocaleText";

interface IProps {
    value?: string | IRoute
    onChange?: (uuid?: string) => void
    onObjectChange?: (object?: IScript) => void
    className?: string,
    scripts: IScript[],
    style?: React.CSSProperties,
    disabled?: boolean
    canAdd?: boolean
    canEdit?: boolean,
    includeName?: boolean
    autoFocus?: boolean
}

interface IState {
    showModal?: 'edit' | 'add'
}

class ScriptPicker extends Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {}
    }

    static defaultProps = {
        canAdd: true,
        canEdit: true,
        className: '',
        includeName: true,
    }

    onPickerChoose = (script: string) => {
        const {onChange, onObjectChange, scripts} = this.props
        const object = scripts.find(s => s.uuid === script)!
        onChange?.(object.uuid)
        onObjectChange?.(object)
    }

    onModalChange = (script?: IScript) => {
        if (script) {
            this.props.onChange?.(script.uuid)
        }
        this.setState({showModal: undefined})
    }

    showModal = (type: 'edit' | 'add') => {
        this.setState({showModal: type})
    }

    render() {
        const {showModal} = this.state
        const {className, scripts, style, disabled, canAdd, value, canEdit, includeName, autoFocus} = this.props
        const parsedValue = Utils.parseObjectToIdentifier(value, 'uuid')

        return (
            <div className="route-picker" style={style}>
                {showModal && (
                    <ScriptForm identifier={showModal === 'edit' ? parsedValue : undefined}
                                onFinish={this.onModalChange} modal={true}/>
                )}
                <Input.Group compact className={'d-flex w-100'}>
                    <Select
                        allowClear
                        className={className + ' flex-grow-1 min-w-0'}
                        showSearch
                        autoFocus={autoFocus}
                        disabled={disabled}
                        value={parsedValue}
                        placeholder="Vyberte script"
                        optionFilterProp="children"
                        onChange={this.onPickerChoose}
                        filterOption={(input, option) =>
                            option?.props.children?.join().toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {scripts.sort(((a, b) => (a.label || a.name) > (b.label || b.name) ? 1 : -1))
                            .map(script => (
                                    <Select.Option key={script.uuid} value={script.uuid}>
                                        <LocaleText code={script.label || ''}/> {includeName ? '[' + script.name + ']' : ''}
                                    </Select.Option>
                                )
                            )}
                    </Select>
                    {canAdd && !disabled &&
                        <Button className={'flex-shrink-0'} onClick={() => this.showModal('add')}
                                icon={<PlusOutlined/>}/>}
                    {canEdit && !disabled && value &&
                        <Button className={'flex-shrink-0'} onClick={() => this.showModal('edit')}
                                icon={<EditOutlined/>}/>}
                </Input.Group>
            </div>
        )
    }
}

const mapStateToProps = (state: RootStateOrAny) => {
    const {scripts} = state.setup as ISetupState

    return {
        scripts
    }
}

export default connect(mapStateToProps)(ScriptPicker)
