import IRestServiceFilters from "./IRestServiceFilters";
import IRestServiceOrders from "./IRestServiceOrders";
import {RouteParametersFromUrl} from "../../../redux/selectors/Routes";
import IRestServiceAggregations from "./IRestServiceAggregations";
import IRestServiceGroupByList from "./IRestServiceGroupByList";

export const RestServiceOptions = {
    FORMAT: {
        DEFAULT: 'default',
        PIVOT: 'pivot'
    }
}

export default interface IRestServiceOptions {
    limit?: number,
    page?: number,
    order?: IRestServiceOrders
    filters?: IRestServiceFilters,
    aggregations?: IRestServiceAggregations,
    groupBy?: IRestServiceGroupByList
    cache?: boolean,
    depth?: number,
    fileUpload?: boolean
    route?: RouteParametersFromUrl
    [x: string]: any
    format?: 'default' | 'pivot' | string
    multiple?: boolean
}