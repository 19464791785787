import React, {Component} from 'react';
import {Button, Row, Select} from "antd";
import {connect, RootStateOrAny} from "react-redux";
import {ISetupState} from "../../../redux/reducers/Setup";
import IContentType from "../../../model/interface/dataStorage/IContentType";
import IconBuilder from "../../../utils/IconBuilder";
import Utils from "../../../utils";
import {CustomTagProps} from "rc-select/lib/interface/generator";
import {CloseOutlined} from "@ant-design/icons";
import LocaleText from "../../app/settings/dictionary/LocaleText";

interface IProps {
    value?: string | IContentType | (string | IContentType)[]
    onChange?: (uuid?: string | string[]) => void
    className?: string,
    contentTypes: IContentType[],
    style?: React.CSSProperties,
    disabled?: boolean
    multiple?: boolean
    extensions?: string[],
    fieldTypes?: string[],
    onFocus?: React.FocusEventHandler<HTMLInputElement>;
    onBlur?: React.FocusEventHandler<HTMLInputElement>;
}

interface IState {
    value?: string | string[]
}

class ContentTypePicker extends Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            value: this.parseValue(props.value, props)
        }
    }

    parseValue(value: any, props: IProps) {
        if (props.multiple && value) {
            if (!Array.isArray(value)) {
                throw new Error('ContentTypePicker requires and array if multiple selection is enabled')
            }
            return value.map(item => typeof item === "object" ? item.uuid : item)
        }
        return typeof value === "string" ? value : value?.uuid
    }

    checkValue() {
        const {value} = this.state
        if (typeof value === 'string' && !this.getFiltered().find(contentType => contentType.uuid === value)) {
            this.onPickerChoose()
        } else if (Array.isArray(value) && !this.getFiltered().find(contentType => value.includes(contentType.uuid))) {
            this.onPickerChoose()
        }
    }

    componentDidMount() {
        this.checkValue()
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        const {value} = this.props
        if (prevProps.value !== value) {
            this.setState({value: this.parseValue(value, this.props)})
        }
    }

    onPickerChoose = (uuid?: string | string[]) => {
        this.setState({value: uuid})
        if (this.props.onChange) {
            this.props.onChange(uuid)
        }
    }

    filterByExtensions(contentType: IContentType) {
        const {extensions} = this.props
        return !extensions || contentType.extensions.find(e => extensions.includes(e.type))
    }

    filterByFieldTypes(contentType: IContentType) {
        const {fieldTypes} = this.props
        return !fieldTypes || contentType.fields.find(f => fieldTypes.includes(f.type))
    }

    getFiltered() {
        const {contentTypes} = this.props
        return contentTypes.filter(contentType => this.filterByExtensions(contentType)
            && this.filterByFieldTypes(contentType))
    }

    tagRender(props: CustomTagProps) {
        const {label, closable, onClose} = props;

        return (
            <Row justify={"space-between"} align={"middle"} className={'pl-2 mb-1 w-100 border'}>
                <span>{label}</span>
                <Button disabled={!closable} danger onClick={onClose} icon={<CloseOutlined/>} size={'small'}
                        type={"link"}/>
            </Row>
        );
    }

    render() {
        const {value} = this.state
        const {className, style, disabled, multiple} = this.props

        return (
            <div className="contentType-picker" style={style}>
                <Select
                    onFocus={this.props.onFocus}
                    onBlur={this.props.onBlur}
                    allowClear
                    className={'w-100 ant-select-block-tag ' + (className || '')}
                    showSearch
                    tagRender={this.tagRender}
                    mode={multiple ? 'multiple' : undefined}
                    disabled={disabled}
                    value={value as any}
                    placeholder="Vyberte typ obsahu"
                    optionFilterProp="children"
                    onChange={this.onPickerChoose}
                    filterOption={(input, option) =>
                        Utils.stringContains(option?.props.children.join(), input)
                    }
                >
                    {this.getFiltered().map(contentType => (
                            <Select.Option key={contentType.uuid} value={contentType.uuid}>
                                {contentType.icon &&
                                    <span className={'mr-2'}>{IconBuilder(contentType.icon)}</span>
                                }
                                <LocaleText code={contentType.label || ''}/> ({contentType.name})
                            </Select.Option>
                        )
                    )}
                </Select>
            </div>
        )
    }
}

const mapStateToProps = (state: RootStateOrAny) => {
    const {contentTypes} = state.setup as ISetupState

    return {
        contentTypes
    }
}

export default connect(mapStateToProps)(ContentTypePicker)
