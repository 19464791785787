import React from "react"
import arrayMove from "array-move";
import {Checkbox, Divider, Form, Row, Select, Switch, Table, Tag, Tooltip, TreeSelect, Typography} from "antd";
import {CaretDownOutlined, CaretUpOutlined, CloseOutlined, MoreOutlined, PlusOutlined} from "@ant-design/icons";
import Utils from "utils/index"
import IViewTableSettings from "../../../../model/interface/dataStorage/view/table/IViewTableSettings";
import {IViewSettingsProps} from "../ViewSettings";
import IField, {RELATION_FIELD_TYPE} from "../../../../model/interface/dataStorage/IField";
import {connect, RootStateOrAny} from "react-redux";
import selectors from "../../../../redux/selectors";
import IContentType from "../../../../model/interface/dataStorage/IContentType";
import IViewTableItemSettings from "../../../../model/interface/dataStorage/view/table/IViewTableItemSettings";
import LikesService from "../../../../model/service/dataStorage/extension/LikesService";
import DragSortList from "../../../shared/list/DragsortList";
import IViewTableGroupSettings from "../../../../model/interface/dataStorage/view/table/IViewTableGroupSettings";
import Button from "../../../shared/button/Button";
import {DataNode} from "antd/lib/tree";
import IViewItem from "../../../../model/interface/dataStorage/view/IViewItem";
import {SortEnd} from "react-sortable-hoc";
import Collapse from "../../../shared/display/collapse/Collapse";
import LocaleText from "../../settings/dictionary/LocaleText";
import TranslationInput from "../../../shared/input/TranslationInput";

interface IProps extends IViewSettingsProps {
    findContentTypeByUuid: (uuid: string) => IContentType
}

export const TABLE_COLUMN_COLORS = {
    BLUE: 'table-column-blue',
    PURPLE: 'table-column-purple',
    CYAN: 'table-column-cyan',
    GREEN: 'table-column-green',
    MAGENTA: 'table-column-magenta',
    PINK: 'table-column-pink',
    RED: 'table-column-red',
    ORANGE: 'table-column-orange',
    YELLOW: 'table-column-yellow',
    VOLCANO: 'table-column-volcano',
    GEEK_BLUE: 'table-column-geekblue',
    LIME: 'table-column-lime',
    GOLD: 'table-column-gold',
}

class ViewTableItemsSettings extends React.Component<IProps, IViewTableSettings> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            tableItems: props.settings && props.settings.tableItems
                ? [
                    ...props.settings.tableItems.filter(item => props.view.items.find(tableItem => tableItem.field === item.fieldId && tableItem.enabled)),
                    ...props.view.items
                        .filter(item => !props.settings?.tableItems?.find(tableItem => tableItem.fieldId === item.field) && item.enabled)
                        .map(item => ({
                            fieldId: item.field,
                            filterable: item.filterable,
                            sortable: item.sortable,
                            enabled: item.enabled
                        }))
                ]
                : props.view.items.filter(item => item.enabled).map(item => ({
                    fieldId: item.field,
                    filterable: item.filterable,
                    sortable: item.sortable,
                    enabled: item.enabled
                }))
        }
    }

    onSortEnd = ({oldIndex, newIndex}: SortEnd, list: IViewTableItemSettings[]): void => {
        list = this.destructChildren(arrayMove(list, oldIndex, newIndex))
        let tableItems = this.getTableItems()
        const index = this.getTableItems().findIndex(i => list.find(l => l.fieldId === i.fieldId))
        tableItems.splice(index, list.length, ...list)

        this.setState({tableItems});
        this.props.onChange({tableItems}).then()
    };

    destructChildren = (children: IViewTableItemSettings[]) => {
        let fieldItems: IViewTableItemSettings[] = []
        children.forEach(child => {
            if (child.children) {
                fieldItems.push(...this.destructChildren(child.children))
            } else {
                fieldItems.push(child)
            }
        })
        return fieldItems
    }

    getTableItems() {
        return this.state.tableItems || [];
    }

    onChange = (item: IViewTableItemSettings, values: any) => {
        let tableItems = this.getTableItems()
        const index = Utils.findIndex(tableItems, {fieldId: item.fieldId})
        tableItems[index] = {...tableItems[index], ...values}
        this.setState({tableItems})
        this.props.onChange({tableItems}).then()
    }

    getField = (value: string, property: 'uuid' | 'name' = 'uuid') => {
        const {findContentTypeByUuid, view} = this.props
        const contentType = findContentTypeByUuid(view.contentTypes[0])
        const field = contentType.fields.find(f => f[property] === value);
        if (!field) {
            throw new Error(`Field with identifier [${property}: ${value}] does not exist`)
        }
        return field
    }

    static isSortableDisabled = (field: IField) => {
        return [RELATION_FIELD_TYPE.MANY_TO_MANY, RELATION_FIELD_TYPE.ONE_TO_MANY].includes(field.type) || field.mode === 'computed';
    }

    static isFilterableDisabled = (field: IField) => {
        return field.mode === 'computed' || field.targetEntity === LikesService.getRecordClassName(); //TODO for now it is impossible, maybe implement ?
    }

    addGroup = () => {
        const {onChange} = this.props
        onChange({tableGroups: [...this.getGroups(), {label: '', uuid: Utils.uuid()}]}).then()
    }

    deleteGroup = (group: IViewTableGroupSettings) => {
        const {onChange} = this.props
        const tableItems = this.getTableItems()
        tableItems.forEach((_, index) => {
            tableItems[index].group = undefined
        })
        onChange({tableGroups: this.getGroups().filter(g => g.uuid !== group.uuid), tableItems}).then()
    }

    updateGroup = (group: IViewTableGroupSettings) => {
        const {onChange} = this.props
        onChange({
            tableGroups: Utils.arrayAddOrUpdateWhere([...this.getGroups()], {uuid: group.uuid}, group)
        }).then()
    }

    getGroups = (parent?: string | false) => {
        const {settings} = this.props
        return (settings?.tableGroups || []).filter(g => parent === false ? !g.parent : !parent || g.parent === parent)
    }

    getViewItem = (field: string) => {
        const {view} = this.props
        return view.items.find(viewItem => viewItem.field === field)
    }

    getGroupParents = (groupUuid: string): string[] => {
        const group = this.getGroups().find(g => g.uuid === groupUuid)
        if (group && group.parent) {
            return [group.parent, ...this.getGroupParents(group.parent)]
        }
        return []
    }

    buildParentTreeOptions(current?: IViewTableGroupSettings) {
        return this.getGroups(false).filter(g => g.uuid !== current?.uuid)
            .map(group => this.buildOptionsTree(group, current?.uuid))
    }

    buildOptionsTree(group: IViewTableGroupSettings, ignore?: string): DataNode {
        return {
            key: group.uuid,
            title: <LocaleText code={group.label}/>,
            children: this.getGroups(group.uuid).filter(g => g.uuid !== ignore)
                .map(child => this.buildOptionsTree(child, ignore))
        }
    }

    render() {
        const {view} = this.props
        const groups = this.getGroups()
        let children = ViewTableItemsSettings.buildChildren(this.getTableItems(), view.items, groups);

        console.log(groups)

        return (
            <>
                <div className={'mb-4'}>
                    <Typography.Text strong>Skupiny</Typography.Text>
                    <Button className={'ml-2'} size={"small"} icon={<PlusOutlined/>} onClick={this.addGroup}/>
                    {groups.length === 0 && <Typography.Text disabled={true} className={'ml-2'}>
                        nejsou vytvořeny žádné skupiny
                    </Typography.Text>}
                    {groups.length > 0 && this.buildGroupsTable(groups)}
                </div>


                <Typography.Text strong className={'mb-2 d-block'}>Položky</Typography.Text>
                {this.buildItemList(children)}
            </>
        );
    }

    buildGroupsTable(groups: IViewTableGroupSettings[]) {
        const {view} = this.props
        return <Table pagination={false} size={'small'} dataSource={groups} columns={[
            {
                title: 'Název',
                render: (_, group, index) => (
                    <TranslationInput code={'configuration.view.' + view.uuid + '.settings.group.' + Utils.uuid()} preserveCode={true} size={"small"} placeholder={'Název'} className={'w-auto'}
                           value={group.label} onChange={e => this.updateGroup({
                        ...group,
                        label: e || ''
                    })}/>
                )
            },
            {
                title: 'Nadřazená skupina',
                render: (_, group) => (
                    <TreeSelect className={'w-100'} value={group.parent} size={"small"} allowClear={true}
                                onChange={parent => this.updateGroup({...group, parent})}
                                treeDefaultExpandAll={true} treeData={this.buildParentTreeOptions(group)}/>
                )
            },
            {
                title: 'Barva',
                render: (_, group) => (
                    <Select value={group.color} placeholder={'Barva'} allowClear={true}
                            onChange={color => this.updateGroup({...group, color})}>
                        {Object.values(TABLE_COLUMN_COLORS).map(color => (
                            <Select.Option value={color} key={color}><Tag
                                className={color}>{'barva'}</Tag></Select.Option>
                        ))}
                    </Select>
                )
            },
            {
                title: '',
                render: (_, group) => (
                    <Button type={'link'} danger size={"small"} icon={<CloseOutlined/>}
                            onClick={() => this.deleteGroup(group)}/>
                )
            }
        ]}/>;
    }

    static buildChildren(items: IViewTableItemSettings[], viewItems: IViewItem[], groups: IViewTableGroupSettings[]) {
        let children: IViewTableItemSettings[] = [];

        const findOrUpdateGroup = (children: IViewTableItemSettings[], parent: string, group: IViewTableItemSettings): IViewTableItemSettings | undefined => {
            let groupExits: IViewTableItemSettings | undefined
            children.forEach((child, index) => {
                if (child.children) {
                    if (child.group === parent) {
                        groupExits = {...child, children: [...child.children, group]}
                        children[index] = groupExits
                    } else {
                        groupExits = findOrUpdateGroup(child.children, parent, group)
                    }
                }
            })

            return groupExits
        }

        const getGroupParents = (groupUuid: string): string[] => {
            const group = groups.find(g => g.uuid === groupUuid)
            return group && group.parent ? [group.parent, ...getGroupParents(group.parent)] : []
        }

        items.forEach(item => {
            const viewItem = viewItems.find(viewItem => viewItem.field === item.fieldId)
            if (viewItem) {
                if (item.group) {
                    let group: IViewTableItemSettings = {...item}, foundGroup
                    for (const parent of [item.group, ...getGroupParents(item.group)]) {
                        foundGroup = findOrUpdateGroup(children, parent, group)
                        group = foundGroup ? foundGroup : {...group, group: parent, children: [{...group}]}
                        if (foundGroup) {
                            break;
                        }
                    }
                    return !foundGroup && children.push(group)
                }
                children.push({...item})
            } else {
                console.log(item)
            }
        })
        return children;
    }

    buildItemList(children: IViewTableItemSettings[]) {
        return <DragSortList item={{
            render: (value: IViewTableItemSettings, _, handle) => {
                if (value.children) {
                    return <div>
                        <Row align={"middle"} className={'p-1'}>
                            {handle}
                            <Divider type={"vertical"}/>
                            <Typography.Text strong={true}>
                                {this.getGroups().find(g => g.uuid === value.group)?.label}
                            </Typography.Text>
                        </Row>
                        <div className={'ml-3'}>{this.buildItemList(value.children)}</div>
                    </div>
                }
                const {fieldId, enabled, sortable, filterable, columnColor} = value
                if (!fieldId) {
                    throw new Error('missing field on view item')
                }
                const viewItem = this.getViewItem(fieldId!)
                const fieldObject = this.getField(fieldId!)
                const {name, label} = fieldObject
                const title = viewItem?.options?.label || label
                const sortableDisabled = ViewTableItemsSettings.isSortableDisabled(fieldObject)
                const filterableDisabled = ViewTableItemsSettings.isFilterableDisabled(fieldObject) || !!viewItem?.options?.subColumnField
                const isLastEnabled = this.getTableItems().every(i => !i.enabled || i.fieldId === fieldId)
                return (
                    <Collapse header={(_, trigger) =>
                        <Row justify={'space-between'}>
                            <Row align={"middle"}>
                                {handle}
                                <Divider type={"vertical"}/>
                                <Tooltip title={isLastEnabled ? 'tabulka musí mít alespoň jeden sloupec' : false}>
                                    <Checkbox disabled={isLastEnabled} className={'mr-2'} checked={enabled}
                                              onChange={(event) =>
                                                  this.onChange(value, {enabled: event.target.checked})}/>
                                </Tooltip>

                                {title &&
                                    <Typography.Text strong className={'mr-2'}><LocaleText code={title}/></Typography.Text>}({name})
                            </Row>
                            <Row align={"middle"}>
                                Řazení: <Switch className={'ml-2'}
                                                defaultChecked={sortableDisabled ? false : sortable}
                                                disabled={sortableDisabled}
                                                onChange={(sortable) => this.onChange(value, {sortable})}/>
                                <Divider type={"vertical"}/>
                                Filtrování: <Switch className={'ml-2'}
                                                    defaultChecked={filterableDisabled ? false : filterable}
                                                    disabled={filterableDisabled}
                                                    onChange={(filterable) => this.onChange(value, {filterable})}/>
                                <Divider type={"vertical"}/>
                                <Button type={'link'} size={"small"} className={'p-0'} onClick={() => {trigger()}}><MoreOutlined style={{cursor: 'pointer'}} className={'p-1'}/></Button>
                            </Row>
                        </Row>}>
                        <Form size={"small"} className={'p-2'}>
                            <Form.Item label={'Skupina'}>
                                <TreeSelect
                                    className={'flex-grow-1'} value={value.group} size={"small"}
                                    allowClear={true}
                                    onChange={group => this.onChange(value, {group})}
                                    treeDefaultExpandAll={true} treeData={this.buildParentTreeOptions()}/>
                            </Form.Item>
                            <Form.Item label={'Barva sloupce'}>
                                <Select value={columnColor} allowClear={true}
                                        onChange={(columnColor) => this.onChange(value, {columnColor})}>
                                    {Object.values(TABLE_COLUMN_COLORS).map(color => (
                                        <Select.Option value={color} key={color}><Tag
                                            className={color}>{'barva'}</Tag></Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Form>
                    </Collapse>
                )
            },
            className: 'border p-1 mb-2 shadow-sm',
            style: {zIndex: 1001}
        }} children={children} lockAxis={"y"} handle={{
            render: () => <div className={'d-inline-block'}>
                <div className={"d-flex flex-column px-1"} style={{cursor: "move"}}>
                    <CaretUpOutlined/>
                    <CaretDownOutlined/>
                </div>
            </div>
        }} onSortEnd={end => this.onSortEnd(end, children)}/>;
    }
}

const mapStateToProps = (state: RootStateOrAny) => {
    return {
        findContentTypeByUuid: (uuid: string) => selectors.contentTypes.findOneBy(state, 'uuid', uuid)
    }
}

export default connect(mapStateToProps)(ViewTableItemsSettings)