import React from "react";
import ISource from "../../../../model/interface/import/ISource";
import FilePicker from "../../../shared/pickers/file/FilePicker";
import IFile from "../../../../model/interface/file/IFile";
import {ISourceImportStepProps} from "./SourceImport";
import Button from "../../../shared/button/Button";
import {Row} from "antd";

interface IState {
    saving: boolean
}

class SourceFile extends React.Component<ISourceImportStepProps, IState> {

    constructor(props: ISourceImportStepProps) {
        super(props);
        this.state = {
            saving: false
        }
    }

    _isMounted = false;

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setSourceFile(file?: IFile) {
        if (file?.uuid !== this.props.resource.file){
            this.props.updateResource({file: file?.uuid} as ISource, true).then(() => {
                this.props.setStep(1)
                this.setState({saving: false})
            })
        }
    }

    confirm = () => {
        this.setState({saving: true})
        this.props.updateResource({...this.props.resource}, true)
            .then(() => {
                this.props.setStep(1)
                this.setState({saving: false})
            })
    }


    render() {
        const {saving} = this.state
        const {resource, parentSource} = this.props
        const valid = !!resource.file

        return (
            <>
                <FilePicker placeholder={'Vyberte soubor k importu'} value={resource.file}
                            widget={"extended"} extensions={['.' + parentSource.mappingExtension]}
                            onChange={(files) => this.setSourceFile(Array.isArray(files) ? files[0] : files)}/>
                {resource.id && <Row justify={"end"}>
                    <Button className={'mt-2'} disabled={!valid} loading={saving} type={"primary"} onClick={this.confirm}>
                        Zobrazit náhled dat
                    </Button>
                </Row>}
            </>
        )
    }
}

export default SourceFile;