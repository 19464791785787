import React, {Ref} from 'react'
import {Card as CardElement, Skeleton, Spin, Typography} from 'antd';
import CardWidget from "./widget/CardWidget";
import ICardWidget from "../../../../../model/interface/dataStorage/card/ICardWidget";
import CardWidgetType from "./widget/CardWidgetType";
import CardEditor from "./CardEditor";
import TreeStructure from "../../../../../utils/TreeStructure";
import IBaseProps from "../../../../../model/interface/IBaseProps";
import ICard from "../../../../../model/interface/dataStorage/ICard";
import {connect, RootStateOrAny} from "react-redux";
import selectors from "../../../../../redux/selectors";
import IContentType from "../../../../../model/interface/dataStorage/IContentType";
import IRepositoryService from "../../../../../model/interface/IRepositoryService";
import IRestResource from "../../../../../model/interface/api/IRestResource";
import ReportWidgetType from "../../report/widget/ReportWidgetType";
import _ from "underscore"
import ICardWidgetPropsFunctions from "../../../../../model/interface/dataStorage/card/ICardWidgetPropsFunctions";
import {IRoutesRouterDisplay} from "../../../router/RoutesRouter";
import CardEditButton from "./CardEditButton";
import CardsService from "../../../../../model/service/dataStorage/CardsService";
import {IActionResult} from "../../../../../model/service/dataStorage/ActionsService";
import settingsService from "../../../../../model/service/SettingsService";
import PresenterBuilder from "../../../../../views/dataStorage/PresenterBuilder";

interface IState {
    structure: { [id: string]: ICardWidget }
    loading: boolean,
    id?: number
    resource?: IRestResource
    reload?: boolean,
    pdfRef: Ref<any>,
    printing: boolean
}

interface IProps extends IBaseProps, IRoutesRouterDisplay {
    card: ICard
    findService: (contentType: IContentType) => IRepositoryService,
    findContentType: (uuid: string) => IContentType
    editor?: boolean,
    resource?: IRestResource
    cards?: ICard[]
    standAlone?: boolean,
    onActionFinish?: (result?: IActionResult) => Promise<void>,
    getText: (code: string, fallBack?: string) => string
}

class Card extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            structure: {},
            loading: false,
            pdfRef: React.createRef(),
            printing: false
        } as IState;
    }

    static defaultProps = {
        standAlone: true
    }

    getContentType() {
        const {findContentType, card} = this.props
        return card && findContentType(card.contentType);
    }

    componentDidMount() {
        this.buildSetup();
    }

    componentDidUpdate(prevProps: IProps) {
        if (prevProps.resource?.id !== this.props.resource?.id){
            this.buildSetup()
        }
    }

    buildSetup() {
        const {findService, match, editor, resource, routeParams, card} = this.props
        const contentType = this.getContentType()
        const params = match.params
        let id = routeParams?.parameters[contentType.name] || Object.entries(params)[0]?.[1]// TODO add customizable

        const service = findService(contentType)
        if (typeof resource !== 'undefined') {
            if (resource.id) {
                id = resource.id
                let structure = CardEditor.widgetsToStructure(this.getCard(resource)?.widgets || [])
                structure = this.setRecordValues(structure, resource, id);
                this.setState({id, structure, resource})
            } else {
                this.setState({id: 0, structure: CardEditor.widgetsToStructure([])})
            }
        } else if (id && !editor) {
            if (id) {
                this.setState({loading: true, id})
                service.resourceRetrieve(id).then(response => {
                    let structure = CardEditor.widgetsToStructure(this.getCard(response)?.widgets || [])
                    structure = this.setRecordValues(structure, response, id);
                    this.setState({id, resource: response, structure, loading: false})
                })
            }
        } else if (editor) {
            let structure = CardEditor.widgetsToStructure(card.widgets)
            this.setState({id, structure})
        }
    }

    load(useCache?: boolean) {
        const contentType = this.getContentType()
        const {findService, editor} = this.props
        const {resource} = this.state
        const service = findService(contentType)
        const id = resource?.id
        if (id && !editor) {
            this.setState({loading: true, id})
            return service.resourceRetrieve(id, {cache: useCache !== undefined ? useCache : true}).then(response => {
                let structure = CardEditor.widgetsToStructure(this.getCard(response)?.widgets || [])
                structure = this.setRecordValues(structure, response, id);
                this.setState({loading: false, structure, resource: response})
            })
        } else {
            return Promise.resolve()
        }
    }

    reload(): Promise<void> {
        this.setState({reload: true})
        return this.load(false).then(() => this.setState({reload: false}))
    }

    onActionFinished = (result?: IActionResult): Promise<IActionResult | void> => {
        const {onActionFinish} = this.props
        if (result?.action.contentType === this.getContentType().uuid) {
            return onActionFinish?.(result).then(() => {
                if (result?.action.type !== 'delete') {
                    this.reload().then()
                }
                return result
            }) || (result?.action.type !== 'delete' ? this.reload().then() : Promise.resolve())
        }
        return this.reload().then()
    }

    getField(uuid: string) {
        const {findContentType, card} = this.props
        return _.findWhere(findContentType(card.contentType).fields, {uuid});
    }

    getAction(uuid: string) {
        const {findContentType, card} = this.props
        const action = _.findWhere(findContentType(card.contentType).actions, {uuid})
        if (!action) {
            throw new Error(`Action with id[${uuid}] not found`)
        }
        return action
    }

    setRecordValues(structure: { [p: string]: ICardWidget }, response: IRestResource, resourceId: number) {
        const contentType = this.getContentType()
        Object.keys(structure).forEach(key => {
            const uuid = structure[key].options.field
            const field = uuid ? _.findWhere(contentType.fields, {uuid}) : null
            if (field && response.hasOwnProperty(field.name)) {
                structure[key].value = response[field.name]
            }
            if (structure[key].type === CardWidgetType.RELATION_FIELD) {
                structure[key].value = resourceId
            }
            if (structure[key].type === ReportWidgetType.COMMENT && response.hasOwnProperty('thread')) {
                structure[key].value = response['thread'] ? response['thread'].id : null // TODO ?
            }
            if (structure[key].type === CardWidgetType.LIKE && response.hasOwnProperty('likes')) {
                structure[key].value = response['likes']
            }
        })
        return structure
    }

    getSortedChildren(id: number, structure: { [id: number]: ICardWidget }) {
        return TreeStructure.sortChildren(id, structure, 'weight') as ICardWidget[]
    }

    onLoading(): Promise<void> {
        return new Promise(resolve => {
            this.setState({loading: true}, () => resolve())
        })
    }

    setValue = (id: string, value: any) => {
        this.setState(state => ({structure: {...state.structure, [id]: {...state.structure[id], value}}}))
    }

    getValue = (fieldName: string, deepResource?: IRestResource): any => {
        const {structure} = this.state

        const resource = deepResource || this.state.resource

        let value: any

        let fields = fieldName.split('.')
        if (resource && fields.length > 1){
            const childResource = resource[fields[0]]
            if (Array.isArray(childResource)){
                return childResource.map(child => this.getValue(fields.slice(1).join('.'), child))
            }
            return this.getValue(fields.slice(1).join('.'), childResource)
        }

        Object.keys(this.state.structure).forEach(key => {
            const uuid = structure[key].options.field
            const field = uuid ? _.findWhere(this.getContentType().fields, {uuid}) : null
            if (field && field.name === fieldName) {
                value = structure[key].value
            }
        })
        if (!value && resource && resource.hasOwnProperty(fieldName)) {
            return resource[fieldName]
        }

        return value
    }

    buildWidgets() {
        const {structure, id, resource, reload} = this.state
        const {editor, findContentType, match, history} = this.props
        const card = this.getCard(resource)
        return Object.entries(structure).map(([, widget]) => {
            if (widget && !widget.parent && card) {
                let functions = {
                    getNode: (id: number) => structure[id],
                    getSortedChildren: (id: number) => this.getSortedChildren(id, structure),
                    getField: (id: string) => this.getField(id),
                    getAction: (id: string) => this.getAction(id),
                    getContentType: () => findContentType(card.contentType),
                    onFinishAction: (result?: IActionResult, canceled?: boolean) => canceled ? this.setState({loading: false}) : this.onActionFinished(result),
                    onLoading: () => this.onLoading(),
                    onReload: () => this.reload(),
                    updateValue: (id: string, value: any) => this.setValue(id, value),
                    getValue: (field: string) => this.getValue(field)
                } as unknown as ICardWidgetPropsFunctions
                return (
                    <CardWidget
                        {...widget}
                        reload={reload}
                        current={widget.uuid}
                        key={widget.uuid}
                        preview={true}
                        functions={functions}
                        match={match}
                        history={history}
                        editor={editor}
                        recordId={id}
                        resource={resource}
                    />
                )
            }
            return null
        });
    }

    getCard = (resource?: IRestResource) => {
        const {cards, card, findContentType} = this.props
        return cards?.length && resource ? CardsService.findAvailableCard(cards, resource, findContentType) : card
    }

    render() {
        const {loading, printing, id, resource, pdfRef} = this.state
        const {editor, standAlone, history, match, findService, getText} = this.props
        const Container = standAlone ? CardElement : 'div'
        const card = this.getCard(resource)
        const contentType = this.getContentType()
        const service = findService(contentType)
        const translatedLabel = getText(card?.label || '')
        const presenter = translatedLabel.startsWith('#') ? service.getPresenter(translatedLabel.slice(1)) : null
        let label: JSX.Element | string = translatedLabel
        if (presenter && resource) {
            label = PresenterBuilder.buildFromServer(presenter.name, resource)
        }
        if (!label){
            label = getText('configuration.card.default-name', 'Karta')
        }
        return (
            <>
                {(id || editor)
                    ? (
                        <>
                            {label && standAlone && (loading && !resource ? (
                                <Skeleton.Button className={'mb-2'} active={true} style={{width: 200}} size={"small"}/>
                            ) : (
                                <Typography.Title level={1}
                                                  style={{color: settingsService.getThemeColor(), marginBottom: 10}}>
                                    {label}
                                </Typography.Title>
                            ))}
                            <Container className={"print-container mb-0"}>
                                {/*<Pdf targetRef={this.state.pdfRef} filename={this.getContentType().label + ' ' + this.props.card.name + ".pdf"}>*/}
                                {/*    {({ toPdf }: any) => <Button type={"default"} onClick={() => this.printPdf(toPdf)} icon={<FilePdfOutlined />} />}*/}
                                {/*</Pdf>*/}
                                <Skeleton className={'w-100'} loading={loading && !resource}>
                                    <Spin spinning={loading || printing}>
                                        {card ?
                                            <CardEditButton
                                                resource={card}
                                                history={history}
                                                match={match}
                                            />
                                            : 'Karta není k dispozici, kontaktujte prosím správce'}
                                        {/*{structure && resource && (*/}
                                        {(resource || editor) && (
                                            <div ref={pdfRef}>
                                                {this.buildWidgets()}
                                            </div>
                                        )}
                                    </Spin>
                                </Skeleton>
                            </Container>
                        </>
                    )
                    : 'Chyba konfigurace'
                }
            </>
        )
    }
}

const mapStateToProps = (state: RootStateOrAny) => {
    return {
        findService: (contentType: IContentType) => selectors.services.findOneByContentType(state, contentType),
        findContentType: (uuid: string) => selectors.contentTypes.findOneBy(state, 'uuid', uuid),
        getText: (code: string, fallback?: string) => selectors.dictionary.getMessage(state, code, fallback),
    }
}

export default connect(mapStateToProps)(Card)