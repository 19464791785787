import React, {RefObject} from 'react'
import {Collapse, Form, FormInstance, Modal} from 'antd';
import ICardWidget from "../../../../../model/interface/dataStorage/card/ICardWidget";
import {ICardStepProps} from "./CardModal";
import CardWidgetType from "./widget/CardWidgetType";
import CardWidget from "./widget/CardWidget";
import CardWidgetList from "./widget/CardWidgetList";
import IWidgetOptions from "../../../../../model/interface/widget/IWidgetOptions";
import Widget from "../../widget/Widget";
import IBaseProps from "../../../../../model/interface/IBaseProps";
import IContentType from "../../../../../model/interface/dataStorage/IContentType";
import CardWidgetGallery from "./widget/CardWidgetGallery";
import IField from "../../../../../model/interface/dataStorage/IField";
import ICardWidgetScalarField from "../../../../../model/interface/dataStorage/card/ICardWidgetScalarField";
import ScalarFieldEditor from "./widget/editor/ScalarFieldEditor";
import RelationFieldEditor from "./widget/editor/RelationFieldEditor";
import ICardWidgetRelationField from "../../../../../model/interface/dataStorage/card/ICardWidgetRelationField";
import _ from "underscore";
import WidgetType from "../../widget/WidgetType";
import ActionWidgetEditor from "../../widget/optionEditor/ActionWidgetEditor";
import IActionOptions from "../../../../../model/interface/widget/option/IActionOptions";
import IAction from "../../../../../model/interface/dataStorage/IAction";
import {connect, RootStateOrAny} from "react-redux";
import selectors from "../../../../../redux/selectors";
import Utils from "../../../../../utils";
import ICardWidgetPropsFunctions from "../../../../../model/interface/dataStorage/card/ICardWidgetPropsFunctions";
import ICardWidgetContainer from "../../../../../model/interface/dataStorage/card/ICardWidgetContainer";
import ContainerEditor from "../../widget/optionEditor/ContainerEditor";
import Title from "antd/es/typography/Title";
import ConditionEditor from "../../form/FormElement/optionEditor/ConditionEditor";
import WorkflowTransactionConditionsEditor from "./widget/editor/WorkflowTransactionConditionsEditor";
import ICardWidgetWorkflowTransactionConditions
    from "../../../../../model/interface/dataStorage/card/ICardWidgetWorkflowTransactionConditions";
import Editor, {IEditorState} from "../../editor/Editor";
import ReportWidgetType from "../../report/widget/ReportWidgetType";
import ResourcePicker from "../../../../shared/pickers/ResourcePicker";
import GroupsService from "../../../../../model/service/security/GroupService";
import ImageWidgetEditor from "../../widget/optionEditor/ImageWidgetEditor";
import IImageWidget from "../../../../../model/interface/widget/option/IImageWidget";
import IframeCardWidgetEditor from "./widget/editor/IframeCardWidgetEditor";
import ICardWidgetIframe from "../../../../../model/interface/dataStorage/card/ICardWidgetIframe";
import FieldLikeOptionsEditor, {IFieldLikeOptions} from "../field/optionEditors/FieldLikeOptionsEditor";


interface IState extends IEditorState<ICardWidget> {
}

interface IProps extends ICardStepProps, IBaseProps {
    findContentTypeByUuid: (uuid: string) => IContentType
}

class CardEditor extends Editor<IProps, IState, ICardWidget> {

    widgetList = CardWidgetList

    constructor(props: IProps) {
        super(props);
        const containerUuid = Utils.uuid()
        this.state = {
            ...this.state,
            current: props.card.widgets.find(w => !w.parent)?.uuid || containerUuid,
            structure: props.card.widgets.length > 0 ? CardEditor.widgetsToStructure(this.props.card.widgets)
                : {
                    [containerUuid]: {
                        ...this.widgetList.getByType(ReportWidgetType.CONTAINER),
                        id: containerUuid,
                        label: 'Container'
                    }
                }
        }
    }

    getContentType() {
        const {card, findContentTypeByUuid} = this.props
        return findContentTypeByUuid(card.contentType);
    }

    static widgetsToStructure(widgets: ICardWidget[]) {
        let structure = {} as { [id: string]: ICardWidget }
        widgets.forEach(widget => {
            structure[widget.uuid] = {
                ...widget,
                id: widget.uuid,
                children: widgets.filter(value => value.parent === widget.uuid).map(wd => wd.uuid),
                parent: widget.parent
            }
        })
        return structure;
    }

    getOptionEditor(node: ICardWidget, formRef: RefObject<FormInstance>) {
        const field = this.getField(node.options?.field)
        const action = this.getAction(node.options?.actionId)
        const {history, match} = this.props
        switch (node.type) {
            case CardWidgetType.RELATION_FIELD:
                return field ? <RelationFieldEditor formRef={formRef} history={history} match={match}
                                                    options={node.options as ICardWidgetRelationField} field={field}/> :
                    this.showWarning('Field not found, recreate this widget!')
            case CardWidgetType.SCALAR_FIELD:
                return field ?
                    <ScalarFieldEditor
                        history={history}
                        match={match}
                        options={node.options as ICardWidgetScalarField}
                        field={field}
                        fields={this.getContentType().fields}
                        widget={node}
                    /> :
                    this.showWarning('Field not found, recreate this widget!')
            case CardWidgetType.LIKE:
                return <FieldLikeOptionsEditor
                    options={node.options as IFieldLikeOptions} buildFieldName={(field) => [field]}/>
            case(WidgetType.ACTION):
                return action ?
                    <ActionWidgetEditor formRef={formRef} options={node.options as IActionOptions} action={action}/> :
                    this.showWarning('Action not found, recreate this widget!')
            case(WidgetType.CONTAINER):
                return <ContainerEditor options={node.options as ICardWidgetContainer}>
                    <Title level={4}>Collapsable conditions</Title>
                    <Form.Item name={'conditions'} noStyle={true}>
                        <ConditionEditor fields={this.getContentType().fields}/>
                    </Form.Item>
                </ContainerEditor>
            case(WidgetType.WORKFLOW_TRANSACTION_CONDITIONS):
                return <WorkflowTransactionConditionsEditor
                    options={node.options as ICardWidgetWorkflowTransactionConditions}/>
            case(WidgetType.IMAGE):
                return <ImageWidgetEditor onFinish={this.onOptionsChange} formRef={formRef}
                                          options={node.options as IImageWidget} contentType={this.getContentType()}/>
            case(WidgetType.IFRAME):
                return <IframeCardWidgetEditor options={node.options as ICardWidgetIframe}
                                               contentType={this.getContentType()}/>
            default:
                return Widget.getOptionEditor(node.type, node.options, formRef, this.onOptionsChange)
        }
    }

    showWarning(title: string) {
        Modal.error({
            title,
        })
        return null
    }

    getField(uuid: string) {
        return _.findWhere(this.getContentType().fields, {uuid});
    }

    getAction(uuid: string) {
        return _.findWhere(this.getContentType().actions, {uuid});
    }

    getGallery = (group?: string) => {
        const {current} = this.state
        return <CardWidgetGallery contentType={this.getContentType()} exists={this.actionOrFieldExists}
                                  node={this.getCurrentNode()} list={CardWidgetList} customGroup={group}
                                  onSelect={(type, field, action) => this.appendNodeExecute(current, type, field, action)}/>
    }

    getInitialOptions(node: ICardWidget): IWidgetOptions {
        let options = {...node.options, ...node.field?.options}
        const field = this.getField(node.options.field)
        if (field) {
            switch (node.type) {
                case CardWidgetType.SCALAR_FIELD:
                    return {type: ScalarFieldEditor.detectType(field), ...options, label: field.label}
            }
        }

        return options
    }

    actionOrFieldExists = (group: string, id: number, type?: string) => {
        return !!Object.entries(this.state.structure)
            .find(value => (type && value[1].type === type)
                || value[1].options[group === 'action' ? 'actionId' : 'field'] === id)
    }

    appendNodeExecute(id: string, type: string, field?: IField, action?: IAction) {
        this.validate().then(() => {
            let node: ICardWidget = {...CardWidgetList.getByType(type), parent: id, id: Utils.uuid(), uuid: id}
            node = field ? {
                ...node,
                options: {field: field.uuid, ...field.options},
                label: field.label || field.name
            } : action ? {
                ...node,
                options: {actionId: action.uuid},
                label: action.label
            } : node
            this.saveNode(node).then()
        })
    }

    saveNode(node: ICardWidget, values?: IWidgetOptions) {
        return super.saveNode(node, values).then(() => this.confirm())
    }

    confirm = (stepBack?: boolean) => {
        const {onChange, setValid, card} = this.props
        this.formRefOptions.current?.validateFields()
            .then(() => setValid(true)).catch(() => setValid(false))
        card.widgets = this.mapWidgets()
        onChange(card, stepBack)
    }

    mapWidgets() {
        let widgets: ICardWidget[] = []
        Object.entries(this.state.structure).forEach(([, widget]) => {
            widgets.push({
                ...widget,
                children: [],
                id: null as any,
                uuid: widget.id
            })
        })
        return widgets;
    }

    getExtraConfiguration(node: ICardWidget) {
        return <Collapse.Panel key={'permissions'} header={'Oprávnění'}>
            <Form.Item label={"Povolit pro skupiny"}>
                <ResourcePicker onChange={value => this.setExtra(node, {groups: value})} value={node.groups}
                                multiple={true} fetchChoices={() => GroupsService.choiceList('default')}/>
            </Form.Item>
        </Collapse.Panel>
    }

    setExtra(node: ICardWidget, values: { [property: string]: any }) {
        const id = node.id
        let newStructure = {...this.state.structure}
        node = {...node, ...values}
        newStructure = {...newStructure, [id]: node}
        this.setState({structure: newStructure}, this.confirm)
    }

    renderPreview = () => {
        const {structure, current} = this.state
        return <div>
            {Object.entries(structure).map(([, widget]) => {
                    if (widget && !widget.parent) {
                        let functions: ICardWidgetPropsFunctions = {
                            setCurrent: this.setCurrent,
                            getNode: (id: string) => structure[id],
                            getSortedChildren: (id: string) => Editor.getSortedChildren(id, structure),
                            getField: (uuid: string) => this.getField(uuid),
                            getAction: (uuid: string) => this.getAction(uuid),
                            getContentType: () => this.getContentType(),
                            updateValue: () => undefined,
                            getValue: () => undefined
                        }
                        return (
                            <CardWidget
                                {...widget}
                                key={widget.id}
                                current={current}
                                functions={functions}
                                match={this.props.match}
                                history={this.props.history}
                                editor={true}
                            />
                        )
                    }
                    return null
                }
            )}
        </div>
    }
}

const mapStateToProps = (state: RootStateOrAny) => {
    return {
        findContentTypeByUuid: (uuid: string) => selectors.contentTypes.findOneBy(state, 'uuid', uuid)
    }
}

export default connect(mapStateToProps)(CardEditor)